import styled from 'styled-components';

export const OverallStakingContainer = styled.div`
  width: 50%;
  height: auto;
  margin: 10px;
  background-color: ${(props) =>
    props.theme.mode === 'dark' ? '#191c27' : '#fff'};
  padding: 1rem;
  border-radius: 10px;
  @media (max-width: 1200px) {
    width: 100%;
  }
`;
export const OverallStakingContainerTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  padding: 70px 32px;

  background-color: ${(props) =>
    props.theme.mode === 'dark' ? '#101219' : ' #e7eaf3'};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    justify-content: start;

    padding: 10px 10px 10px 16px;
  }
`;
export const OverallStakingContainerLeft = styled.div`
  margin-right: 20px;
`;
export const OverallStakingHeading = styled.h1`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 145%;
  /* or 26px */

  /* Dark/Dark_100 */

  color: ${(props) => (props.theme.mode === 'dark' ? '#777d92' : '#31374d')};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 10px;
  }
`;
export const OverallStakingValue = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 110%;
  /* identical to box height, or 44px */

  display: flex;
  align-items: center;
  letter-spacing: -1px;

  /* Light/Light_300 */

  color: ${(props) => (props.theme.mode === 'dark' ? '#b8c1da' : '#12141c')};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 14px;
    margin: 0;
  }
`;
export const OverallStakingInnerContainerRight = styled.div``;
export const OverallStakingContainerImage = styled.img`
  width: 185px;
  height: 150px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 55px;
    height: 45px;
  }
`;
export const OverallStakingContainerBottom = styled.div`
  max-width: 60%;
  margin: 2.5rem 0rem;
  margin-left: 1.2rem;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 1rem 0rem;
    margin-left: 1.2rem;
  }
`;
export const OverallStakingHeadingTop = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 145%;
  /* or 32px */

  display: flex;
  align-items: center;

  /* Light/Light_300 */

  color: #12141c;

  color: ${(props) => props.theme.mode === 'dark' && '#b8c1da'};
  margin-bottom: 0px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 12px;
  }
`;
export const OverallStakingBottomPara = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 168%;
  /* identical to box height, or 27px */

  /* Dark/Dark_100 */

  color: #777d92;

  opacity: 0.87;
  margin-top: 5px;
  margin-bottom: 25px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 8px;
  }
`;
export const OverallStakingHeadingBottom = styled.h1`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 145%;
  /* or 20px */

  /* Light/Light_300 */

  color: #31374d;

  color: ${(props) => props.theme.mode === 'dark' && '#b8c1da'};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 10px;
  }
`;
export const OverallStakingButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 15px;
`;
export const OverallStakingButtonLeft = styled.img``;
export const OverallStakingButtonRight = styled.img``;

// skeleton blocks

export const LeftTopSkeleton = styled.div`
  margin: 0;
  width: 100%;

  .ant-skeleton-input {
    margin: 0.5rem 0;
    height: 15rem;
    border-radius: 4px;
  }
`;
