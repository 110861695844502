import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleThankYouModal } from '../../../store/slices/modal';
import {
  TickBackground,
  CloseIcon,
  Container,
  DetailsContainer,
  InnerContainer,
  LogoBackground,
  ModalStyles,
  ProjectLogo,
  ProjectName,
  Text,
  ThankYouText,
} from './ThankYouModal.styles';

import Close from '../../../assets/Close.svg';
import TickPng from '../../../assets/tick.png';

function ThankYouModal() {
  const { isThankYouModalVisible } = useSelector((state) => state.modal);
  const { selectedPoolV1 } = useSelector((state) => state.pools);

  const dispatch = useDispatch();

  const handleCancel = () => {
    dispatch(toggleThankYouModal());
  };

  return (
    <ModalStyles
      footer={null}
      visible={isThankYouModalVisible}
      onCancel={handleCancel}
      width={400}
    >
      <Container>
        <CloseIcon src={Close} onClick={handleCancel} />
        <LogoBackground>
          <ProjectLogo
            src={selectedPoolV1.projectLogoUrl}
            alt={selectedPoolV1.projectName}
          />
        </LogoBackground>
        <ProjectName>Join {selectedPoolV1.projectName} pool</ProjectName>
        <Text marginBottom="12px">Tranasaction is competed</Text>

        <DetailsContainer>
          <InnerContainer>
            <TickBackground>
              <img src={TickPng} alt="tick" />
            </TickBackground>
            <ThankYouText>
              Thank you for participating in {selectedPoolV1.projectName} Token
              Sale
            </ThankYouText>
            <Text>Please wait for the Token distribution</Text>
          </InnerContainer>
        </DetailsContainer>
      </Container>
    </ModalStyles>
  );
}

export default ThankYouModal;
