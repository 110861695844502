import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Skeleton } from 'antd';
import {
  UserStakeContainer,
  LeftStake,
  RightStake,
  LogoImg,
  StakeLeftHeading,
  MyStake,
  Textbox,
  StakeReward,
  MyStakeStatus,
  MyStakeContainer,
  NativeSkeleton,
} from '../AsvaStake.style';
import LpLogo from '../../assets/logo.svg';
import asvaLogo from '../../assets/asvaLogo.svg';
import pools from '../../../../config/stakePool';

import { currentEnv } from '../../StakeEnv';
import { rewardPercent, userStakeInfo } from '../../../../Web3/web3';
import uptoTwoDecimal from '../../../../utils/uptoTwoDecimal';

function MyPosition() {
  const [userInfo1, setUserInfo1] = useState();
  const [userInfo2, setUserInfo2] = useState();
  const [userInfo3, setUserInfo3] = useState();
  const [userInfo4, setUserInfo4] = useState();
  const [userInfo5, setUserInfo5] = useState();
  const [userInfo6, setUserInfo6] = useState();
  const [userInfo7, setUserInfo7] = useState();
  const [userInfo8, setUserInfo8] = useState();
  const [userInfo9, setUserInfo9] = useState();
  const [userInfoLp, setUserInfoLp] = useState();
  const [reward1, setReward1] = useState();
  const [reward2, setReward2] = useState();
  const [reward3, setReward3] = useState();
  const [reward4, setReward4] = useState();
  const [reward5, setReward5] = useState();
  const [reward6, setReward6] = useState();
  const [reward7, setReward7] = useState();
  const [reward8, setReward8] = useState();
  const [reward9, setReward9] = useState();
  const [rewardLp, setRewardLp] = useState();
  const [loading, setLoading] = useState(false);
  const isConnected = useSelector((state) => state.user);
  const { pool1, pool2, pool3, pool4, pool5, pool6, pool7, pool8, pool9, lp } =
    useSelector((state) => state.stake);

  useMemo(async () => {
    try {
      if (isConnected.isConnected) {
        setLoading(true);
        const contract = pools.pools[0];
        const address = await currentEnv(process.env.REACT_APP_ENV);
        const rewardPer = await rewardPercent(
          contract.contractAddress[address]
        );
        const userStake = await userStakeInfo(
          contract.contractAddress[address]
        );
        const rewards =
          (parseFloat(rewardPer) / 10000) * parseFloat(userStake.amount);
        setReward1(rewards.toFixed(2));
        setUserInfo1(userStake);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }, [isConnected, pool1]);
  useMemo(async () => {
    try {
      if (isConnected.isConnected) {
        setLoading(true);
        const contract = pools.pools[1];
        const address = await currentEnv(process.env.REACT_APP_ENV);
        const rewardPer = await rewardPercent(
          contract.contractAddress[address]
        );
        const userStake = await userStakeInfo(
          contract.contractAddress[address]
        );
        const rewards =
          (parseFloat(rewardPer) / 10000) * parseFloat(userStake.amount);
        setReward2(rewards.toFixed(2));
        setUserInfo2(userStake);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }, [isConnected, pool2]);
  useMemo(async () => {
    try {
      if (isConnected.isConnected) {
        setLoading(true);
        const contract = pools.pools[2];
        const address = await currentEnv(process.env.REACT_APP_ENV);
        const rewardPer = await rewardPercent(
          contract.contractAddress[address]
        );
        const userStake = await userStakeInfo(
          contract.contractAddress[address]
        );
        const rewards =
          (parseFloat(rewardPer) / 10000) * parseFloat(userStake.amount);
        setUserInfo3(userStake);
        setReward3(rewards.toFixed(2));
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }, [isConnected, pool3]);
  useMemo(async () => {
    try {
      if (isConnected.isConnected) {
        setLoading(true);
        const contract = pools.pools[3];
        const address = await currentEnv(process.env.REACT_APP_ENV);
        const rewardPer = await rewardPercent(
          contract.contractAddress[address]
        );
        const userStake = await userStakeInfo(
          contract.contractAddress[address]
        );
        const rewards =
          (parseFloat(rewardPer) / 10000) * parseFloat(userStake.amount);
        setReward4(rewards.toFixed(2));
        setUserInfo4(userStake);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }, [isConnected, pool4]);
  useMemo(async () => {
    try {
      if (isConnected.isConnected) {
        setLoading(true);
        const contract = pools.pools[4];
        const address = await currentEnv(process.env.REACT_APP_ENV);
        const rewardPer = await rewardPercent(
          contract.contractAddress[address]
        );
        const userStake = await userStakeInfo(
          contract.contractAddress[address]
        );
        const rewards =
          (parseFloat(rewardPer) / 10000) * parseFloat(userStake.amount);
        setReward5(rewards.toFixed(2));
        setUserInfo5(userStake);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }, [isConnected, pool5]);
  useMemo(async () => {
    try {
      if (isConnected.isConnected) {
        setLoading(true);
        const contract = pools.pools[5];
        const address = await currentEnv(process.env.REACT_APP_ENV);
        const rewardPer = await rewardPercent(
          contract.contractAddress[address]
        );
        const userStake = await userStakeInfo(
          contract.contractAddress[address]
        );
        const rewards =
          (parseFloat(rewardPer) / 10000) * parseFloat(userStake.amount);
        setReward6(rewards.toFixed(2));
        setUserInfo6(userStake);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }, [isConnected, pool6]);
  useMemo(async () => {
    try {
      if (isConnected.isConnected) {
        setLoading(true);
        const contract = pools.pools[6];
        const address = await currentEnv(process.env.REACT_APP_ENV);
        const rewardPer = await rewardPercent(
          contract.contractAddress[address]
        );
        const userStake = await userStakeInfo(
          contract.contractAddress[address]
        );
        const rewards =
          (parseFloat(rewardPer) / 10000) * parseFloat(userStake.amount);
        setReward7(rewards.toFixed(2));
        setUserInfo7(userStake);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }, [isConnected, pool7]);
  useMemo(async () => {
    try {
      if (isConnected.isConnected) {
        setLoading(true);
        const contract = pools.pools[7];
        const address = await currentEnv(process.env.REACT_APP_ENV);
        const rewardPer = await rewardPercent(
          contract.contractAddress[address]
        );
        const userStake = await userStakeInfo(
          contract.contractAddress[address]
        );
        const rewards =
          (parseFloat(rewardPer) / 10000) * parseFloat(userStake.amount);
        setReward8(rewards.toFixed(2));
        setUserInfo8(userStake);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }, [isConnected, pool8]);
  useMemo(async () => {
    try {
      if (isConnected.isConnected) {
        setLoading(true);
        const contract = pools.pools[8];
        const address = await currentEnv(process.env.REACT_APP_ENV);
        const rewardPer = await rewardPercent(
          contract.contractAddress[address]
        );
        const userStake = await userStakeInfo(
          contract.contractAddress[address]
        );
        const rewards =
          (parseFloat(rewardPer) / 10000) * parseFloat(userStake.amount);
        setReward9(rewards.toFixed(2));
        setUserInfo9(userStake);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }, [isConnected, pool9]);
  useMemo(async () => {
    try {
      if (isConnected.isConnected) {
        setLoading(true);
        const contract = pools.lp[0];
        const address = await currentEnv(process.env.REACT_APP_ENV);
        const rewardPer = await rewardPercent(
          contract.contractAddress[address]
        );
        const userStake = await userStakeInfo(
          contract.contractAddress[address]
        );
        const rewards =
          (parseFloat(rewardPer) / 10000) * parseFloat(userStake.amount);
        setRewardLp(rewards.toFixed(2));
        setUserInfoLp(userStake);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }, [isConnected, lp]);

  if (isConnected?.isConnected && loading) {
    return (
      <>
        <MyStakeContainer>
          <NativeSkeleton>
            <Skeleton.Input active block />
          </NativeSkeleton>
        </MyStakeContainer>
        <MyStakeContainer>
          <NativeSkeleton>
            <Skeleton.Input active block />
          </NativeSkeleton>
        </MyStakeContainer>
        <MyStakeContainer>
          <NativeSkeleton>
            <Skeleton.Input active block />
          </NativeSkeleton>
        </MyStakeContainer>
        <MyStakeContainer>
          <NativeSkeleton>
            <Skeleton.Input active block />
          </NativeSkeleton>
        </MyStakeContainer>
        <MyStakeContainer>
          <NativeSkeleton>
            <Skeleton.Input active block />
          </NativeSkeleton>
        </MyStakeContainer>
      </>
    );
  }

  return (
    <>
      {parseFloat(userInfo1?.amount) > 0 && (
        <MyStakeContainer cursor={false}>
          <UserStakeContainer>
            <LeftStake>
              <LogoImg src={asvaLogo} />
              <Textbox>
                <StakeLeftHeading>Staked Asva (1.0)</StakeLeftHeading>
                <MyStake>Stake APR 6% for 7 Days </MyStake>
              </Textbox>
            </LeftStake>
            <RightStake>
              <Textbox margin="20px">
                <MyStake>My Stake</MyStake>
                <MyStakeStatus>
                  {uptoTwoDecimal(userInfo1?.amount)}
                </MyStakeStatus>
              </Textbox>
              <Textbox margin="20px">
                <MyStake>My Reward</MyStake>
                <StakeReward color="#3BEEAD">{reward1} </StakeReward>
              </Textbox>
              <Textbox margin="20px">
                <MyStake>Maturity Date</MyStake>
                <StakeReward color="#FCB778">
                  {' '}
                  {`${moment
                    .utc(parseFloat(userInfo1?.date) * 1000)
                    .format('DD MMM YYYY')}
                 `}
                </StakeReward>
              </Textbox>
            </RightStake>
          </UserStakeContainer>
        </MyStakeContainer>
      )}
      {parseFloat(userInfo2?.amount) > 0 && (
        <MyStakeContainer cursor={false}>
          <UserStakeContainer>
            <LeftStake>
              <LogoImg src={asvaLogo} />
              <Textbox>
                <StakeLeftHeading>Staked ASVA (1.0)</StakeLeftHeading>
                <MyStake>Stake APR 12% for 15 Days</MyStake>
              </Textbox>
            </LeftStake>
            <RightStake>
              <Textbox margin="20px">
                <MyStake>My Stake</MyStake>
                <MyStakeStatus>
                  {uptoTwoDecimal(userInfo2?.amount)}
                </MyStakeStatus>
              </Textbox>
              <Textbox margin="20px">
                <MyStake>My Reward</MyStake>
                <StakeReward color="#3BEEAD">{reward2} </StakeReward>
              </Textbox>
              <Textbox margin="20px">
                <MyStake>Maturity Date</MyStake>
                <StakeReward color="#FCB778">
                  {' '}
                  {`${moment
                    .utc(parseFloat(userInfo2?.date) * 1000)
                    .format('DD MMM YYYY')}
                 `}
                </StakeReward>
              </Textbox>
            </RightStake>
          </UserStakeContainer>
        </MyStakeContainer>
      )}
      {parseFloat(userInfo3?.amount) > 0 && (
        <MyStakeContainer cursor={false}>
          <UserStakeContainer>
            <LeftStake>
              <LogoImg src={asvaLogo} />
              <Textbox>
                <StakeLeftHeading>Staked ASVA (1.0)</StakeLeftHeading>
                <MyStake>Stake APR 25% for 30 Days</MyStake>
              </Textbox>
            </LeftStake>
            <RightStake>
              <Textbox margin="20px">
                <MyStake>My Stake</MyStake>
                <MyStakeStatus>
                  {uptoTwoDecimal(userInfo3?.amount)}
                </MyStakeStatus>
              </Textbox>
              <Textbox margin="20px">
                <MyStake>My Reward</MyStake>
                <StakeReward color="#3BEEAD">{reward3} </StakeReward>
              </Textbox>
              <Textbox margin="20px">
                <MyStake>Maturity Date</MyStake>
                <StakeReward color="#FCB778">
                  {' '}
                  {`${moment
                    .utc(parseFloat(userInfo3?.date) * 1000)
                    .format('DD MMM YYYY')}
                 `}
                </StakeReward>
              </Textbox>
            </RightStake>
          </UserStakeContainer>
        </MyStakeContainer>
      )}
      {parseFloat(userInfo4?.amount) > 0 && (
        <MyStakeContainer cursor={false}>
          <UserStakeContainer>
            <LeftStake>
              <LogoImg src={asvaLogo} />
              <Textbox>
                <StakeLeftHeading>Staked ASVA (1.0)</StakeLeftHeading>
                <MyStake>Stake APR 85% for 90 Days</MyStake>
              </Textbox>
            </LeftStake>
            <RightStake>
              <Textbox margin="20px">
                <MyStake>My Stake</MyStake>
                <MyStakeStatus>
                  {uptoTwoDecimal(userInfo4?.amount)}
                </MyStakeStatus>
              </Textbox>
              <Textbox margin="20px">
                <MyStake>My Reward</MyStake>
                <StakeReward color="#3BEEAD">{reward4} </StakeReward>
              </Textbox>
              <Textbox margin="20px">
                <MyStake>Maturity Date</MyStake>
                <StakeReward color="#FCB778">
                  {' '}
                  {`${moment
                    .utc(parseFloat(userInfo4?.date) * 1000)
                    .format('DD MMM YYYY')}
                 `}
                </StakeReward>
              </Textbox>
            </RightStake>
          </UserStakeContainer>
        </MyStakeContainer>
      )}
      {parseFloat(userInfo5?.amount) > 0 && (
        <MyStakeContainer cursor={false}>
          <UserStakeContainer>
            <LeftStake>
              <LogoImg src={asvaLogo} />
              <Textbox>
                <StakeLeftHeading>Staked ASVA (2.0)</StakeLeftHeading>
                <MyStake>Stake APR 2% for 21 Days</MyStake>
              </Textbox>
            </LeftStake>
            <RightStake>
              <Textbox margin="20px">
                <MyStake>My Stake</MyStake>
                <MyStakeStatus>
                  {uptoTwoDecimal(userInfo5?.amount)}
                </MyStakeStatus>
              </Textbox>
              <Textbox margin="20px">
                <MyStake>My Reward</MyStake>
                <StakeReward color="#3BEEAD">{reward5} </StakeReward>
              </Textbox>
              <Textbox margin="20px">
                <MyStake>Maturity Date</MyStake>
                <StakeReward color="#FCB778">
                  {' '}
                  {`${moment
                    .utc(parseFloat(userInfo5?.date) * 1000)
                    .format('DD MMM YYYY')}
                 `}
                </StakeReward>
              </Textbox>
            </RightStake>
          </UserStakeContainer>
        </MyStakeContainer>
      )}
      {parseFloat(userInfo6?.amount) > 0 && (
        <MyStakeContainer cursor={false}>
          <UserStakeContainer>
            <LeftStake>
              <LogoImg src={asvaLogo} />
              <Textbox>
                <StakeLeftHeading>Staked ASVA (2.0)</StakeLeftHeading>
                <MyStake>Stake APR 5% for 60 Days</MyStake>
              </Textbox>
            </LeftStake>
            <RightStake>
              <Textbox margin="20px">
                <MyStake>My Stake</MyStake>
                <MyStakeStatus>
                  {uptoTwoDecimal(userInfo6?.amount)}
                </MyStakeStatus>
              </Textbox>
              <Textbox margin="20px">
                <MyStake>My Reward</MyStake>
                <StakeReward color="#3BEEAD">{reward6} </StakeReward>
              </Textbox>
              <Textbox margin="20px">
                <MyStake>Maturity Date</MyStake>
                <StakeReward color="#FCB778">
                  {' '}
                  {`${moment
                    .utc(parseFloat(userInfo6?.date) * 1000)
                    .format('DD MMM YYYY')}
                 `}
                </StakeReward>
              </Textbox>
            </RightStake>
          </UserStakeContainer>
        </MyStakeContainer>
      )}
      {parseFloat(userInfo7?.amount) > 0 && (
        <MyStakeContainer cursor={false}>
          <UserStakeContainer>
            <LeftStake>
              <LogoImg src={asvaLogo} />
              <Textbox>
                <StakeLeftHeading>Staked ASVA (2.0)</StakeLeftHeading>
                <MyStake>Stake APR 12% for 90 Days</MyStake>
              </Textbox>
            </LeftStake>
            <RightStake>
              <Textbox margin="20px">
                <MyStake>My Stake</MyStake>
                <MyStakeStatus>
                  {uptoTwoDecimal(userInfo7?.amount)}
                </MyStakeStatus>
              </Textbox>
              <Textbox margin="20px">
                <MyStake>My Reward</MyStake>
                <StakeReward color="#3BEEAD">{reward7} </StakeReward>
              </Textbox>
              <Textbox margin="20px">
                <MyStake>Maturity Date</MyStake>
                <StakeReward color="#FCB778">
                  {' '}
                  {`${moment
                    .utc(parseFloat(userInfo7?.date) * 1000)
                    .format('DD MMM YYYY')}
                 `}
                </StakeReward>
              </Textbox>
            </RightStake>
          </UserStakeContainer>
        </MyStakeContainer>
      )}
      {parseFloat(userInfo8?.amount) > 0 && (
        <MyStakeContainer cursor={false}>
          <UserStakeContainer>
            <LeftStake>
              <LogoImg src={asvaLogo} />
              <Textbox>
                <StakeLeftHeading>Staked ASVA (2.0)</StakeLeftHeading>
                <MyStake>Stake APR 18% for 180 Days</MyStake>
              </Textbox>
            </LeftStake>
            <RightStake>
              <Textbox margin="20px">
                <MyStake>My Stake</MyStake>
                <MyStakeStatus>
                  {uptoTwoDecimal(userInfo8?.amount)}
                </MyStakeStatus>
              </Textbox>
              <Textbox margin="20px">
                <MyStake>My Reward</MyStake>
                <StakeReward color="#3BEEAD">{reward8} </StakeReward>
              </Textbox>
              <Textbox margin="20px">
                <MyStake>Maturity Date</MyStake>
                <StakeReward color="#FCB778">
                  {' '}
                  {`${moment
                    .utc(parseFloat(userInfo8?.date) * 1000)
                    .format('DD MMM YYYY')}
                 `}
                </StakeReward>
              </Textbox>
            </RightStake>
          </UserStakeContainer>
        </MyStakeContainer>
      )}
      {parseFloat(userInfo9?.amount) > 0 && (
        <MyStakeContainer cursor={false}>
          <UserStakeContainer>
            <LeftStake>
              <LogoImg src={asvaLogo} />
              <Textbox>
                <StakeLeftHeading>Staked ASVA (2.0)</StakeLeftHeading>
                <MyStake>Stake APR 25% for 365 Days</MyStake>
              </Textbox>
            </LeftStake>
            <RightStake>
              <Textbox margin="20px">
                <MyStake>My Stake</MyStake>
                <MyStakeStatus>
                  {uptoTwoDecimal(userInfo9?.amount)}
                </MyStakeStatus>
              </Textbox>
              <Textbox margin="20px">
                <MyStake>My Reward</MyStake>
                <StakeReward color="#3BEEAD">{reward9} </StakeReward>
              </Textbox>
              <Textbox margin="20px">
                <MyStake>Maturity Date</MyStake>
                <StakeReward color="#FCB778">
                  {' '}
                  {`${moment
                    .utc(parseFloat(userInfo9?.date) * 1000)
                    .format('DD MMM YYYY')}
                 `}
                </StakeReward>
              </Textbox>
            </RightStake>
          </UserStakeContainer>
        </MyStakeContainer>
      )}
      {parseFloat(userInfoLp?.amount) > 0 && (
        <MyStakeContainer cursor={false}>
          <UserStakeContainer>
            <LeftStake>
              <LogoImg src={LpLogo} />
              <Textbox>
                <StakeLeftHeading>Staked ASVA/BUSD LP</StakeLeftHeading>
                <MyStake>Stake APR 46% for 30 Days</MyStake>
              </Textbox>
            </LeftStake>
            <RightStake>
              <Textbox margin="20px">
                <MyStake>My Stake</MyStake>
                <MyStakeStatus>
                  {uptoTwoDecimal(userInfoLp?.amount)}
                </MyStakeStatus>
              </Textbox>
              <Textbox margin="20px">
                <MyStake>My Reward</MyStake>
                <StakeReward color="#3BEEAD">{rewardLp} </StakeReward>
              </Textbox>
              <Textbox margin="20px">
                <MyStake>Maturity Date</MyStake>
                <StakeReward color="#FCB778">
                  {' '}
                  {`${moment
                    .utc(parseFloat(userInfoLp?.date) * 1000)
                    .format('DD MMM YYYY')}
                 `}
                </StakeReward>
              </Textbox>
            </RightStake>
          </UserStakeContainer>
        </MyStakeContainer>
      )}
    </>
  );
}

export default MyPosition;
