import { Skeleton } from 'antd';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  gap: 20px;
`;

const Image = styled.div`
  flex: 1;

  .ant-skeleton-input {
    height: 345px;
    border-radius: 10px;
  }
`;

const Content = styled.div`
  flex: 2;

  .ant-skeleton-input {
    height: 345px;
    border-radius: 10px;
  }
`;

function HorizontalCardSkeleton() {
  return (
    <Container>
      <Image>
        <Skeleton.Input active block />
      </Image>
      <Content>
        <Skeleton.Input active block />
      </Content>
    </Container>
  );
}

export default HorizontalCardSkeleton;
