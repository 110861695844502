const networkName = {
  97: 'BNB Chain',
  137: 'Polygon',
  43114: 'Avalance',
  56: 'BNB Chain',
  80001: 'Polgon TESTNET',
  43113: 'Avalance TESTNET',
  1442: 'Polygon zkEVm ',
  1101: 'Polygon zkEVm ',
  // polygon zkevm
  420: 'Optimism ',
  10: 'Optimism ',
  // optimism
  421613: 'Arbitrum ',
  42161: 'Arbitrum ',
  // arbitrum
};
export default networkName;
