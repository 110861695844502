import React from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import copy from 'copy-to-clipboard';

import {
  Container,
  Heading,
  CheckBoxDiv,
  Label,
  MetamaskConnect,
  MetamaskImageConnect,
  MetamaskTextConnect,
  CloseIcon,
  // CopyImage,
  Disconnect,
  // DisconnectIcon,
  DisconnectSpan,
  ModalStyles,
  CopyIcon,
  DisconnectIcon,
  // CopyIcon,
} from './DisconnectWalletModal.styles';

import Metamsk from '../../../assets/Metamsk.png';
// import Binance from '../../../assets/Binance.png';

import Close from '../../../assets/Close.png';

// import disconnect from '../../../assets/disconnect.png';
import { toggleDisconnectWallet } from '../../../store/slices/modal';
import { walletDisconnect } from '../../../store/slices/user';

function DisconnectWalletModal() {
  const { isDisconnectWalletVisible } = useSelector((state) => state.modal);

  const { displayAddress, address } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const handleCancel = () => {
    dispatch(toggleDisconnectWallet());
  };

  const disconnectWallet = () => {
    dispatch(toggleDisconnectWallet());
    dispatch(walletDisconnect());
  };

  const copyToClipboard = () => {
    copy(address);
    toast.success('Copied to clipboard');
  };

  return (
    <ModalStyles
      footer={null}
      visible={isDisconnectWalletVisible}
      onCancel={handleCancel}
      width={360}
    >
      <Container>
        <Heading>You are Connected to your wallet</Heading>

        <CheckBoxDiv>
          <Label>Connected with Metamask</Label>
        </CheckBoxDiv>
        <CloseIcon src={Close} onClick={handleCancel} />
        <MetamaskConnect>
          <MetamaskImageConnect src={Metamsk} />
          <MetamaskTextConnect>{displayAddress}</MetamaskTextConnect>
          <CopyIcon onClick={copyToClipboard} />
        </MetamaskConnect>
        <Disconnect onClick={disconnectWallet}>
          <DisconnectSpan>Disconnect</DisconnectSpan>
          <DisconnectIcon />
        </Disconnect>
      </Container>
    </ModalStyles>
  );
}

export default DisconnectWalletModal;
