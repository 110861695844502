/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Skeleton } from 'antd';
import { useSelector } from 'react-redux';

const WhitelistStatus = styled.div`
  margin: 0;
  width: ${(props) => props.width};
  text-align: center;
  font-size: 14px;
  border-radius: 5px;
  line-height: 26px;
  padding: 3px 5px;
  background-color: ${(props) => {
    if (props.tag === 'closed') {
      return 'rgba(251, 89, 89, 0.2)';
    }

    if (props.tag === 'tba') {
      return 'transparent';
    }

    if (props.tag === 'soon') {
      return 'rgba(252, 183, 120, 0.2)';
    }

    if (props.tag === 'open') {
      return 'rgba(59, 238, 173, 0.2)';
    }
    return 'transparent';
  }};

  color: ${(props) => {
    if (props.tag === 'closed') {
      return props.theme.colors.red[400];
    }

    if (props.tag === 'tba') {
      return props.theme.colors.grey[200];
    }

    if (props.tag === 'soon') {
      return props.theme.colors.yellow[400];
    }

    if (props.tag === 'open') {
      return props.theme.colors.green[400];
    }
    return 'transparent';
  }};
  //   background-color: rgba(251, 89, 89, 0.2);
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 1px 5px;
    font-size: 12px;
    line-height: 20px;
  }
`;

function WhitelistTag({ type, data, width }) {
  const { loading, error, timeStamp } = useSelector((state) => state.time);

  const [whitelistType, setWhitelistType] = useState({
    type: '',
    text: '',
  });

  const getData = async () => {
    const timestampFromServer = new Date(timeStamp).toISOString();

    if (timestampFromServer < data.idoDates.whitelistStartDate) {
      setWhitelistType({ type: 'soon', text: 'Whitelist Soon' });
      return;
    }

    if (
      timestampFromServer > data.idoDates.whitelistStartDate &&
      timestampFromServer < data.idoDates.whitelistEndDate
    ) {
      setWhitelistType({ type: 'open', text: 'Whitelist Open' });
      return;
    }

    if (timestampFromServer > data.idoDates.whitelistEndDate) {
      setWhitelistType({ type: 'closed', text: 'Whitelist Closed' });
    }
  };

  useEffect(() => {
    if (!loading) getData();
    if (error) {
      setWhitelistType({
        text: '',
        date: '',
        type: 'open',
      });
    }
  }, [loading, data, timeStamp]);

  return loading ? (
    <Skeleton.Input active />
  ) : (
    <WhitelistStatus tag={whitelistType.type} width={`${width}%`}>
      {whitelistType.text}
    </WhitelistStatus>
  );
}

export default WhitelistTag;
