import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import {
  Container,
  Heading,
  CheckBoxDiv,
  Checkbox,
  // Span,
  A,
  Label,
  Metamask,
  MetamaskImage,
  MetamaskText,
  CloseIcon,
  ModalStyles,
} from './ConnectWalletModal.styles';

import Metamsk from '../../../assets/Metamsk.png';
// import Binance from '../../../assets/Binance.png';
import checkbox from '../../../assets/checkbox.png';
import checkedbox from '../../../assets/checkedbox.png';

import Close from '../../../assets/Close.png';
import { toggleConnectWallet } from '../../../store/slices/modal';
import { fetchAccountDetailsWindow } from '../../../Web3/web3';
import { setAccountDetails } from '../../../store/slices/user';

function ConnectWalletModal() {
  const { isConnectWalletModalVisible } = useSelector((state) => state.modal);

  const dispatch = useDispatch();

  const [checkBoxVisible, setCheckBoxVisible] = useState(false);

  const handleOk = () => {
    dispatch(toggleConnectWallet());
  };

  const handleCancel = () => {
    dispatch(toggleConnectWallet());
  };

  const connectWallet = async () => {
    if (window.ethereum === undefined) {
      toast.error('Plase install metamask');
      return;
    }

    if (!checkBoxVisible) {
      toast.error('Please accept the terms and conditions');
      return;
    }

    const details = await fetchAccountDetailsWindow();

    dispatch(setAccountDetails(details));

    dispatch(toggleConnectWallet());
  };

  return (
    <ModalStyles
      footer={null}
      visible={isConnectWalletModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={423}
    >
      <Container>
        <Heading>Connect to</Heading>
        <Heading>your preferred Wallet</Heading>

        <CheckBoxDiv>
          {checkBoxVisible ? (
            <Checkbox
              src={checkedbox}
              onClick={() => setCheckBoxVisible(false)}
            />
          ) : (
            <Checkbox src={checkbox} onClick={() => setCheckBoxVisible(true)} />
          )}
          <Label>
            I accept the
            <A
              href="https://metalaunch.io/assets/files/terms-of-use.pdf"
              target="_blank"
            >
              {' '}
              terms and conditions{' '}
            </A>
            of Metalaunch
          </Label>
        </CheckBoxDiv>
        <CloseIcon src={Close} onClick={handleCancel} />

        <Metamask onClick={connectWallet}>
          <MetamaskImage src={Metamsk} />
          <MetamaskText>Metamask Wallet</MetamaskText>
        </Metamask>
      </Container>
    </ModalStyles>
  );
}

export default ConnectWalletModal;
