import styled from 'styled-components';

export const Container = styled.div`
  width: auto;
  height: 100%;
  margin: 10px 50px;
  padding: 0;
  box-sizing: border-box;
`;

export const Endedpoollist = styled.div`
  height: 120px;
  width: auto;
  border-radius: 10px;
  margin: 1% auto;
  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['300']
      : props.theme.colors.grey['100']};
  /* border: ${(props) =>
    props.theme.mode === 'dark'
      ? ` 1px solid ${props.theme.colors.black['200']}`
      : `1px solid  ${props.theme.colors.grey['200']}`}; */
  &:hover {
    background: ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black['200']
        : props.theme.colors.grey['200']};
    border: ${(props) =>
      props.theme.mode === 'dark'
        ? `1px solid ${props.theme.colors.black['100']}`
        : `1px solid ${props.theme.colors.black['200']}`};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;
export const Endedpoolcard = styled.div`
  display: flex;
  align-items: center;

  padding-top: 12px;
  width: 100%;
  padding: 12px 60px 10px 0px;
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    width: 100%;
  }
  @media screen and (max-width: 1000px) {
    padding-right: 5px !important;
    width: 71%;
  }
`;

export const Imagediv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 23px 0px;

  width: 100px;
  margin-left: 1%;
  height: auto;
  margin-bottom: 0px;
  margin-right: 2%;

  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 10px;
  &::before {
    content: '';

    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;

    background-image: url('${(props) => props.image}');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border-radius: 10px;
    opacity: 0.5;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
  }
  @media screen and (max-width: 1000px) {
    min-width: 100px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 42%;
    height: 95%;
    flex-direction: column;
    box-sizing: border-box;
  }
`;
export const ImageBackground = styled.div`
  border: 0px solid #20232f;
  position: relative;
  width: 50px;
  height: 50px;
  opacity: 1;
  border-radius: 50%;
  background-color: ${(props) =>
    props.theme.mode === 'dark' ? '#20232f' : props.theme.colors.grey['100']};
`;
export const Image = styled.img`
  width: 79%;
  height: 75%;
  margin-left: 11%;
  margin-top: 11%;
  border-radius: 50%;
  opacity: 1;
`;
export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 60px;
  width: 100%;
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    margin-left: 1rem;
    gap: 50px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin-left: 1rem;
    gap: 20px;
  }
`;

export const Idotype = styled.div`
  display: flex;
  /* align-items: center; */
  /* justify-content: space-between; */
  flex-direction: column;
  /* margin-right: 30%; */

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    margin-right: 0%;
    padding: 1rem;
    flex-direction: column;
    box-sizing: border-box;
  }
`;
export const Head = styled.span`
  display: flex;
  align-items: flex-start;
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['100']
      : props.theme.colors.black['200']};

  /* width: 5rem; */

  border-radius: 2px;
  margin-bottom: 7px;
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    font-size: 9px;
    margin-bottom: 16%;
  }
`;
export const Text = styled.span`
  font-size: 12px;
  padding-right: 0;
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['100']
      : props.theme.colors.black['200']};

  margin-right: 0px;
  width: 5rem;
  /* @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    font-size: 9px;
  } */
`;
export const Network = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  /* margin-right: 30%; */

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    margin-right: 0%;

    flex-direction: column;
    box-sizing: border-box;
  }
  /* @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    margin-right: 10%;
    margin-bottom: 1%;
  } */
`;
export const NetworkHead = styled.span`
  font-size: 12px;
  padding-right: 0;

  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['100']
      : props.theme.colors.black['200']};

  margin-right: auto;
  width: 5rem;
  margin-bottom: 10px;
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    font-size: 9px;
    margin-bottom: 7%;
  }
`;
export const NetworkText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    margin-right: auto;
  }
`;

export const NetworkImage = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 11%;
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    width: 10px;
    height: 10px;
  }
`;
export const NetworkName = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 145%;
  width: 9rem;
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['200']
      : props.theme.colors.black['400']};
  margin-left: 6px;

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    font-size: 9px;
    margin-left: 3px;
  }
`;
export const ProjectType = styled.div`
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  /* margin-right: 30%; */

  /* @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    margin-right: 10%;
  } */
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    margin-right: 0%;
    padding: 1rem;
    flex-direction: column;
    box-sizing: border-box;
  }
`;
export const ProjectTypeHead = styled.span`
  width: 5rem;
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['100']
      : props.theme.colors.black['200']};
  font-size: 12px;

  line-height: 150%;
  margin-bottom: 10px;
  margin-right: auto;

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    font-size: 9px;
    margin-bottom: 17%;
  }
`;
export const ProjectTypeText = styled.span`
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['200']
      : props.theme.colors.black['400']};
  font-size: 10px;
  font-weight: 500;
  line-height: 130%;

  font-size: 14px;
  width: 150%;
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    font-size: 10px;
    margin-right: auto;
  }
`;
export const Img = styled.img`
  width: 24%;
`;

export const Data = styled.span`
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['200']
      : props.theme.colors.black['400']};
  font-size: 18px;
  font-weight: 500;
  line-height: 130%;
  width: 8rem;
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    font-size: 12px;
    width: 6rem;
  }
`;
export const Totalraise = styled.div`
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  /* margin-right: 30%; */
  width: auto;

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    margin-right: 10%;
    margin-bottom: 1%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    margin-right: 0%;

    padding: 1rem;
    flex-direction: column;
    box-sizing: border-box;
  }
`;
export const TrHead = styled.span`
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['100']
      : props.theme.colors.black['200']};
  font-size: 12px;

  width: auto;
  margin-bottom: 9px;
  margin-right: auto;

  padding: 0px 0px;
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    font-size: 9px;
  }
`;
export const TrData = styled.span`
  color: #1ab17b;
  font-size: 20px;
  font-weight: 500;
  line-height: 130%;

  font-size: 14px;
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    font-size: 11px;
    margin-right: auto;
  }
`;
export const Totalparticipants = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 15%;
`;
export const TpHead = styled.span`
  color: #b8c1da;
  font-size: 12px;

  width: 7rem;
  margin-bottom: 10px;
`;
export const TpData = styled.span`
  color: white;
  font-size: 20px;
  font-weight: 500;
  line-height: 130%;

  font-size: 14px;
  margin-right: auto;
`;
export const Alltimehigh = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 22%;
  margin-right: 96%;
`;
export const AthHead = styled.span`
  color: #b8c1da;
  font-size: 12px;

  width: 5rem;
  margin-bottom: 10px;
`;
export const AthData = styled.span`
  color: white;
  font-size: 20px;
  font-weight: 500;
  line-height: 130%;

  font-size: 14px;
  margin-right: auto;
`;
export const EndedDate = styled.div`
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  /* margin-right: 30%; */

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    margin-right: 10%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    margin-right: auto;
    flex-direction: column;
  }
`;
export const EDHead = styled.span`
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['100']
      : props.theme.colors.black['200']};
  font-size: 12px;
  /* width: 4rem; */
  margin-bottom: 10px;
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    font-size: 9px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 5rem;
    margin-bottom: 10px;
    margin-right: auto;
    color: ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black['100']
        : props.theme.colors.black['200']};
    font-size: 12px;
  }
`;
export const EDData = styled.span`
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['200']
      : props.theme.colors.black['400']};
  font-size: 20px;
  font-weight: 500;
  line-height: 130%;

  font-size: 14px;
  margin-right: auto;
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    font-size: 14px;
    margin-right: auto;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-right: auto;
    box-sizing: border-box;
  }
`;
export const Status = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    margin-right: 0%;
    display: flex;
    align-items: right;
    flex-direction: column;
    flex-direction: column;
    box-sizing: border-box;
  }
`;
export const SHead = styled.span`
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['100']
      : props.theme.colors.black['200']};
  font-size: 12px;
  margin-bottom: 10px;
  margin-right: auto;

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    font-size: 10px;
    margin-bottom: 10px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 12px;
  }
`;
export const SData = styled.span`
  color: ${(props) => props.color};
  font-size: 20px;
  font-weight: 500;
  line-height: 130%;
  font-size: 14px;

  width: 100px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    box-sizing: border-box;
    margin-right: auto;
    font-size: 15px !important;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    font-size: 11px;
  }
`;
// Mobile view Css

export const EndedPoolMobileContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: 2% auto;
  justify-content: center;
  display: flex;

  box-sizing: border-box;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 350px;
    height: 240px;
    background: ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black['300']
        : props.theme.colors.grey['100']};
    box-shadow: 8px;
    margin-top: 0px;
    border-radius: 8px;
    padding: 0rem;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    overflow: hidden;
  }
`;
export const EndedpoolcardMobile = styled.div`
  display: flex;
  gap: 0.2rem;
  justify-content: space-around;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
`;
export const IdotypeMobile = styled.div`
  display: flex;
  width: auto;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
`;
export const SearchIcon = styled.img`
  width: auto;

  position: absolute;
  top: 26%;
  left: 3%;
  cursor: pointer;

  height: auto;
  color: white;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    position: absolute;
    z-index: 1000;
    top: 10px;
    right: 0;
    right: 2px;

    left: auto;
  }
`;
export const ImagedivMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100px;
  height: 91px;

  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 10px;

  &::before {
    content: '';
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    background-image: url('${(props) => props.image}');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border-radius: 10px;
    opacity: 0.5;
  }
`;
export const ImageBackgroundMobile = styled.div`
  border: 0px solid #20232f;
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  opacity: 1;
  background: ${(props) =>
    props.theme.mode === 'dark' ? '#20232f' : props.theme.colors.grey['100']};
`;
export const ImageMobile = styled.img`
  width: 70%;
  height: 70%;
  position: absolute;
  top: 7px;
  right: 8px;
  opacity: 1;
  border-radius: 51% !important;
`;
export const HeadMobile = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const TextMobile = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  /* or 18px */

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  border-radius: 4px;
  padding: 2px;

  margin-bottom: 5px;
  margin-right: auto;

  /* Light/Light_300 */
  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['200']
      : props.theme.colors.grey['200']};
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['300']
      : props.theme.colors.grey['500']};

  gap: 4px;
`;
export const DataMobile = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  /* identical to box height, or 26px */

  display: flex;
  justify-content: start;
  margin-right: auto;
  /* Light/Light_200 */

  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['200']
      : props.theme.colors.black['400']};
`;
export const Inputfilter = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  /* For Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    /* flex-direction: row-reverse; */
    margin-top: 1.5rem;
  }
`;
export const Input = styled.input`
  width: 100%;
  height: 100%;
  outline: 1px grey;
  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['50']
      : props.theme.colors.black['200']};
  border: 1px solid
    ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black['200']
        : props.theme.colors.grey['300']};
  padding: 15px 30px;
  margin-left: 10px;
  border-radius: 6px;
  padding: 15px 39px;
  &::placeholder {
    color: ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black['200']
        : props.theme.colors.grey['500']};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    position: absolute;
    outline: 1px grey;
    background-color: #12141c;

    padding: 15px 30px;
    margin-left: auto;
    border-radius: 6px;
    &::placeholder {
      color: #777d92;
      font-size: 13px;
    }
  }
`;
export const Filter = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 3px 10px;
  align-items: center;
  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['500']
      : props.theme.colors.grey['200']};
  border-radius: 6px;

  margin-left: 2%;
`;
export const Li = styled.span`
  margin-left: 14px;
  padding: 14px 12px;
  font-size: 16px;
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['200']
      : props.theme.colors.grey['500']};
  &:hover {
    background-color: ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black['300']
        : props.theme.colors.black['200']};
    padding: 14px 12px;
    border-radius: 6px;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
  }

  /* For Mobile */
`;
export const TotalraiseMobile = styled.div`
  display: flex;
  flex-direction: column;
`;
export const TrHeadMobile = styled.span`
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['100']
      : props.theme.colors.black['200']};
  font-size: 12px;

  line-height: 150%;
  width: 5rem;
  margin-bottom: 9px;

  padding: 0px 0px;
`;
export const TrDataMobile = styled.span`
  color: #30d89b;
  font-size: 20px;
  font-weight: 500;
  line-height: 130%;

  font-size: 17px;
  margin-right: 22%;
`;
export const EndedPoolContainer = styled.div`
  display: flex;

  align-items: center;
  box-sizing: border-box;
  padding: 20px;
`;
export const EndedPoolMiddleConatiner = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 10px;
  padding: 0px 20px 20px 20px;
  /* margin-bottom: 9%;
  margin-left: -98px;
  margin-top: 16px;
  box-sizing: border-box;
  margin-right: 34px; */
`;

export const EndedPoolContainerLast = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-right: auto;
  margin-right: 130%;
`;

export const Hr = styled.hr`
  width: 278px;
  margin-top: 5%;

  height: 0%;
  color: gray;
  background-color: gray;
`;
export const PublicIDO = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  /* or 18px */

  display: flex;
  align-items: center;
  text-align: center;

  /* Light/Light_300 */

  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['100']
      : props.theme.colors.grey['500']};
  padding: 0px 4px;
  margin: 2px 1px;

  border-right: 1px solid
    ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black['300']
        : props.theme.colors.grey['300']};
`;
export const NetworkImge = styled.img`
  width: 20%;
  height: 20%;
  margin-left: 5%;
`;
//  mobiel view

export const EndedDateMobiel = styled.p`
  width: 8rem;
  font-weight: 500;
  font-size: 16px;
  margin: 0;
  line-height: 100%;
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['200']
      : props.theme.colors.grey['500']};
`;
