/* eslint-disable no-param-reassign */
/* eslint-disable no-async-promise-executor */
/* eslint-disable import/prefer-default-export */
import Web3 from 'web3';
import { toast } from 'react-toastify';
import { ethers } from 'ethers';
import CLAIM from './CLAIM.json';
import NEWABI from './NEW_ABI.json';
import StakePool from './pools.json';
import Erc20 from './erc20.json';
import web3Configuration from './config';
import { convertToDecimal, convertToEther } from '../helpers/convertDecimals';
import { roundOffBuyValue } from '../utils/helpers';
import { currentEnv } from '../components/Staking/StakeEnv';

// import { convertToEther } from '../helpers/convertDecimals';

let web3 = new Web3(Web3.givenProvider);
// let contractInstance;
let tokenInfo;
export const web3Initialise = (singlePoolData) =>
  new Promise(async (resolve) => {
    const contractDetails = {
      tokenAbi: singlePoolData?.abi?.tokenABI,
      currencyAbi: singlePoolData?.abi?.currencyABI,
      contractAddress: singlePoolData?.projectContractAddress,
      tokenAddress: singlePoolData?.smartContractAddress?.token,
      currencyAddress: singlePoolData?.smartContractAddress?.currency,
      networkId: singlePoolData?.projectNetwork?.networkId,
      decimals: singlePoolData?.tokenDetails?.decimals,
    };

    web3 = await new Web3(
      Web3.givenProvider || web3Configuration[contractDetails.networkId].rpc
    );

    // const contractAbi = NEWABI;
    // const contractAddress = contractDetails?.contractAddress;
    // const currencyAddress = contractDetails?.currencyAddress;

    // tokenAbi =
    //   contractDetails.tokenAbi.length > 0
    //     ? JSON.parse(contractDetails.tokenAbi)
    //     : [];
    // const currencyAbi =
    //   contractDetails.currencyAbi.length > 0
    //     ? JSON.parse(contractDetails.currencyAbi)
    //     : [];

    // contractInstance = await new web3.eth.Contract(
    //   contractAbi,
    //   contractAddress
    // );

    // const currencyInstance = await new web3.eth.Contract(
    //   currencyAbi,
    //   currencyAddress
    // );

    tokenInfo = {
      symbol: 'symbol',
      decimals: contractDetails.decimals,
    };

    // await getTokenInfo();

    // let fetchedTokenInfo = getTokenInfo();

    // tokenInfo = fetchedTokenInfo;

    // console(tokenInfo);

    resolve(true);
  });
export const fetchAccountDetailsWindow = () =>
  new Promise(async (resolve, reject) => {
    try {
      const account = await web3.eth.requestAccounts();
      const networkId = await web3.eth.net.getId();
      const chainId = await web3.eth.getChainId();
      let balance = await web3.eth.getBalance(account[0]);
      balance = web3.utils.fromWei(balance, 'ether');
      const user = {
        address: account[0],
        networkId,
        chainId,
        balance: parseFloat(balance).toFixed(2),
      };
      if (account.length > 0) resolve(user);
    } catch (error) {
      reject(error);
    }
  });

export const fetchAccountDetails = () =>
  new Promise(async (resolve, reject) => {
    const account = await web3.eth.requestAccounts();
    if (account.length < 1) {
      const notificaton = {
        message: 'No Account Found',
        error: true,
      };
      // toast.error('Account not Connected');
      reject(notificaton);
    } else {
      const details = {
        account: {
          address: account[0],
          balance: await web3.eth.getBalance(account[0]),
          isWhiteListed: false,
        },
        connection: {
          isConnected: true,
          network: await web3.eth.net.getNetworkType(),
          networkId: await web3.eth.net.getId(),
        },
        notification: {
          message: `BSC Testnet Network Connected`,
          error: false,
        },
      };
      resolve(details);
    }
  });

function convertDecimalToHexChainID(number) {
  // convertDecimalToHex
  if (typeof number !== 'number') number = parseInt(number, 10);

  const hex = number.toString(16);
  // returning the chainID
  return `0x${hex}`;
}

export function getChainId() {
  return new Promise(async (resolve, reject) => {
    try {
      const id = await web3.eth.getChainId();
      resolve(id);
    } catch (error) {
      reject(error);
    }
  });
}

export const getAccountBalance = () =>
  new Promise(async (resolve, reject) => {
    try {
      const account = await web3.eth.getAccounts();

      if (account.length > 0) {
        const balance = await web3.eth.getBalance(account[0]);
        resolve(web3.utils.fromWei(balance, 'ether'));
      }
    } catch (error) {
      reject(error);
    }
  });

export const getUserAccounts = () =>
  new Promise(async (resolve, reject) => {
    try {
      const accounts = await web3.eth.getAccounts();
      resolve(accounts);
    } catch (error) {
      reject(error);
    }
  });

export const switchNetwork = async (network) => {
  network = convertDecimalToHexChainID(network);
  return new Promise((resolve, reject) => {
    window.ethereum
      .request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: network }],
      })
      .then(() => {
        resolve(true);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getCurrencyBalance = (currencyAbi, currencyAddress, tokenInfo) =>
  // Replace new abi and address with the live one
  new Promise(async (resolve, reject) => {
    try {
      const account = await web3.eth.requestAccounts();
      let contractInstance1 = '';

      contractInstance1 = await new web3.eth.Contract(
        JSON.parse(currencyAbi),
        // currencyAbi,
        currencyAddress
      );

      let balance = await contractInstance1.methods
        .balanceOf(account[0])
        .call();

      balance = await convertToEther(balance, tokenInfo);

      resolve(balance);
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });

export const createCurrencyContractInstance = (currencyAbi, currencyAddress) =>
  // Replace new abi and address with the live one
  new Promise(async (resolve, reject) => {
    try {
      let contractInstance1 = '';

      contractInstance1 = await new web3.eth.Contract(
        JSON.parse(currencyAbi),
        currencyAddress
      );

      resolve(contractInstance1);
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });

const approveErcToken = (
  amount,
  projectContractAddress,
  currencyAbi,
  currencyAddress,
  amountToBuy
) =>
  new Promise(async (resolve, reject) => {
    // changed code added currencyAbi, currencyAddress

    const { account } = await fetchAccountDetails();
    const buyAddress = account.address;

    // pass currencAbi and currencyAddress
    const currencyContractInstance = await createCurrencyContractInstance(
      currencyAbi,
      currencyAddress
    );

    try {
      let newAllowance = await currencyContractInstance.methods
        .allowance(buyAddress, projectContractAddress)
        .call();

      newAllowance = web3.utils.fromWei(newAllowance, 'ether');
      // console.log(
      //   newAllowance,
      //   parseFloat(newAllowance),
      //   parseFloat(amountToBuy),
      //   'Allowance'
      // );

      let contractData = '';
      if (parseFloat(newAllowance) < parseFloat(amountToBuy)) {
        // console.log('approval runs');
        contractData = await currencyContractInstance.methods
          .approve(projectContractAddress, amount)
          .send({
            from: buyAddress,
            gas: 500000,
          });
      }

      // console.log(contractData);
      resolve(contractData);
    } catch (error) {
      reject(error);
      toast.error('The token could not have been approved');
    }
  });

export const buyPoolToken = (
  amount,
  projectContractAddress,
  tokenPrice,
  currencyAbi,
  currencyAddress,
  tokenInfo
) =>
  new Promise(async (resolve, reject) => {
    // changed code added currencyAbi, currencyAddress
    if (amount === '') {
      toast.info('Please enter a valid amount');
      return;
    }

    parseInt(tokenInfo, 10);

    amount = parseFloat(amount);

    const { account } = await fetchAccountDetails();
    const buyAddress = account.address;

    let amountOfToken = parseFloat(amount) / tokenPrice;

    amountOfToken = roundOffBuyValue(amountOfToken);

    const amountToWei = await convertToDecimal(
      (amount + 0).toString(),
      tokenInfo
    );

    const amountOfTokenToWei = await convertToDecimal(
      amountOfToken.toString(),
      tokenInfo
    );

    // changed code added currencyAbi, currencyAddress
    await approveErcToken(
      amountToWei,
      projectContractAddress,
      currencyAbi,
      currencyAddress,
      amount
    );

    let convertToBigNumberAmountToken = '';
    if (tokenInfo === 18) {
      convertToBigNumberAmountToken = web3.utils
        .toBN(amountOfTokenToWei)
        .toString();
    } else {
      convertToBigNumberAmountToken = web3.utils
        .toBN(amountOfTokenToWei)
        .toString();
    }

    try {
      // changed code initiated contract instance with new passed parameters
      let contractInstanceProjectAddress = '';

      contractInstanceProjectAddress = await new web3.eth.Contract(
        NEWABI.abi,
        projectContractAddress
      );

      const contractData = await contractInstanceProjectAddress.methods
        .buy(convertToBigNumberAmountToken)
        .send({
          from: buyAddress,
          gas: 500000,
        });

      resolve(contractData);
    } catch (error) {
      console.log(error);
      toast.error('Could not buy the token from the pool');
      reject(error);
    }
  });

// Claim

export const checkSales = (claimAddress) =>
  new Promise(async (resolve, reject) => {
    const { account } = await fetchAccountDetails();
    const buyAddress = account.address;

    try {
      const contractInstance = await new web3.eth.Contract(
        NEWABI.abi,
        claimAddress
      );
      const contractData = await contractInstance?.methods
        .sales(buyAddress)
        .call();
      const { amount, tokensWithdrawn, investor } = contractData;
      resolve({
        amount: await web3.utils.fromWei(amount, tokenInfo?.decimals),
        tokensWithdrawn,
        investor,
      });
    } catch (error) {
      reject(error);
    }
  });

export const claimTokens = (claimAddress) =>
  new Promise(async (resolve, reject) => {
    const { account } = await fetchAccountDetails();
    const buyAddress = account.address;

    try {
      const claimContractInstance = await new web3.eth.Contract(
        CLAIM.abi,
        claimAddress
      );
      const contractData = await claimContractInstance.methods
        .claimAll(buyAddress)
        .send({
          from: buyAddress,
        });
      resolve(contractData);

      window.location.reload();
    } catch (error) {
      reject(error);
    }
    // } else if (saleData.tokensWithdrawn === true) {
    //   toast.error("The tokens have already been claimed");
    // }
  });

export const getClaimValue = (claimAddress) =>
  new Promise(async (resolve, reject) => {
    const { account } = await fetchAccountDetails();
    const buyAddress = account.address;
    try {
      const claimContractInstance = await new web3.eth.Contract(
        CLAIM.abi,
        claimAddress
      );
      const claimAmout = await claimContractInstance.methods
        .getRemainingAccountAmount(buyAddress)
        .call();
      const realAmount = web3.utils.fromWei(claimAmout, 'ether');
      resolve(realAmount);
    } catch (error) {
      reject(error);
    }
  });

export const getCurrentClaim = (claimAddress) =>
  new Promise(async (resolve, reject) => {
    const { account } = await fetchAccountDetails();
    const buyAddress = account.address;
    try {
      const claimContractInstance = await new web3.eth.Contract(
        CLAIM.abi,
        claimAddress
      );
      const claimAmout = await claimContractInstance.methods
        .getClaimableAccountAmount(buyAddress)
        .call();

      const realAmount = web3.utils.fromWei(claimAmout, 'ether');
      resolve(realAmount);
    } catch (error) {
      reject(error);
    }
  });

export const getClaim = (claimAddress) =>
  new Promise(async (resolve, reject) => {
    const { account } = await fetchAccountDetails();
    const buyAddress = account.address;
    try {
      const claimContractInstance = await new web3.eth.Contract(
        CLAIM.abi,
        claimAddress
      );
      const claimAmout = await claimContractInstance.methods
        .getClaims(buyAddress)
        .call();

      resolve(claimAmout);
    } catch (error) {
      reject(error);
    }
  });
export const getClaimedTokens = (claimAddress) =>
  new Promise(async (resolve, reject) => {
    const { account } = await fetchAccountDetails();
    const buyAddress = account.address;
    try {
      const claimContractInstance = await new web3.eth.Contract(
        CLAIM.abi,
        claimAddress
      );
      const claimAmout = await claimContractInstance.methods
        .getRemainingAccountAmount(buyAddress)
        .call();
      const realAmount = web3.utils.fromWei(claimAmout, 'ether');
      resolve(realAmount);
    } catch (error) {
      reject(error);
    }
  });
export const getClaimPause = (claimAddress) =>
  new Promise(async (resolve, reject) => {
    try {
      const claimContractInstance = await new web3.eth.Contract(
        CLAIM.abi,
        claimAddress
      );
      const claimAmout = await claimContractInstance.methods.isPaused().call();

      resolve(claimAmout);
    } catch (error) {
      reject(error);
    }
  });
export const totalAsvaStake = (address) =>
  new Promise(async (resolve, reject) => {
    try {
      const web3 = new Web3('https://bscrpc.com');
      const totalStakeInstance = await new web3.eth.Contract(
        StakePool,
        address
      );
      const result = await totalStakeInstance.methods.totalTokenStaked().call(); // totalTokenstake for sigle vault
      const amount = await convertToEther(result);

      resolve(amount);
    } catch (error) {
      reject(error);
    }
  });
export const rewardPercent = (address) =>
  new Promise(async (resolve, reject) => {
    try {
      const totalStakeInstance = await new web3.eth.Contract(
        StakePool,
        address
      );
      const result = await totalStakeInstance.methods.rewardPercent().call(); // totalTokenstake for sigle vault

      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
export const userStakeInfo = (contractAddress) =>
  new Promise(async (resolve, reject) => {
    const { account } = await fetchAccountDetails();
    const buyAddress = account.address;
    try {
      const totalStakeInstance = await new web3.eth.Contract(
        StakePool,
        contractAddress
      );
      const result = await totalStakeInstance.methods
        .userInfo(buyAddress)
        .call(); // totalTokenstake for sigle vault
      const amount = await convertToEther(result[0]);
      const date = result[1];
      resolve({ amount, date });
    } catch (error) {
      reject(error);
    }
  });

export const asvaTokenBalance = () =>
  new Promise(async (resolve, reject) => {
    const address = await currentEnv(process.env.REACT_APP_ENV);
    const { account } = await fetchAccountDetails();
    const buyAddress = account.address;
    try {
      const Erc20Instance = await new web3.eth.Contract(
        Erc20,
        address === 56
          ? '0xf7b6d7e3434cb9441982f9534e6998c43eef144a'
          : '0x6C1B15639b0Ee022d977759E6898B91Cf99886e6'
      );
      const result = await Erc20Instance.methods.balanceOf(buyAddress).call(); // totalTokenstake for sigle vault
      const amount = await convertToEther(result);

      resolve(amount);
    } catch (error) {
      reject(error);
    }
  });

export const asvaLpTokenBalance = () =>
  new Promise(async (resolve, reject) => {
    const address = await currentEnv(process.env.REACT_APP_ENV);
    const { account } = await fetchAccountDetails();
    const buyAddress = account.address;
    try {
      const Erc20Instance = await new web3.eth.Contract(
        Erc20,
        address === 56
          ? '0x583c2a4226a3b9abb87e658d1808926a73647768'
          : '0x8a07ff83De1A7F8Ac6D1792a82e5Ba4b70aA6a8B'
      );
      const result = await Erc20Instance.methods.balanceOf(buyAddress).call(); // totalTokenstake for sigle vault
      const amount = await convertToEther(result);

      resolve(amount);
    } catch (error) {
      reject(error);
    }
  });

export const stakeAsvaToken = (
  amountOfTokens,
  contractAddress,
  tokenContractAddress
) =>
  new Promise(async (resolve, reject) => {
    const { account } = await fetchAccountDetails();
    const stakeAddress = account.address;
    // console.log(account, 'tttt');

    const weiAmount = web3.utils.toWei(amountOfTokens, 'ether');

    try {
      // approval

      const Erc20Instance = await new web3.eth.Contract(
        Erc20,
        tokenContractAddress
      );
      let newAllowance = await Erc20Instance.methods
        .allowance(stakeAddress, contractAddress)
        .call(); // totalTokenstake for sigle vault
      newAllowance = web3.utils.fromWei(newAllowance, 'ether');

      if (parseFloat(newAllowance) < parseFloat(amountOfTokens)) {
        await Erc20Instance.methods
          .approve(contractAddress, ethers.constants.MaxUint256.toString())
          .send({ from: stakeAddress });
      }

      // staking

      const stakeInstance = await new web3.eth.Contract(
        StakePool,
        contractAddress
      );
      const result = await stakeInstance.methods
        .lockStakingTokenToParticipate(weiAmount)
        .send({
          from: stakeAddress,
          gas: 500000,
        });
      // console.log(result, 'REEEEEESSS');
      // const amount = await convertToEther(result);
      // console.log(amount, amountOfTokens, 'sss');

      resolve(result);
    } catch (error) {
      reject(error);
    }
  });

export const unStakeAsvaToken = (contractAddress) =>
  new Promise(async (resolve, reject) => {
    const { account } = await fetchAccountDetails();
    const stakeAddress = account.address;
    // console.log(amountOfTokens, 'tttt');

    // const weiAmount = web3.utils.toWei(amountOfTokens, 'ether');

    try {
      const unStakeInstance = await new web3.eth.Contract(
        StakePool,
        contractAddress
      );
      const result = await unStakeInstance.methods.unLockStakingToken().send({
        from: stakeAddress,
        gas: 500000,
      });
      // console.log(result, 'REEEEEESSS');
      // const amount = await convertToEther(result);
      // console.log(amount, amountOfTokens, 'sss');

      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
