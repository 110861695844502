/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  text: 'Init',
  show: false,
  type: 'install', // 1 -> install  2 -> switch
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,

  reducers: {
    showNotification: (state, action) => ({
      ...state,
      text: action.payload.text,
      show: true,
      type: action.payload.type,
    }),

    hideNotification: (state) => ({
      ...state,
      show: false,
    }),
  },
});

export const { showNotification, hideNotification } = notificationSlice.actions;
export default notificationSlice.reducer;
