/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { Link, NavLink } from 'react-router-dom';
import { toggleTheme } from '../../store/slices/theme';
import {
  fetchAccountDetailsWindow,
  getAccountBalance,
  getUserAccounts,
} from '../../Web3/web3';
import {
  setAccountDetails,
  setUserBalance,
  setUserNetwork,
  walletDisconnect,
} from '../../store/slices/user';
import { useAccountChange, useNetworkChange } from '../../hooks';
import AsvaLabsDark from './Images/AsvaLabsDark.svg';
import AsvaLabsLight from './Images/AsvaLabsLight.svg';
import Wallet from './Images/Wallet.svg';
import ThemeChangeLight from './Images/ThemeChangeLight.svg';
import ThemeChangeDark from './Images/ThemeChangeDark.svg';
// import BNB from './Images/BNB.svg';
// import ArrowDark from './Images/ArrowDark.svg';
// import ArrowLight from './Images/ArrowLight.svg';
// import MetaMask from './Images/MetaMask.svg';
import MobileButtonLight from './Images/mobile-button-light.svg';
import MobileButtonDark from './Images/mobile-button-dark.svg';
import HoverImageDark from './Images/HoverImageDark.svg';
import HoverImageLight from './Images/HoverImageLight.svg';
// import CloseButton from './Images/CloseButton.svg';
// import Tick from './Images/Tick.svg';
import {
  NavRowContainer,
  NavTextContainer,
  NavLI,
  NavContainer,
  NavImageContainer,
  NavUL,
  NavButton,
  NavP,
  NavToggleBtn,
  NavImage,
  NavWrapperDiv,
  NavDivContainer,
  NavDiv,
  // NavBtnDiv,
  // NavDivNetworkBtn,
  // NavDivCompose,
  // NavSpan,
  NavMobileButton,
  // NavModalDiv,
  // NavContainerModal,
  // NavCloseIcon,
  // NavLinks,
  // NavConnectInfo,
  NavToggleBtnDiv,
  // NavModalConnectBtnDiv,
  // NavModalDivNetworkBtn,
  // NavModalDivCompose,
  // NavModalImage,
  // ModalStyles,
  NavBtnDivConnect,
  ShowDesktop,
  NavHoverDiv,
  // NavDivComposeImage,
  // NavModalContainerBtnDiv,
  // NavDivVerified,
  // NavSpanVerified,
} from './Navbar.styles';
import ChangeNetworkButton from './components/ChangeNetworkButton/ChangeNetworkButton';
import ChangeAddressButton from './components/ChangeAddressButton/ChangeAddressButton';
import {
  toggleConnectWallet,
  toggleMobileNavbar,
} from '../../store/slices/modal';
import MobileNavbarModal from '../Modal/MobileNavbarModal/MobileNavbarModal';
import {
  hideNotification,
  showNotification,
} from '../../store/slices/notification';

function Navbar() {
  const { isConnected, networkId } = useSelector((state) => state.user);
  const { selectedPoolV1Loading, selectedPoolV1 } = useSelector(
    (state) => state.pools
  );
  const [currentTab, setCurrentTab] = useState('');
  const [show, setshow] = useState(false);

  const dispatch = useDispatch();
  const network = useNetworkChange();
  const account = useAccountChange();
  const theme = useTheme();

  useEffect(() => {
    if (window.ethereum === undefined) {
      dispatch(
        showNotification({
          text: `Please install metamask`,
          type: 'install',
        })
      );
      return;
    }

    if (window.ethereum) {
      dispatch(hideNotification());
    }

    if (selectedPoolV1Loading === false && selectedPoolV1) {
      if (selectedPoolV1.projectNetwork.networkId !== networkId) {
        dispatch(
          showNotification({
            text: `Please connect to ${selectedPoolV1.projectNetwork.networkName} network`,
            type: 'connect',
          })
        );
      }

      if (selectedPoolV1.projectNetwork.networkId === networkId) {
        dispatch(hideNotification());
      }
    }
  }, [networkId]);

  const connectWallet = async () => {
    dispatch(toggleConnectWallet());
  };

  const updateUserBalance = async () => {
    const fetchedBalance = await getAccountBalance();
    dispatch(setUserBalance(parseFloat(fetchedBalance).toFixed(2)));
  };

  const userConnectedWallet = async () => {
    const details = await fetchAccountDetailsWindow();
    // setIsWalletLoggedIn(true);
    dispatch(setAccountDetails(details));
  };

  const initChecks = async () => {
    const accounts = await getUserAccounts();

    if (isConnected && accounts.length === 0) {
      userConnectedWallet();
    }

    if (isConnected && accounts.length > 0) {
      userConnectedWallet();
    }
  };

  useEffect(() => {
    initChecks();
  }, []);

  useEffect(() => {
    dispatch(setUserNetwork(network));
    updateUserBalance();
  }, [network]);

  useEffect(() => {
    if (account) {
      if (isConnected && account.length === 0) {
        dispatch(walletDisconnect());
      }

      if (account.length >= 1) {
        userConnectedWallet();
      }
    }
  }, [account]);

  const showModal = () => {
    dispatch(toggleMobileNavbar());
  };

  return (
    <NavContainer>
      <NavMobileButton onClick={showModal}>
        <img
          src={theme.mode === 'dark' ? MobileButtonDark : MobileButtonLight}
          alt="mobile-button"
        />
      </NavMobileButton>
      <NavRowContainer>
        <NavWrapperDiv to="/">
          <NavImageContainer>
            <img
              src={theme.mode === 'dark' ? AsvaLabsDark : AsvaLabsLight}
              alt="Asva Labs"
            />
          </NavImageContainer>
          <NavTextContainer>By AsvaLabs</NavTextContainer>
        </NavWrapperDiv>
        <NavDivContainer>
          <NavUL>
            <Link to="/">
              <NavLI
                onClick={() => setCurrentTab('Home')}
                borderImage={
                  currentTab === 'Home'
                    ? 'linear-gradient(118.21deg, #ec4781, #6038f7 130.15%)'
                    : '#12141C'
                }
                active={currentTab === 'Home'}
              >
                Projects
              </NavLI>
            </Link>

            {/* <NavLI
              onClick={() => setCurrentTab('Project Pool')}
              borderImage={
                currentTab === 'Project Pool'
                  ? 'linear-gradient(118.21deg, #ec4781, #6038f7 130.15%)'
                  : '#12141C'
              }
              active={currentTab === 'Project Pool'}
            >
              Project Pool
            </NavLI> */}
            <NavLink to="/stake" rel="noreferrer">
              <NavLI
                onClick={() => setCurrentTab('Stake/Farm')}
                borderImage={
                  currentTab === 'Stake/Farm'
                    ? 'linear-gradient(118.21deg, #ec4781, #6038f7 130.15%)'
                    : '#12141C'
                }
                active={currentTab === 'Stake/Farm'}
              >
                Stake/Farm
              </NavLI>
            </NavLink>
            <NavLink to="/" rel="noreferrer">
              <NavLI
                // onClick={() => setCurrentTab('Dashboard')}
                borderImage={
                  currentTab === 'Dashboard'
                    ? 'linear-gradient(118.21deg, #ec4781, #6038f7 130.15%)'
                    : '#12141C'
                }
                // active={currentTab === 'Dashboard'}
                disable
                onMouseEnter={(e) => {
                  setshow(true);
                }}
                onMouseLeave={(e) => {
                  setshow(false);
                }}
              >
                Dashboard
              </NavLI>
            </NavLink>
          </NavUL>
        </NavDivContainer>
        {show && (
          <NavHoverDiv>
            <img
              src={theme.mode === 'dark' ? HoverImageDark : HoverImageLight}
              alt="HoverImage"
              width="80px"
            />
          </NavHoverDiv>
        )}
      </NavRowContainer>
      <NavDiv>
        {isConnected ? (
          <>
            <ShowDesktop>
              <ChangeNetworkButton />
              <ChangeAddressButton />
            </ShowDesktop>
            <NavToggleBtnDiv
              button
              type="button"
              onClick={() => {
                dispatch(toggleTheme());
              }}
            >
              <NavToggleBtn>
                <NavImage color="#FFFFFF">
                  <img
                    src={
                      theme.mode === 'dark' ? ThemeChangeDark : ThemeChangeLight
                    }
                    alt="ChangeTheme"
                  />
                </NavImage>
              </NavToggleBtn>
            </NavToggleBtnDiv>
          </>
        ) : (
          <>
            <NavBtnDivConnect onClick={() => connectWallet(window)}>
              <NavButton>
                <img src={Wallet} alt="Wallet" />
                <NavP>Connect Wallet</NavP>
              </NavButton>
            </NavBtnDivConnect>
            <NavToggleBtnDiv
              button
              type="button"
              onClick={() => {
                dispatch(toggleTheme());
              }}
            >
              <NavToggleBtn>
                <NavImage color="#FFFFFF">
                  <img
                    src={
                      theme.mode === 'dark' ? ThemeChangeDark : ThemeChangeLight
                    }
                    alt="ChangeTheme"
                  />
                </NavImage>
              </NavToggleBtn>
            </NavToggleBtnDiv>
          </>
        )}
      </NavDiv>
      <MobileNavbarModal
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        connectWallet={connectWallet}
      />
    </NavContainer>
  );
}

export default Navbar;
