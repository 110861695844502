import ArbiLogo from '../assets/arbi.png';
import OpLogo from '../assets/opti.png';
import BnbLogo from '../assets/Bnb.svg';
import EthLogo from '../assets/eth.png';
import UsdtLogo from '../assets/usdt.png';
import UsdcLogo from '../assets/usdc.png';
import BusdLogo from '../assets/token.png';

const TokenImages = {
  BNB: BnbLogo,
  OP: OpLogo,
  ARB: ArbiLogo,
  ETH: EthLogo,
  USDT: UsdtLogo,
  USDC: UsdcLogo,
  BUSD: BusdLogo,
};
export default TokenImages;
