/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useTheme } from 'styled-components';
import { Divider } from 'antd';
import { useSelector } from 'react-redux';
import { useWindowSize } from '../../hooks/index';
// import { Carousel } from 'antd';
// import 'antd/dist/antd.css';

// import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
// import { Pagination } from 'swiper';
// import 'swiper/swiper.min.css';
// import 'swiper/modules/pagination/pagination.min.css';

// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

import {
  CaraouselContainer,
  // CardsContainer,
  // IndicatorsContainer,
  FlexContainer,
  Video,
  VidoeContainer,
  //   LogoWrapper,
  ProjectLogo,
  VideoTitleRow,
  //   VideoText,
  ProjectName,
  DateTime,
  ProjectInfoContainer,
  AbsoluteControls,
  ControlsRow,
  VideoTag,
  SoundImg,
  AbsoluteVideoInfo,
  CustomCarousel,
  ImageSlide,
  PaginationContainer,
  SlideNumsWrapper,
  UpButton,
  DownButton,
  SlideNum,
  CustomCarouselHorizontal,
  CaraouselContainerHorizontal,
  ImageSlideVertical,
  // VideoContainer,
  AbsoluteVideoInfoHorizontal,
  VideoHorizontal,
  DefaultSoundImg,
  DefaultVideoContainer,
  DefaultVideo,
  //   ProjectInfoContainer,
} from './FeaturedPoolsCarousel.styles';
import volumeUp from '../../assets/volumeUp.svg';
import muteIcon from '../../assets/muteIcon.svg';

function CustomPagination(
  i,
  currentslide,
  setcurrentslide,
  total,
  theme,
  width
) {
  // console.log('test', i, j);
  const handleUp = () => {
    if (currentslide > 0) {
      setcurrentslide(currentslide - 1);
    }
  };
  const handleDown = () => {
    if (currentslide < total - 1) {
      setcurrentslide(currentslide + 1);
    }
  };
  // console.log(theme);
  return (
    <PaginationContainer>
      <div
        style={{
          marginRight: '3px',
          marginBottom:
            width < parseInt(theme.breakpoints.tablet, 10) ? '4px' : '',
          display:
            width > parseInt(theme.breakpoints.tablet, 10) ? 'block' : 'flex',
        }}
      >
        {i}
      </div>
      <Divider
        type={
          width > parseInt(theme.breakpoints.tablet, 10)
            ? 'vertical'
            : 'horizontal'
        }
        style={{
          background:
            theme.mode === 'dark'
              ? theme.colors.grey[400]
              : theme.colors.grey[300],
          height:
            width > parseInt(theme.breakpoints.tablet, 10) ? '100%' : 'auto',
          width:
            width > parseInt(theme.breakpoints.tablet, 10) ? 'auto' : '100%',
        }}
      />
      <UpButton
        onClick={handleUp}
        currentslide={currentslide}
        lastslide={total - 1}
      />
      <SlideNumsWrapper>
        <SlideNum>
          {currentslide + 1 < 10 ? `0${currentslide + 1}` : currentslide + 1}
        </SlideNum>{' '}
        <SlideNum opacity="0.5">{total < 10 ? `0${total}` : total} </SlideNum>
      </SlideNumsWrapper>
      <DownButton
        onClick={handleDown}
        currentslide={currentslide}
        lastslide={total - 1}
      />
    </PaginationContainer>
  );
}

function FeaturedPoolsCarousel() {
  const theme = useTheme();
  const { width } = useWindowSize();
  const carouselRef = useRef();

  const upcomingProjects = useSelector((state) => state.pools.upcomingPools);
  const activeProjects = useSelector((state) => state.pools.activePools);

  const [featuredProjects, setFeaturedProjects] = useState([]);

  useEffect(() => {
    const upcomingWithVideos = upcomingProjects.filter(
      (item) =>
        item?.media[0]?.type === 'VIDEO' || item?.media[0]?.type === 'GIF'
    );
    const activeWithVideos = activeProjects.filter(
      (item) =>
        item?.media[0]?.type === 'VIDEO' || item?.media[0]?.type === 'GIF'
    );

    // let featuredProjects = [];

    if (upcomingWithVideos.length > 3) {
      // featuredProjects = upcomingProjects;
      setFeaturedProjects(upcomingWithVideos);
    } else if (activeWithVideos.length > 3) {
      // featuredProjects = activeProjects;
      setFeaturedProjects(activeWithVideos);
    } else {
      // featuredProjects = [...activeWithVideos, ...upcomingWithVideos];
      const tempFeaturedProjects = [...activeWithVideos, ...upcomingWithVideos];
      setFeaturedProjects(tempFeaturedProjects);
    }
  }, [upcomingProjects, activeProjects]);

  // console.log('width', width);
  // console.log(
  //   'activeV',
  //   activeWithVideos,
  //   'upcomingV',
  //   upcomingWithVideos,
  //   'featured',
  //   featuredProjects
  // );

  const [isMute, setIsMute] = useState(true);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [index, setIndex] = useState(0);

  const onSlideClick = (i) => {
    // console.log(i, 'slideIndex');
    setCurrentSlide(i);
  };

  function convertDateFormat(d) {
    const dateToRender = moment.utc(d).format('Do MMM YYYY HH:mm A');
    return `${dateToRender} UTC`;
  }

  const goToNextSlide = () => {
    if (featuredProjects.length > 1) {
      // console.log('GoToNext');
      carouselRef.current.next();
    }

    // console.log('Nothing');
  };

  useEffect(() => {
    function playVideo() {
      const video = document.getElementById(`videoHorizontalSlides${index}`);
      // video.load();
      video?.play();
      // console.log(video, 'jdjks');
    }
    playVideo();
  }, [index]);

  return (
    <FlexContainer>
      {featuredProjects.length === 0 && (
        <DefaultVideoContainer>
          <DefaultVideo
            muted={isMute}
            autoPlay
            loop
            loading="lazy"
            src="https://archive.org/download/BigBuckBunny_124/Content/big_buck_bunny_720p_surround.mp4"
            // poster="https://peach.blender.org/wp-content/uploads/title_anouncement.jpg?x11217"
            // width={
            //   width < parseInt(theme.breakpoints.tablet, 10) ? '100%' : '85%'
            // }
            // min-width="80vw"
            // height="500px"
            // style={{ margin: 'auto' }}
          >
            Sorry, your browser doesn't support embedded videos, but don't
            worry, you can <a href="https://">download it</a>
            and watch it with your favorite video player!
          </DefaultVideo>
          <DefaultSoundImg
            src={isMute ? muteIcon : volumeUp}
            alt="volume/mute"
            onClick={() => setIsMute(!isMute)}
          />
        </DefaultVideoContainer>
      )}

      {featuredProjects.length > 0 && featuredProjects.length <= 3 && (
        <CaraouselContainerHorizontal>
          <CustomCarouselHorizontal
            ref={carouselRef}
            dots
            infinite={false}
            infiniteSlide={false}
            slidesToShow={1}
            slidesToScroll={1}
            dotPosition="bottom"
            // focusOnSelect
            draggable
            swipeToSlide
            // width="100%"
            // verticalSwiping={width > parseInt(theme.breakpoints.tablet, 10)}
            // appendDots={(i) =>
            //   CustomPagination(
            //     i,
            //     currentSlide,
            //     setCurrentSlide,
            //     featuredProjects.length,
            //     theme,
            //     width
            //   )
            // }
            beforeChange={(oldIndex, newIndex) => {
              setIndex(newIndex);
            }}
          >
            {featuredProjects.map((project, i) => {
              if (project?.media[0].type === 'GIF') {
                return (
                  <ImageSlide
                    key={project._id}
                    index={i}
                    src={project.projectBannerUrl}
                    // onClick={() => onSlideClick(i)}
                  />
                );
              }
              return (
                <Video
                  key={project._id}
                  id={`videoHorizontalSlides${i}`}
                  muted={isMute}
                  loop={featuredProjects.length === 1}
                  autoPlay
                  loading="lazy"
                  src={project?.media[0]?.url}
                  onEnded={goToNextSlide}

                  // poster={project.projectBannerUrl}
                  // width="85%"
                >
                  Sorry, your browser doesn't support embedded videos, but don't
                  worry, you can{' '}
                  <a href={project?.media[0]?.url}>download it</a>
                  and watch it with your favorite video player!
                </Video>
              );
            })}
          </CustomCarouselHorizontal>
          <AbsoluteControls>
            <ControlsRow>
              <VideoTag bgColor={theme.colors.green['400']}>Featured</VideoTag>
              <VideoTag marginLeft="0" bgColor={theme.colors.yellow['400']}>
                {featuredProjects[index]?.typeOfProject}
              </VideoTag>
              {featuredProjects[index]?.media[0].type === 'VIDEO' && (
                <SoundImg
                  src={isMute ? muteIcon : volumeUp}
                  alt="volume/mute"
                  onClick={() => setIsMute(!isMute)}
                />
              )}
            </ControlsRow>
          </AbsoluteControls>

          <AbsoluteVideoInfoHorizontal>
            <VideoTitleRow>
              <ProjectInfoContainer>
                <ProjectLogo
                  src={featuredProjects[index]?.projectLogoUrl}
                  alt="project-logo"
                />
                <ProjectName>
                  {featuredProjects[index]?.projectName}
                </ProjectName>
              </ProjectInfoContainer>

              {/* <DateTime>22nd Apr 2022 | 2:30 PM UTC</DateTime> */}
              <DateTime>
                {featuredProjects[index]?.isTBA
                  ? 'TBA'
                  : convertDateFormat(
                      featuredProjects[index]?.idoDates.roundOneStartDate
                    )}
              </DateTime>
            </VideoTitleRow>
          </AbsoluteVideoInfoHorizontal>
        </CaraouselContainerHorizontal>
      )}

      {featuredProjects.length > 3 && (
        <>
          <VidoeContainer width={featuredProjects.length > 3 ? '75%' : '100%'}>
            {featuredProjects[currentSlide]?.media[0].type === 'VIDEO' ? (
              <VideoHorizontal
                // controls
                muted={isMute}
                autoPlay
                loading="lazy"
                // src="https://archive.org/download/BigBuckBunny_124/Content/big_buck_bunny_720p_surround.mp4"
                // src="https://siasky.net/AABNK8Z_hYqtpveexrhRQK9uerebR8Q3cvHiyWGnIBznGA"
                src={featuredProjects[currentSlide]?.media[0].url}
                // poster="https://peach.blender.org/wp-content/uploads/title_anouncement.jpg?x11217"
                // poster={featuredProjects[currentSlide]?.projectBannerUrl}
                width="100%"
                // height="100%"
                onEnded={() => {
                  const lastSlide = featuredProjects.length - 1;
                  if (currentSlide === lastSlide) {
                    setCurrentSlide(0);
                  } else {
                    setCurrentSlide(currentSlide + 1);
                  }
                }}
              >
                Sorry, your browser doesn't support embedded videos, but don't
                worry, you can{' '}
                <a href={featuredProjects[currentSlide]?.media[0].url}>
                  download it
                </a>
                and watch it with your favorite video player!
              </VideoHorizontal>
            ) : (
              <img
                src={
                  featuredProjects[currentSlide]?.media[0].url ||
                  featuredProjects[currentSlide]?.projectBannerUrl
                }
                alt="project-media"
                width="100%"
                style={{ borderRadius: '20px' }}
              />
            )}

            <AbsoluteControls>
              <ControlsRow>
                <VideoTag bgColor={theme.colors.green['400']}>
                  Featured
                </VideoTag>
                <VideoTag marginLeft="0" bgColor={theme.colors.yellow['400']}>
                  {featuredProjects[currentSlide]?.typeOfProject}
                </VideoTag>
                {featuredProjects[currentSlide]?.media[0].type === 'VIDEO' && (
                  <SoundImg
                    src={isMute ? muteIcon : volumeUp}
                    alt="volume/mute"
                    onClick={() => setIsMute(!isMute)}
                  />
                )}
              </ControlsRow>
            </AbsoluteControls>

            <AbsoluteVideoInfo>
              <VideoTitleRow>
                <ProjectInfoContainer>
                  <ProjectLogo
                    src={featuredProjects[currentSlide]?.projectLogoUrl}
                    alt="project-logo"
                  />
                  <ProjectName>
                    {featuredProjects[currentSlide]?.projectName}
                  </ProjectName>
                </ProjectInfoContainer>

                <DateTime>
                  {featuredProjects[index]?.isTBA
                    ? 'TBA'
                    : convertDateFormat(
                        featuredProjects[index]?.tokenDetails.tokenSaleDate
                      )}
                </DateTime>
              </VideoTitleRow>
            </AbsoluteVideoInfo>
          </VidoeContainer>

          {featuredProjects.length > 3 && (
            <CaraouselContainer marginLeft="1rem">
              <CustomCarousel
                dots
                infinite={false}
                infiniteSlide={false}
                slidesToShow={3}
                slidesToScroll={1}
                dotPosition={
                  width > parseInt(theme.breakpoints.tablet, 10)
                    ? 'right'
                    : 'bottom'
                }
                vertical={width > parseInt(theme.breakpoints.tablet, 10)}
                focusOnSelect
                draggable
                swipeToSlide
                verticalSwiping={width > parseInt(theme.breakpoints.tablet, 10)}
                appendDots={(i) =>
                  CustomPagination(
                    i,
                    currentSlide,
                    setCurrentSlide,
                    featuredProjects.length,
                    theme,
                    width
                  )
                }
              >
                {featuredProjects.map((project, i) => (
                  <ImageSlideVertical
                    key={project._id}
                    index={i}
                    src={project.projectBannerUrl}
                    onClick={() => onSlideClick(i)}
                  />
                ))}
              </CustomCarousel>
            </CaraouselContainer>
          )}
        </>
      )}

      {/* <Slider
      dots={false}
        arrows={false}
        infinite={false}
        infiniteSlide={false}
        slidesToShow={3}
        slidesToScroll={1}
        autoplay
        // autoplaySpeed={3000}
        vertical
        swipeToSlide
        verticalSwiping
        focusOnSelect
        adaptiveHeight
        // variableWidth
      >
        <CustomSlide index={0} />
        <CustomSlide index={1} />
        <CustomSlide index={2} />
        <CustomSlide index={3} />
        <CustomSlide index={4} />
        <div>
          <img src={thumbnail} alt="thumbnail" />
        </div>
        <div>
          <img src={thumbnail2} alt="thumbnail" />
        </div>
        <div>
          <img src={thumbnail3} alt="thumbnail" />
        </div>
        <div>
          <img src={thumbnail} alt="thumbnail" />
        </div>
        <div>
          <img src={thumbnail2} alt="thumbnail" />
        </div>
        <div>
          <img src={thumbnail3} alt="thumbnail" />
        </div>
      </Slider> */}

      {/* <Swiper
        direction="vertical"
        slidesPerView={3}
        // scrollbar={{ hide: true }}
        // modules={[Pagination]}
        // pagination={{
        //   // dynamicBullets: true,
        //   clickable: true,
        // }}
      >
        <SwiperSlide>
          <img src={thumbnail} alt="thumbnail" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={thumbnail2} alt="thumbnail" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={thumbnail3} alt="thumbnail" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={thumbnail} alt="thumbnail" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={thumbnail2} alt="thumbnail" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={thumbnail3} alt="thumbnail" />
        </SwiperSlide>
      </Swiper> */}

      {/* <CaraouselContainer>
        <CardsContainer>
          <h1>Test Card</h1>
          <h1>Test Card</h1>
          <h1>Test Card</h1>
        </CardsContainer>
        <IndicatorsContainer>
          <p>i1</p>
          <p>i2</p>
          <p>i3</p>
        </IndicatorsContainer>
      </CaraouselContainer> */}
    </FlexContainer>
  );
}

export default FeaturedPoolsCarousel;
