import { Skeleton } from 'antd';
import React from 'react';
import styled from 'styled-components';

// const Container = styled.div`
//    display: flex;
//     width: 100%;
//     margin: 59px auto 20px;
//     max-width: 31rem;
//     -webkit-box-pack: justify;
//     justify-content: space-between;
//     -webkit-box-align: center;
//     align-items: center;
//     background: rgb(255, 255, 255);
//     padding: 30px 50px;
//     border-radius: 12px;
//     border: 1px solid rgb(184, 193, 218);
//     box-sizing: border-box;
// `;
const Container2 = styled.div`
height: 120px;
display:flex;  
  border-radius: 10px;
  margin: 1% auto;
  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['300']
      : props.theme.colors.grey['100']};

`;

const Image = styled.div`
  width:10%;
  padding:10px;

  .ant-skeleton-input {
      height: 100px;
    padding: 12px 60px 10px 0px;
    border-radius: 10px;
  }
`;
const Container = styled.div`
display:flex;
flex-direction:column;
padding:20px;
width:100%;

`;
// const Container3 = styled.div`
// display:flex;
// flex-direction:column;
// padding:20px;

// `;
const Container4 = styled.div`

`;
const Images = styled.div`
  width:100%;
  padding:10px;

  .ant-skeleton-input {
      height: 10px;
    padding: 12px 60px 10px 0px;
    border-radius:10px;
    
  }
`;
// const Imag = styled.div`
// width:100%;
//   padding:10px;

//   .ant-skeleton-input {
//       height: 10px;
//     padding: 12px 60px 10px 0px;
    
//   }
// `;

// const Content = styled.div`
//   flex: 2;

//   .ant-skeleton-input {
//     height: 345px;
//     border-radius: 10px;
//   }
// `;

function EndedPoolListSkeleton() {
  return (
      
      <Container2>
        <Container4>


        <Image>
        <Skeleton.Input active block />
      </Image>
        </Container4>
      <Container>
        <Images>
        <Skeleton.Input active block />
      </Images>
      <Images>
        <Skeleton.Input active block />
      </Images>
      </Container>
     
      
      {/* <Images>
        
      </Images> */}
      </Container2>
      
  );
}

export default EndedPoolListSkeleton;
