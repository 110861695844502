import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Divider,
  CardContainer,
  CardImgContainer,
  CardLogo,
  DashedDivider,
  ItemRow,
  NameContainer,
  NetworkInfo,
  // PipeDivider,
  ProjectName,
  ProjectType,
  RowText,
  RowTextSpan,
  SupportedTokens,
  TitleRow,
  TokenName,
  BannerImg,
} from './VerticalCard.styles';
import ProgressBar from '../ProgressBar/ProgressBar';
import NetworkImg from '../NetworkImg/NetworkImg';
import WhitelistTag from '../WhitelistTag/WhitelistTag';
import VerticalCardFooter from './components/VerticalCardFooter';
import { Web3Class } from '../../Web3/web3Class';

// eslint-disable-next-line react/prop-types
function VerticalCard({ type, data }) {
  // const theme = useTheme();
  const isSocketConnected = useSelector((state) => state.socket.isConnected);
  const socket = useSelector((state) => state.socket.data);

  const [percentage, setPercentage] = useState(0);

  const getActiveCardPoolProgress = async () => {
    if (data.projectContractAddress) {
      try {
        const web3Instance = new Web3Class(data);
        const percentageData =
          await web3Instance.calculateOverallPoolPercentage();
        // let tierMax1 = calculatePriceOfTokensRoundDown(
        //   tierMax,

        // );

        // tierMax1 = convertNegetiveNumberToNumber(tierMax1);
        setPercentage(percentageData);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (type === 'active') {
      getActiveCardPoolProgress();
    }
  }, []);
  console.log(percentage);
  useEffect(() => {
    if (isSocketConnected) {
      socket.on('project-progress', (projectData) => {
        if (projectData.projectId === data._id) {
          setPercentage(projectData.overallPercentage);
        }
      });
    }
  }, [isSocketConnected, socket, data]);

  return (
    <Link
      to={
        data.projectStatus === 'upcoming' && data.isUpcomingDisabled === false
          ? `#`
          : `/pools/${data._id}`
      }
    >
      <CardContainer>
        <CardImgContainer>
          <BannerImg src={data.projectBannerUrl} alt={data.projectName} />
          <ProjectType type={data.typeOfProject}>
            {data.typeOfProject}
          </ProjectType>
        </CardImgContainer>

        <div className="details-container">
          <CardLogo>
            <img src={data.projectLogoUrl} alt={data.projectName} />
          </CardLogo>
          <TitleRow>
            <NameContainer>
              <ProjectName>{data.projectName}</ProjectName>
              <TokenName>{data.tokenDetails.tokenName}</TokenName>
            </NameContainer>
            <NetworkInfo>
              <NetworkImg networkId={data.projectNetwork.networkId} />
              {/* <PipeDivider>|</PipeDivider> */}
              <SupportedTokens>{data.supportedToken}</SupportedTokens>
            </NetworkInfo>
          </TitleRow>

          <ItemRow>
            <RowText>Total Raise</RowText>
            <DashedDivider
              strLength={data.tokenDetails.totalFundraise.toString().length}
            />
            <RowText>
              {data.tokenDetails.totalFundraise === 0
                ? 'TBA'
                : `${data.tokenDetails.totalFundraise.toLocaleString()} USD`}
            </RowText>
          </ItemRow>

          {type === 'active' && (
            <ItemRow>
              <RowText>
                Sale Status
                {percentage < 100 ? (
                  <RowTextSpan color="#3beeadcc">(In progress)</RowTextSpan>
                ) : (
                  <RowTextSpan color="#FB5959">(Sold Out)</RowTextSpan>
                )}
              </RowText>
              <ProgressBar completed={percentage} width={40} />
            </ItemRow>
          )}

          {type === 'upcoming' && (
            <ItemRow>
              <RowText>Whitelist Status</RowText>
              <WhitelistTag type={type} data={data} />
            </ItemRow>
          )}

          <Divider />

          <VerticalCardFooter type={type} data={data} percentage={percentage} />
        </div>
      </CardContainer>
    </Link>
  );
}

export default VerticalCard;
