import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
// import dummyimage from '../../../assets/dummyimage.png';
// import xyzNetwork from '../../../assets/XYZ_network.png';
// import dummylogo from '../../../assets/dummylogo.png';
// import Searchicon from '../../../assets/Searchicon.png';

import {
  EndedPoolMobileContainer,
  EndedpoolcardMobile,
  //   Image,
  IdotypeMobile,
  // HeadMobile,
  TextMobile,
  DataMobile,
  ImageBackgroundMobile,
  ImageMobile,
  ImagedivMobile,
  TotalraiseMobile,
  TrDataMobile,
  TrHeadMobile,

  // Totalparticipants,
  // TpHead,
  // TpData,
  EndedPoolContainer,
  // Alltimehigh,
  // AthData,
  // AthHead,
  EndedDate,
  // EDData,
  EDHead,
  Status,
  SHead,
  SData,
  // Hr,s
  PublicIDO,
  // NetworkImge,
  EndedPoolMiddleConatiner,
  // EndedPoolContainerLast,
  // Inputfilter,
  // Input,
  // Filter,
  // Li,
  // SearchIcon,

  //   Wrapper,
  //   Idotype,
  //   Head,
  //   Text,
  //   Data,
  //   Totalraise,
  //   TrHead,
  //   TrData,
  //   EndedDate,
  //   EDHead,
  //   EDData,
  //   Status,
  //   SHead,
  //   SData,
  //   Imagediv,
  //   Network,
  //   NetworkHead,
  //   NetworkImage,
  //   NetworkName,
  //   NetworkText,
  //   ProjectType,
  //   ProjectTypeHead,
  //   ProjectTypeText,
  //   ImageBackground,
  EndedDateMobiel,
} from './EndedPoolCard.styles';
import NetworkImg from '../../NetworkImg/NetworkImg';
import { completedProjects } from '../../../helpers/common';

function EndedPoolMobile({ data }) {
  // const networkName = {
  //   97: 'BSC',
  //   137: 'Polygon',
  //   43114: 'Avalance',
  //   56: 'BSC',
  //   80001: 'Polgon',
  //   43113: 'Avalance',
  // };
  return (
    <Link to={data.projectStatus === 'ended' && `/pools/${data._id}`}>
      <EndedPoolMobileContainer>
        <EndedpoolcardMobile>
          <EndedPoolContainer>
            <ImagedivMobile image={data.projectBannerUrl}>
              <ImageBackgroundMobile>
                <ImageMobile src={data.projectLogoUrl} />
              </ImageBackgroundMobile>
            </ImagedivMobile>
            <IdotypeMobile>
              <TextMobile>
                <PublicIDO>Public IDO</PublicIDO>
                <NetworkImg networkId={97} />
              </TextMobile>
              <DataMobile>{data.projectName}</DataMobile>
            </IdotypeMobile>
          </EndedPoolContainer>
          <EndedPoolMiddleConatiner>
            <TotalraiseMobile>
              <TrHeadMobile>Total raise</TrHeadMobile>
              <TrDataMobile>
                {`${Math.floor(data.tokenDetails.totalFundraise).toLocaleString(
                  'en-US'
                )} USD`}
              </TrDataMobile>
            </TotalraiseMobile>
            <EndedDate>
              <EDHead>Ended date</EDHead>
              <EndedDateMobiel>
                {moment(data.idoDates.roundTwoEndDate).format('DD MMM  YYYY')}
              </EndedDateMobiel>
            </EndedDate>
            <Status>
              <SHead>Status</SHead>
              <SData
                color={data.projectStatus === 'ended' ? '#FB5959' : '#FCB778'}
              >
                {completedProjects.includes(data._id)
                  ? 'Refunded'
                  : data.projectStatus === 'ended'
                  ? 'Ended'
                  : 'Claimable'}
              </SData>
            </Status>
          </EndedPoolMiddleConatiner>
        </EndedpoolcardMobile>
      </EndedPoolMobileContainer>
    </Link>
  );
}

export default EndedPoolMobile;
