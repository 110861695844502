import styled from 'styled-components';

export const NotificationContainer = styled.div`
  padding: 16px 0;

  background-color: ${(props) => {
    if (props.type === 'network') {
      return props.theme.mode === 'dark'
        ? 'rgba(251, 89, 89, 0.2)'
        : 'rgba(251, 89, 89, 0.2)';
    }
    if (props.type === 'connect') {
      return props.theme.mode === 'dark'
        ? 'rgba(252, 183, 120, 0.2)'
        : props.theme.colors.yellow[500];
    }

    if (props.type === 'install') {
      return props.theme.mode === 'dark'
        ? 'rgba(251, 89, 89, 0.2)'
        : props.theme.colors.red[400];
    }

    if (props.type === 'old-link') {
      return props.theme.mode === 'dark'
        ? 'rgba(59, 238, 173, 0.2)'
        : props.theme.colors.green[500];
    }

    return props.theme.mode === 'dark'
      ? 'rgba(252, 183, 120, 0.2)'
      : props.theme.colors.yellow[500];
  }};

  .text {
    text-align: center;
    margin: 0;
    color: ${(props) => {
      if (props.type === 'connect') {
        return props.theme.mode === 'dark'
          ? props.theme.colors.yellow[400]
          : props.theme.colors.grey[100];
      }

      if (props.type === 'install') {
        return props.theme.mode === 'dark'
          ? props.theme.colors.red[400]
          : props.theme.colors.grey[100];
      }

      if (props.type === 'old-link') {
        return props.theme.mode === 'dark'
          ? 'rgba(59, 238, 173, 0.8)'
          : props.theme.colors.grey[100];
      }
      if (props.type === 'network') {
        return props.theme.mode === 'dark' ? '#FB5959' : '#FB5959';
      }
      return props.theme.colors.yellow[400];
    }};

    .old-link-a {
      text-decoration: underline;
      color: ${(props) =>
        props.theme.mode === 'dark'
          ? 'rgba(59, 238, 173, 0.8)'
          : props.theme.colors.grey[100]};
    }
  }
`;

export const NotificationText = styled.p``;
