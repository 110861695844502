import styled from 'styled-components';
import { Modal, Form, Input, Upload } from 'antd';

export const ModalStyles = styled(Modal)`
  background-color: '#fff';

  & .ant-modal-body {
    color: blue;
    border-radius: 12px;
  }

  & .ant-modal-content {
    /* background: transparent; */
    background-color: ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black['300']
        : props.theme.colors.grey['100']};
    border-radius: 12px;
  }

  & .ant-modal-close {
    display: none;
  }
`;

export const CloseIcon = styled.img`
  position: absolute;
  top: 10px;
  left: auto;
  right: 10px;
  z-index: 100;
  cursor: pointer;
`;

export const Container = styled.div`
  padding-top: 30px;
`;

// Form Items

export const CustomForm = styled(Form)`
  .ant-row {
    flex-direction: column;
  }
  .ant-form-item-label {
    overflow: visible;
  }
  .ant-form-item-label > label {
    color: ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.grey['200']
        : props.theme.colors.black['400']};
    /* font-size: 12px; */
  }
  .ant-form-item {
    margin-bottom: 15px;
  }
`;
export const FormItem = styled(Form.Item)`
  .ant-input {
    background-color: ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black['300']
        : props.theme.colors.grey['100']};
    border-radius: 4px;
    color: ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.grey['200']
        : props.theme.colors.black['400']};
    border-color: ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.grey['400']
        : props.theme.colors.black['100']};
  }
  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
    background-color: ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black['300']
        : props.theme.colors.grey['100']};
  }
`;
export const CustomInput = styled(Input)``;
export const CustomUpload = styled(Upload)`
  .ant-upload.ant-upload-select-picture-card {
    height: 110px;
    width: 110px;
    background-color: ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black['300']
        : props.theme.colors.grey['100']};
    border-radius: 8px;
    border-color: ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.grey['400']
        : props.theme.colors.black['100']};
    display: ${(props) => (props.showImg ? 'none' : 'block')};
  }
  .ant-upload {
    color: ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.grey['200']
        : props.theme.colors.black['400']};
    font-size: 10px;
  }
  .ant-upload-list-picture-card-container {
    width: 110px;
    height: 110px;
    display: ${(props) => (props.showImg ? 'block' : 'none')};
    border: 1px dashed;
    border-radius: 8px;
    border-color: ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.grey['400']
        : props.theme.colors.black['100']};
  }
  .ant-upload-list-picture .ant-upload-list-item,
  .ant-upload-list-picture-card .ant-upload-list-item {
    border: none;
  }
`;
export const CustomButton = styled.button`
  width: 100%;
  padding: 5px;
  background: ${(props) =>
    props.disabled === true
      ? '#31374D'
      : 'linear-gradient(118.21deg, #ec4781 22.9%, #6038f7 130.15%);'};
  border: none;
  font-weight: 500;
  font-size: 16px;
  border-radius: 5px;
  color: ${(props) => (props.disabled === true ? '#777D92' : '#ffffff')};
`;

export const FormWrapper = styled.div``;
export const FormHeading = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['200']
      : props.theme.colors.black['400']};
`;
export const FormSubHeading = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['100']
      : props.theme.colors.black['100']};
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  /* width: 100%; */
`;
export const UploadWrapper = styled.div``;
export const DetailsWrapper = styled.div``;
