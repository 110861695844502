import styled from 'styled-components';

export const StakeWrapper = styled.div`
  /* width: 50%; */
  display: flex;
  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;
export const RightStakeWrapper = styled.div`
  width: 50%;
  @media (max-width: 1200px) {
    width: 100%;
  }
`;
export const StakeContainer = styled.div`
  display: flex;
  justify-content: start;
  /* justify-content: space-between; */
  align-items: center;

  background: #ffffff;
  background: ${(props) => props.theme.mode === 'dark' && '#191c27'};

  margin: 10px;
  border-radius: 10px;
`;

export const Stakeul = styled.ul`
  display: flex;
  margin: 0;
  padding: 0 10px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 10px 10px 0 10px;
  }
`;
export const Stakeli = styled.li`
  font-size: 20px;
  font-weight: 50px;
  padding: 20px;
  list-style: none;
  color: #777d92;
  cursor: pointer;
  border-radius: 12px 12px 0px 0px;
  &.active {
    color: #191c27;
    color: ${(props) => props.theme.mode === 'dark' && '#b8c1da'};
    border-bottom: 3px solid #5e50ff;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 12px;
    padding: 5px;
    padding-bottom: 10px;
  }
`;

// UserStaking Start
export const MyStakeContainer = styled.div`
  background: #ffffff;
  background: ${(props) => props.theme.mode === 'dark' && '#191c27'};

  border-radius: 10px;
  padding: 0 10px;
  margin: 10px;
  overflow: hidden;
  height: 110px;
  transition: height 0.3s !important;
  &.active {
    transition: height 0.3s !important;
    height: 230px;
  }
  cursor: ${(props) => (props.cursor ? 'pointer' : 'default')};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    height: 130px;
    &.active {
      transition: height 0.3s !important;
      height: 250px;
    }
  }
`;

export const UserStakeContainer = styled.div`
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 22px 10px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    flex-direction: column;
    display: flex;
    justify-content: start;
    align-items: start;
    margin: 10px;
    padding: 5px 0;
  }
`;
export const LeftStake = styled.div`
  display: flex;
  /* padding: 20px; */
`;
export const RightStake = styled.div`
  /* padding: 20px; */
  display: flex;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-top: 20px;
    margin-left: 10px;
    padding: 0;
  }
`;
export const RightButton = styled.div`
  /* padding: 20px; */
  display: flex;
`;
export const LogoImg = styled.img`
  margin: 0 10px;
`;
export const Textbox = styled.div`
  padding: 0 10px;

  flex-direction: column;
  justify-content: start;
  margin: 0 ${(props) => props.margin};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 0;
    margin-right: 7px;
    padding: 0;
  }
`;
export const StakeLeftHeading = styled.p`
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;

  color: #12141c;
  color: ${(props) => props.theme.mode === 'dark' && '#b8c1da'};
`;
export const MyStake = styled.p`
  margin: 0;

  color: #31374d;
  color: ${(props) => props.theme.mode === 'dark' && '#777d92'};

  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
`;
export const MyStake1 = styled.p`
  margin: 0;
  color: #777d92;

  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
`;
export const StakeReward = styled.p`
  margin-top: 10px;
  margin-bottom: 0;
  color: ${(props) => props.color};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-top: 5px;
    margin-right: 10px;
  }
`;
// export const StakePeriod = styled.p`
//   margin: 0;
//   color: #777d92;
//   font-size: 12px;
//   font-weight: 400;
//   line-height: 12px;
// `;
export const MyStakeStatus = styled.p`
  margin-top: 10px;
  margin-bottom: 0;

  color: #12141c;
  color: ${(props) => props.theme.mode === 'dark' && '#b8c1da'};

  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-top: 5px;
  }
`;

// UserStaking End

// Deposit and withdrow hidden component

export const HiddenDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px 20px 20px 20px;

  background: #e7eaf3;
  background: ${(props) => props.theme.mode === 'dark' && '#12141c'};
  border-radius: 10px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 20px 10px 20px 20px;
  }
`;

export const RewardsEarned = styled.p`
  color: #31374d;
  color: ${(props) => props.theme.mode === 'dark' && '#777d92'};
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 8px;
  }
`;
export const DepositBtn = styled.button`
  margin: 8px;
  padding: 0 35px;
  font-size: 14px;
  background: ${(props) => props.background};
  border: none;
  border-radius: 10px;
  color: #ffffff;
  &.disable {
    background: #b8c1da;
    background: ${(props) => props.theme.mode === 'dark' && '#31374d'};

    color: #777d92;
    color: ${(props) => props.theme.mode === 'light' && '#E7EAF3'};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 5px 2px;
    padding: 0 15px;
    font-size: 10px;
  }
`;
export const ConnectWalletbtn = styled.button`
  margin: 8px;
  padding: 0 35px;
  font-size: 14px;
  background: linear-gradient(118.21deg, #ec4781 22.9%, #6038f7 130.15%);
  border: none;
  border-radius: 10px;
  color: #ffffff;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 5px 2px;
    padding: 0 15px;
    font-size: 10px;
  }
`;

// Skeleton

export const NativeSkeleton = styled.div`
  /* margin: 0 auto; */
  width: 100%;

  .ant-skeleton-input {
    margin: 0.5rem 0;
    height: 6rem;
    border-radius: 6px;
  }
`;
