import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Skeleton } from 'antd';
import styled from 'styled-components';
import Countdown from 'react-countdown';
import moment from 'moment';
import CustomCountdown from '../CustomCountdown/CustomCountdown';

const Text = styled.p`
  margin: 0;
  padding: 4px 0px;

  border-radius: 4px;
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['300']
      : props.theme.colors.black['200']};
`;

const TimerContainer = styled.div`
  justify-self: flex-start;
`;
const Text2 = styled.p`
  text-align: center;
  margin: 0;
  padding: 4px 6px;

  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['300']
      : props.theme.colors.grey['200']};
  border: ${(props) =>
    props.theme.mode === 'dark'
      ? `1px solid ${props.theme.colors.black['200']}`
      : `1px solid ${props.theme.colors.grey['300']}`};

  border-radius: 4px;
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['200']
      : props.theme.colors.black['400']};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 10px;
  }
`;

function ProjectTimerHorizontal({ data, percentage }) {
  const { timeStamp, loading } = useSelector((state) => state.time);
  const [projectTimerStatus, setprojectTimerStatus] = useState({
    showTimer: false,
    text: '',
    timer: '',
    heading: '',
  });

  const calculateProjectTimerStatus = () => {
    const { idoDates } = data;
    const timestampFromServer = new Date(timeStamp).toISOString();
    if (data.isTBA) {
      setprojectTimerStatus({
        showTimer: false,
        text: 'TBA',
        timer: '',
        heading: 'Sale start date',
      });

      return;
    }

    if (data.isIDOLauched === false) {
      const date = moment
        .utc(data.idoDates.roundOneStartDate)
        .format('Do MMM YYYY | h:mm a UTC');
      setprojectTimerStatus({
        showTimer: false,
        text: date,
        timer: '',
        heading: 'Sale start date',
      });
    }
    if (data?.isIDOLauched === true) {
      if (timestampFromServer < idoDates.whitelistStartDate) {
        setprojectTimerStatus({
          showTimer: true,
          heading: 'Whitelist Starts In',
          timer: idoDates.whitelistStartDate,
        });
      } else if (
        timestampFromServer > idoDates.whitelistStartDate &&
        timestampFromServer < idoDates.whitelistEndDate
      ) {
        const formattedDate = moment(data.idoDates.roundOneStartDate).format(
          'Do MMM YYYY | h:mm a UTC'
        );
        setprojectTimerStatus({
          showTimer: false,
          // heading: 'Whitelist Ends In',
          // timer: idoDates.whitelistEndDate,
          heading: `Sale starts date `,
          text: `${formattedDate}`,
          timer: idoDates.roundOneStartDate,
        });
      } else if (
        timestampFromServer > idoDates.whitelistEndDate &&
        timestampFromServer < idoDates.roundOneStartDate
      ) {
        setprojectTimerStatus({
          showTimer: true,
          heading: 'Sale Starts In',
          timer: idoDates.roundOneStartDate,
        });
      } else {
        setprojectTimerStatus({
          showTimer: true,
          heading: 'Sale Ends In',
          timer: idoDates.roundTwoEndDate,
        });
      }
    }
  };

  useEffect(() => {
    if (!loading) calculateProjectTimerStatus();
  }, [loading, timeStamp]);

  return loading ? (
    <Skeleton.Input active />
  ) : (
    <TimerContainer>
      <Text>{projectTimerStatus.heading}</Text>
      {projectTimerStatus.showTimer ? (
        percentage !== undefined ? (
          percentage < 100 ? (
            <Countdown
              date={projectTimerStatus.timer}
              renderer={CustomCountdown}
            />
          ) : (
            <Countdown date={new Date()} renderer={CustomCountdown} />
          )
        ) : (
          <Countdown
            date={projectTimerStatus.timer}
            renderer={CustomCountdown}
          />
        )
      ) : (
        // <>
        <Text2>{projectTimerStatus.text}</Text2>
        // </>
      )}
    </TimerContainer>
  );
}

export default ProjectTimerHorizontal;
