/* eslint-disable */
export const currentEnv = (env_var) => {
  // console.log("env_var ", env_var);
  if (env_var.toUpperCase().trim() === 'LOCAL') {
    return 56;
  } else if (env_var.toUpperCase().trim() === 'DEV') {
    return 97;
  } else if (env_var.toUpperCase().trim() === 'QA') {
    return 56;
  } else if (env_var.toUpperCase().trim() === 'PROD') {
    return 56;
  }
};
