import { Skeleton } from 'antd';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  width: 100%;
  margin: 59px auto 20px;
  max-width: 31rem;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['300']
      : props.theme.colors.grey['100']};
  padding: 30px 50px;
  border-radius: 12px;
  border: 1px solid
    ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black['200']
        : props.theme.colors.grey['300']};
  box-sizing: border-box;
`;

// const Container2 = styled.div`
// height: 120px;
//   width: 90%;
//   border-radius: 10px;
//   margin: 5% auto;

// `;

const Image = styled.div`
  .ant-skeleton-input {
    height: 50px;
    border-radius: 10px;
  }
`;

// const Content = styled.div`
//   flex: 2;

//   .ant-skeleton-input {
//     height: 345px;
//     border-radius: 10px;
//   }
// `;

function EndedPoolListSkeleton() {
  return (
    <>
      <Container>
        <Image>
          <Skeleton.Input active block />
        </Image>
        <Image>
          <Skeleton.Input active block />
        </Image>
      </Container>
      {/* <Container2>
      <Image>
        <Skeleton.Input active block />
      </Image>
    </Container2> */}
    </>
  );
}

export default EndedPoolListSkeleton;
