import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';
import Countdown from 'react-countdown';
import CustomCountdown from '../CustomCountdown/CustomCountdown';

const RowText = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: 400;
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['200']
      : props.theme.colors.black['400']};
`;

const Text = styled.p`
  margin: 0;
  padding: 4px 6px;

  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['300']
      : props.theme.colors.grey['200']};
  border: ${(props) =>
    props.theme.mode === 'dark'
      ? `1px solid ${props.theme.colors.black['200']}`
      : `1px solid ${props.theme.colors.grey['300']}`};

  border-radius: 4px;
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['200']
      : props.theme.colors.black['400']};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 10px;
  }
`;

function ProjectTimer({ type, data, percentage }) {
  const { timeStamp } = useSelector((state) => state.time);
  const [whitelistState, setWhitelistState] = useState({
    showTimer: false,
    text: '',
    timer: '',
  });
  const calculateTimeStamp = () => {
    if (data.isTBA) {
      setWhitelistState({
        showTimer: false,
        text: 'TBA',
        timer: '',
      });

      return;
    }

    if (data.isIDOLauched === false) {
      const date = moment
        .utc(data.idoDates.roundOneStartDate)
        .format('Do MMM YYYY | h:mm a UTC');
      setWhitelistState({
        showTimer: false,
        text: date,
        timer: '',
      });

      return;
    }
    if (data.isIDOLauched === true) {
      setWhitelistState({
        showTimer: true,
        text: '',
        timer: data.idoDates.roundOneStartDate,
      });

      return;
    }

    const timestampFromServer = new Date(timeStamp).toISOString();

    if (timestampFromServer < data.idoDates.whitelistStartDate) {
      setWhitelistState({
        showTimer: true,
        text: '',
        timer: data.idoDates.whitelistStartDate,
      });
    }
  };

  useEffect(() => {
    if (type === 'upcoming') {
      calculateTimeStamp();
    }
  }, []);

  if (type === 'active') {
    return percentage < 100 ? (
      <Countdown
        date={data.idoDates.roundTwoEndDate}
        renderer={CustomCountdown}
      />
    ) : (
      <Countdown date={new Date()} renderer={CustomCountdown} />
    );
  }

  if (type === 'upcoming') {
    return whitelistState.showTimer ? (
      <Countdown date={whitelistState.timer} renderer={CustomCountdown} />
    ) : (
      <Text>{whitelistState.text}</Text>
    );
  }

  return <RowText>Sale ends in</RowText>;
}

export default ProjectTimer;
