import React, { useState } from 'react';
import {
  Container,
  CustomCollapse,
  CustomLink,
  CustomPanel,
  Description,
  Heading,
} from './Faq.style';
import ExpandIcon from '../assets/Expand.svg';
import CollapseIcon from '../assets/Collapse.svg';

function Faq() {
  const [currentActive, setCurrentActive] = useState('1');
  const handleChange = (k) => {
    setCurrentActive(k[k.length - 1]);
  };
  return (
    <Container>
      <Heading>Frequestly Asked Question</Heading>
      <Description>
        Edit Phasellus scelerisque ullamcorper. Morbi magna orci, ornare vel
        auctor non, malesuada{' '}
      </Description>

      <CustomCollapse
        activeKey={currentActive}
        defaultActiveKey={currentActive}
        expandIconPosition="right"
        onChange={handleChange}
        // eslint-disable-next-line react/no-unstable-nested-components
        expandIcon={(props) => {
          if (props.isActive) {
            return <img src={CollapseIcon} alt=" " />;
          }

          return <img src={ExpandIcon} alt=" " />;
        }}
      >
        <CustomPanel
          currentActive={currentActive}
          header="What is the MetaLaunch Launchpad’s Tier Model?"
          key="1"
        >
          MetaLaunch’s tier system makes it easy for investors to join the
          ecosystem. Holders who wish to participate will just need to hold
          their ASVA tokens to participate in any IDO hosted on the launchpad.
          The users will be classified into tiers based on the number of ASVA
          tokens they hold, which will guarantee allocations among the
          participants.
          <CustomLink
            href="https://medium.com/@asvalabs/introduction-to-the-metalaunch-tier-system-4bb7bbea46ff"
            target="_blank"
            rel="noopener noreferrer"
          >
            Read More
          </CustomLink>
        </CustomPanel>
        <CustomPanel
          currentActive={currentActive}
          header="Explain MetaLaunch’s Project’s Selection process"
          key="2"
        >
          The MetaLaunch project application process is administered by the
          MetaLaunch team and the MetaLaunch Council members. MetaLaunch council
          is an independent group of industry leaders and VC firms that add a
          layer of decentralization, fairness, transparency, and legality to the
          selection process.
          <CustomLink
            href="https://medium.com/@asvalabs/metalaunchs-project-s-due-diligence-process-cde9327520c4"
            target="_blank"
            rel="noopener noreferrer"
          >
            Read More
          </CustomLink>
        </CustomPanel>
        <CustomPanel
          currentActive={currentActive}
          header="What is the “MetaLaunch Incubation program”?"
          key="3"
        >
          The MetaLaunch Incubation Program is a brand new initiative developed
          with the primary aim to assist and nurture promising metaverse and
          gaming projects in their early development phases, utilizing our wide
          ecosystem and industry networks.
          <CustomLink
            href="https://medium.com/@asvalabs/introducing-the-metalaunch-incubation-program-3b92e6b3520d"
            target="_blank"
            rel="noopener noreferrer"
          >
            Read More
          </CustomLink>
        </CustomPanel>
        <CustomPanel
          currentActive={currentActive}
          header="How to buy $ASVA on Pancakeswap?"
          key="4"
        >
          We are very excited to announce you can now buy $ASVA token on
          PancakeSwap through straightforward steps. In this article, we will be
          showing you how to buy $ASVA on Pancakeswap.
          <CustomLink
            href="https://medium.com/@asvalabs/how-to-buy-asva-on-pancakeswap-bc5bdf3719be"
            target="_blank"
            rel="noopener noreferrer"
          >
            Read More
          </CustomLink>
        </CustomPanel>
        <CustomPanel
          currentActive={currentActive}
          header="How to stake $ASVA on the MetaLaunch platform?"
          key="5"
        >
          By staking ASVA tokens, you will be eligible to participate in
          promising IGOs and IMOs launching on MetaLaunch and earn the best APR.
          In this article, we will explain how to stake your $ASVA tokens on the
          MetaLaunch platform and enjoy higher $ASVA rewards!
          <CustomLink
            href="https://asvalabs.medium.com/asva-staking-on-metalaunch-goes-live-2cad20fc91a8"
            target="_blank"
            rel="noopener noreferrer"
          >
            Read More
          </CustomLink>
        </CustomPanel>

        {/* Added To hide border style for last item */}
        <CustomPanel
          currentActive={currentActive}
          header="Test"
          key="999"
          style={{ display: 'none' }}
        >
          Phasellus scelerisque ullamcorper. Morbi magna orci, ornare vel auctor
          non, malesuada sed dolor. PellentesquePhasellus scelerisque eros
          felis, ut lobortis ipsum mattis ullamcorper. Morbi magna orci, ornare
          vel auctor non Phasellus scelerisque ullamcorper. Morbi magna orci,
          ornare vel auctor non, malesuada sed dolor. PellentesquePhasellus
        </CustomPanel>
      </CustomCollapse>
    </Container>
  );
}

export default Faq;
