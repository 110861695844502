import { useState, useEffect } from 'react';
import { getChainId } from '../Web3/web3';

const useNetworkChange = () => {
  const [networkId, setNetworkId] = useState(undefined);
  useEffect(() => {
    if (window.ethereum !== undefined)
      window.ethereum.on('networkChanged', async (networkId1) => {
        // console.log("networkChanged", networkId1);
        if (parseFloat(networkId1) === 1) {
          const chainId = await getChainId();
          setNetworkId(parseFloat(chainId));
        } else setNetworkId(parseFloat(networkId1));
      });

    // const getNetwork = async () => {
    //   const data = await fetchAccountDetails();
    //   if (parseFloat(data.connection.networkId) === 1) {
    //     const chainId = await getChainId();
    //     setNetworkId(parseFloat(chainId));
    //     return;
    //   }
    //   setNetworkId(parseFloat(data.connection.networkId));
    // };

    // getNetwork();
  }, []);
  return networkId;
};

export default useNetworkChange;
