/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
/* eslint-disable camelcase */
export const getBackendUrl = (env_var) => {
  // console.log("env_var ", env_var);
  if (env_var.toUpperCase().trim() === 'LOCAL') {
    return 'http://api-test.metalaunch.io/';
  } else if (env_var.toUpperCase().trim() === 'DEV') {
    return 'http://api-test.metalaunch.io/';
  } else if (env_var.toUpperCase().trim() === 'QA') {
    return 'http://qa-admin-api.metalaunch.io/';
  } else if (env_var.toUpperCase().trim() === 'PROD') {
    return 'https://api.metalaunch.io/';
  }
};

export const completedProjects = [
  1642916694000, 1642917951796, 1660133565291, 1660133245281,
];
