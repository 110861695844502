import React, { useState } from 'react';
import { useTheme } from 'styled-components';
import axios from 'axios';
import {
  FooterDiv,
  FooterInputDivColumn,
  FooterDivTextContainer,
  FooterP1,
  FooterP3,
  FooterDivContainer,
  FooterDivInsider,
  FooterMainDiv,
  FooterInput,
  FooterInputBtn,
  FooterInputBtnspan,
  FooterDivInput,
  FooterWrapperDiv,
  FooterImageContainer,
  FooterAsvaText,
  FooterP4,
  // FooterImgDiv,
  // FooterImgDivIcon,
  FooterDivBottom,
  FooterDivFull,
  FooterArrowImage,
  FooterHiddenForSmallScreen,
  FooterDivMedium,
  FooterDivMediumDiv,
  FooterDivMediumDivText,
  FooterAnchor,
  Message,
} from './Footer.styles';
import AsvaLabsLight from './assets/AsvaLabsLight.svg';
import AsvaLabsDark from './assets/AsvaLabsDark.svg';
// import Discord from './assets/Discord.png';
// import Medium from './assets/Medium.png';
// import Telegram from './assets/Telegram.png';
// import Twitter from './assets/Twitter.png';
import Arrow from './assets/Arrow.svg';

function Footer() {
  const theme = useTheme();
  const [currentTab, setCurrentTab] = useState('');
  const [inputVal, setInputVal] = useState('');
  const [messageData, setMessageData] = useState();

  const handleSubscribe = () => {
    const email = inputVal;
    setInputVal('');
    //  var message = elem.querySelector("p");
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email === '' || !regex.test(String(email).toLowerCase())) {
      setMessageData('Please enter a valid email');
    }
    const formData = new FormData();
    formData.append('api_key', 'AghazFUFk4uDrDUsquEg');
    formData.append('email', email);
    formData.append('list', 'Ya5aiMLvPKaMZLzXN7vbmQ');
    axios
      .post('https://sendy.asvalabs.com/subscribe', formData, {
        headers: { 'Content-type': 'application/x-www-form-urlencoded' },
      })
      .then((response) => {
        const resData = response.data;
        if (resData.includes(`You're already subscribed!`)) {
          setMessageData(`You're already subscribed!`);
        }
        if (resData.includes(`You're subscribed!`)) {
          setMessageData(`You're subscribed!`);
        }
      })
      .catch(() => {
        setMessageData(`Something went wrong!`);
      });
  };
  return (
    <FooterDivFull>
      <FooterMainDiv>
        <FooterDiv>
          <FooterInputDivColumn>
            <FooterP1>Never miss a Launch!</FooterP1>
            <FooterP1>
              Subscribe to stay in the loop with our newest Token sale launches
              and
            </FooterP1>
            <FooterP1> ASVA ecosystem updates.</FooterP1>

            <FooterDivInput>
              <FooterInput
                type="text"
                placeholder="Enter your email address"
                value={inputVal}
                onChange={(e) => setInputVal(e.target.value)}
              />
              <FooterInputBtn>
                <FooterInputBtnspan onClick={() => handleSubscribe()}>
                  Subscribe
                  <FooterArrowImage src={Arrow} alt="Arrow" />
                </FooterInputBtnspan>
              </FooterInputBtn>
            </FooterDivInput>
            <Message>{messageData}</Message>
          </FooterInputDivColumn>
          <FooterDivTextContainer>
            <FooterDivContainer>
              <FooterP3>Metalaunch</FooterP3>
              <FooterDivInsider>
                <FooterAnchor
                  target="_blank"
                  href=""
                  rel="noreferrer"
                  onClick={() => setCurrentTab('IDO Launchpad')}
                  active={currentTab === 'IDO Launchpad'}
                >
                  MetaLaunch
                </FooterAnchor>
              </FooterDivInsider>
              <FooterDivInsider>
                <FooterAnchor
                  target="_blank"
                  href=""
                  rel="noreferrer"
                  onClick={() => setCurrentTab('INO Launchpad')}
                  active={currentTab === 'INO Launchpad'}
                >
                  INO Launchpad
                </FooterAnchor>
              </FooterDivInsider>
              <FooterDivInsider>
                <FooterAnchor
                  target="_blank"
                  href="https://stake.metalaunch.io/"
                  rel="noreferrer"
                  onClick={() => setCurrentTab('Stake/Farm')}
                  active={currentTab === 'Stake/Farm'}
                >
                  Stake/Farm
                </FooterAnchor>
              </FooterDivInsider>
            </FooterDivContainer>
            <FooterDivContainer>
              <FooterP3>Resources</FooterP3>
              <FooterDivInsider>
                <FooterAnchor
                  target="_blank"
                  href="https://metalaunch.io/assets/files/terms-of-use.pdf"
                  rel="noreferrer"
                  onClick={() => setCurrentTab('Tearms of service')}
                  active={currentTab === 'Tearms of service'}
                >
                  Terms of Service
                </FooterAnchor>
              </FooterDivInsider>
              <FooterDivInsider>
                <FooterAnchor
                  target="_blank"
                  href="https://metalaunch.io/assets/files/privacy-policy.pdf"
                  rel="noreferrer"
                  onClick={() => setCurrentTab('Privacy policy')}
                  active={currentTab === 'Privacy policy'}
                >
                  Privacy Policy
                </FooterAnchor>
              </FooterDivInsider>
            </FooterDivContainer>
            <FooterHiddenForSmallScreen>
              <FooterDivContainer>
                <FooterP3>Connect</FooterP3>
                <FooterDivInsider>
                  <FooterAnchor
                    target="_blank"
                    href="https://asvalabs.medium.com/"
                    rel="noreferrer"
                    onClick={() => setCurrentTab('Medium')}
                    active={currentTab === 'Medium'}
                  >
                    Medium
                  </FooterAnchor>
                </FooterDivInsider>
                <FooterDivInsider>
                  <FooterAnchor
                    target="_blank"
                    href="https://twitter.com/Metalaunch_io"
                    rel="noreferrer"
                    onClick={() => setCurrentTab('Twitter')}
                    active={currentTab === 'Twitter'}
                  >
                    Twitter
                  </FooterAnchor>
                </FooterDivInsider>
                <FooterDivInsider>
                  <FooterAnchor
                    target="_blank"
                    href="https://www.linkedin.com/company/71280081/"
                    rel="noreferrer"
                    onClick={() => setCurrentTab('Linkdlin')}
                    active={currentTab === 'Linkdlin'}
                  >
                    LinkedIn
                  </FooterAnchor>
                </FooterDivInsider>
              </FooterDivContainer>
            </FooterHiddenForSmallScreen>
          </FooterDivTextContainer>
        </FooterDiv>
        <FooterDivMedium>
          <FooterDivMediumDiv>
            <FooterDivMediumDivText>
              <FooterAnchor
                target="_blank"
                href="https://asvalabs.medium.com/"
                rel="noreferrer"
                onClick={() => setCurrentTab('Medium')}
                active={currentTab === 'Medium'}
              >
                Medium
              </FooterAnchor>
            </FooterDivMediumDivText>
          </FooterDivMediumDiv>
          <FooterDivMediumDiv>
            <FooterDivMediumDivText>
              <FooterAnchor
                target="_blank"
                href="https://twitter.com/Metalaunch_io"
                rel="noreferrer"
                onClick={() => setCurrentTab('Twitter')}
                active={currentTab === 'Twitter'}
              >
                Twitter
              </FooterAnchor>
            </FooterDivMediumDivText>
          </FooterDivMediumDiv>
          <FooterDivMediumDiv>
            <FooterDivMediumDivText>
              <FooterAnchor
                target="_blank"
                href="https://www.linkedin.com/company/71280081/"
                rel="noreferrer"
                onClick={() => setCurrentTab('Linkdlin')}
                active={currentTab === 'Linkdlin'}
              >
                Linkdlin
              </FooterAnchor>
            </FooterDivMediumDivText>
          </FooterDivMediumDiv>
        </FooterDivMedium>
        <FooterDivBottom>
          <FooterWrapperDiv>
            <FooterImageContainer>
              <img
                src={theme.mode === 'dark' ? AsvaLabsDark : AsvaLabsLight}
                alt="AsvaLabs"
              />
            </FooterImageContainer>
            <FooterAsvaText>By AsvaLabs</FooterAsvaText>
          </FooterWrapperDiv>
          <FooterWrapperDiv>
            <FooterP4>© 2022 Asva labs llc. all rights reserved </FooterP4>
          </FooterWrapperDiv>
          {/* <FooterImgDiv>
            <FooterImgDivIcon>
              <img src={Twitter} alt="Twitter" />
            </FooterImgDivIcon>
            <FooterImgDivIcon>
              <img src={Telegram} alt="Telegram" />
            </FooterImgDivIcon>
            <FooterImgDivIcon>
              <img src={Discord} alt="Discord" />
            </FooterImgDivIcon>
            <FooterImgDivIcon>
              <img src={Medium} alt="Medium" />
            </FooterImgDivIcon>
          </FooterImgDiv> */}
        </FooterDivBottom>
      </FooterMainDiv>
    </FooterDivFull>
  );
}

export default Footer;
