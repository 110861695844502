import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  loading: false,
  isConnected: false,
  data: {},
};

export const socketSLice = createSlice({
  name: 'socket',
  initialState,
  reducers: {
    initializeConnection: (state, action) => ({
      ...state,
      isConnected: action.payload.isConnected,
      data: action.payload.data,
    }),
  },
});

export const { initializeConnection } = socketSLice.actions;
export default socketSLice.reducer;
