// import styled from 'styled-components';
// import { Link } from 'react-router-dom';

// export const CardBody = styled(Link)`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   cursor: auto;
// `;
// export const CardBanner = styled.div`
//   cursor: pointer;
//   width: 1100px;
//   border-radius: 15px;
//   margin: 25px;
//   padding: 28px;
//   background-repeat: no-repeat;
//   background-image: url(${(props) => props.image});
//   background-size: 100%;
//   position: relative;
//   transition: 0.5s;
//   &:hover {
//     transform: scale(1.05);
//   }
//   @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
//     width: 900px;
//   }
// `;
// export const TypeOfProject = styled.div`
//   padding: 5px;
//   font-weight: 500;
//   font-size: 14px;
//   line-height: 145%;
//   color: #12141c;
//   background-color: #fcb778;
//   position: absolute;
//   border-radius: 5px;
//   text-transform: uppercase;
//   top: 10px;
//   right: 10px;
// `;
// export const CardInfo = styled.div`
//   display: flex;
//   background: ${(props) =>
//     props.theme.mode === 'dark'
//       ? props.theme.colors.black['300']
//       : props.theme.colors.grey['100']};
//   margin-top: 30%;
//   border-radius: 10px;
//   justify-content: space-around;
//   align-items: center;
// `;
// export const Logo = styled.img`
//   margin: 25px;
//   width: 50px;
// `;
// export const ProjectName = styled.p`
//   display: flex;
//   margin: 0;
//   font-weight: 600;
//   font-size: 30px;
//   line-height: 100%;
//   color: ${(props) => props.theme.text};
// `;
// export const TokenName = styled.span`
//   font-weight: 500;
//   font-size: 10px;
//   line-height: 160%;
//   color: #777d92;
//   margin: 10px;
// `;
// export const CardInfoWrapper = styled.div`
//   margin: 20px;
//   position: relative;
// `;

// export const CardInfoHeading = styled.p`
//   margin: 0;
//   font-weight: 400;
//   font-size: 12px;
//   line-height: 145%;
//   color: #777d92;
//   margin-bottom: 10px;
// `;
// export const SaleTimer = styled.p`
//   margin: 0;
//   font-weight: 400;
//   font-size: 12px;
//   line-height: 145%;
//   color: #777d92;
// `;
// export const CardInfoHeadingSpan = styled.span`
//   margin: 0 5px;
//   font-weight: 400;
//   font-size: 10px;
//   line-height: 160%;
//   color: #30d89b;
// `;
// export const CardInfoValue = styled.div`
//   color: ${(props) =>
//     props.theme.mode === 'dark'
//       ? props.theme.colors.grey['200']
//       : props.theme.colors.grey['500']};
//   margin: 0;
//   font-weight: 500;
//   font-size: 18px;
//   line-height: 145%;
// `;
// export const SupportedToken = styled.div`
//   width: auto;
//   padding: 3px;
//   border-radius: 10px;
//   background: ${(props) =>
//     props.theme.mode === 'dark'
//       ? props.theme.colors.black['500']
//       : props.theme.colors.grey['200']};
//   display: flex;
//   justify-content: space-around;
//   align-items: center;
// `;
// export const SupportedTokenNamee = styled.div`
//   border-left: ${(props) =>
//     props.theme.mode === 'dark'
//       ? `1px solid ${props.theme.colors.black['200']}`
//       : `1px solid ${props.theme.colors.grey['300']}`};
//   /* 1px solid #616c97; */
//   padding: 0 10px;
//   font-size: 16px;
// `;
// export const NetworkImage = styled.img`
//   margin: -3px 5px;
// `;

import styled from 'styled-components';

export const CardContainer = styled.div`
  padding: 1rem;
  margin: 3rem 1.5rem;
  display: flex;
  column-gap: 1rem;
  align-items: center;
  border-radius: 20px;
  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['300']
      : props.theme.colors.grey['100']};
  transition: 0.5s;
  &:hover {
    transform: scale(1.05);
  }
`;

export const CardImage = styled.img`
  max-width: 465px;
  height: 20rem;
  border-radius: 14px;
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
`;

export const CardTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const Logo = styled.img`
  width: 3rem;
  height: 3rem;
`;

export const ProjectName = styled.p`
  display: inline-flex;
  align-items: center;
  margin: 0 1rem;
  font-weight: 600;
  font-size: 2.2em;
  /* color: ${(props) => props.theme.text}; */
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['200']
      : props.theme.colors.black['400']};
`;

export const TokenName = styled.span`
  font-weight: 500;
  font-size: 0.5em;
  /* color: #777d92; */
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['100']
      : props.theme.colors.grey['400']};
  margin-left: 1rem;
`;

export const SupportedTokens = styled.div`
  font-style: normal;
  font-weight: 500;
  display: flex;
  align-items: center;
  text-align: center;
  color: #777d92;
`;
export const PipeDivider = styled.p`
  margin: 0 0.2em;
  color: #31374d;
`;
export const NetworkInfo = styled.div`
  margin-left: auto;
  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['400']
      : props.theme.colors.grey['200']};
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.2rem;
`;

export const VideoTag = styled.div`
  margin-left: 0.5rem;
  font-weight: 500;
  color: ${(props) => props.theme.colors.black['400']};
  border-radius: 4px;
  padding: 0.2rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => {
    if (props.type === 'Public IDO') {
      return props.theme.colors.yellow[400];
    }
    if (props.type === 'Community IDO') {
      return props.theme.colors.green[400];
    }

    return props.theme.colors.yellow[400];
  }};
`;

export const Text = styled.p`
  margin: 0;
  margin-bottom: ${(props) => props.marginBottom};
  margin-top: ${(props) => props.marginTop};
  font-size: ${(props) => (props.dollarValue ? '1.2em' : '1em')};
  font-weight: ${(props) => (props.dollarValue ? 500 : 400)};
  /* color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['200']
      : props.theme.colors.black['400']}; */
  color: ${(props) => props.color};
  margin-top: 7px;
`;

export const RowTextSpan = styled.span`
  font-size: 0.9em;
  font-style: normal;
  font-weight: 400;
  color: ${(props) => props.color};
  margin-left: 5px;
`;

export const SaleDescription = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
  column-gap: 1rem;
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 8px;
  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['400']
      : props.theme.colors.grey['200']};
`;

export const TotalRaise = styled.div`
  font-weight: 400;
  color: ${(props) => props.theme.colors.black['100']};
`;

export const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TimerContainer = styled.div`
  justify-self: flex-start;
`;
