import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { useTheme } from 'styled-components';

import Tick from '../../Images/Tick.svg';
import failedIcon from '../../Images/failedIcon.svg';
import InReview from '../../Images/InReview.svg';
import MetaMask from '../../Images/MetaMask.svg';
import {
  toggleDisconnectWallet,
  toggleMobileNavbar,
} from '../../../../store/slices/modal';
import { fetchIsKycVerified } from '../../../../store/slices/user';

const AddressButton = styled.button`
  outline: none;
  border: none;
  padding: 15px 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['300']
      : props.theme.colors.grey['200']};

  border-radius: 6px;
  /* max-width: 152px; */
`;

const AddressValue = styled.p`
  margin: 0;
  padding: 0;

  font-weight: 500;
  font-size: 14px;
  line-height: 145%;

  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['300']
      : props.theme.colors.black['300']};
`;

const WalletLogo = styled.img``;

export const VerifiedBackground = styled.span`
  display: flex;
  gap: 4px;
  padding: 2px 4px;
  background: ${(props) =>
    props.isKycVerified === 'approved'
      ? 'rgba(59, 238, 173, 0.2)'
      : props.isKycVerified === 'rejected'
      ? 'rgba(251, 89, 89, 0.2)'
      : 'rgba(252, 183, 120, 0.2)'};
  border-radius: 10px;

  margin-left: ${(props) => props.marginLeft};
`;

export const VerifiedText = styled.p`
  margin: 0;
  padding: 0;
  color: #30d89b;
  font-weight: 400;
  font-size: 10px;
  line-height: 160%;
`;

function ChangeAddressButton() {
  const { displayAddress, address, isKycVerified } = useSelector(
    (state) => state.user
  );
  const { isMobileNavbarModalModalVisible } = useSelector(
    (state) => state.modal
  );

  const theme = useTheme();

  const dispatch = useDispatch();

  const openDisconnectWalletModal = () => {
    if (isMobileNavbarModalModalVisible) dispatch(toggleMobileNavbar());

    dispatch(toggleDisconnectWallet());
  };

  useEffect(() => {
    dispatch(fetchIsKycVerified(address));
  }, [address]);

  return (
    <AddressButton>
      <WalletLogo
        onClick={openDisconnectWalletModal}
        src={MetaMask}
        alt="MetaMask"
      />
      <AddressValue
        onClick={openDisconnectWalletModal}
        color={
          theme.mode === 'dark'
            ? theme.colors.grey['300']
            : theme.colors.black['300']
        }
      >
        {displayAddress}
      </AddressValue>

      {isKycVerified === 'approved' && (
        <VerifiedBackground isKycVerified={isKycVerified}>
          <img src={Tick} alt="Verified" />
          <VerifiedText>KYC Verified</VerifiedText>
        </VerifiedBackground>
      )}
      {isKycVerified === 'rejected' && (
        <VerifiedBackground isKycVerified={isKycVerified}>
          <img src={failedIcon} alt="InReview" />
          <VerifiedText
            onClick={() =>
              window.open(
                'https://verify-with.blockpass.org/?clientId=asva_ido_on_metalaunch_cce82&serviceName=Asva%20IDO%20on%20MetaLaunch&env=prod'
              )
            }
          >
            KYC Failed
          </VerifiedText>
        </VerifiedBackground>
      )}
      {isKycVerified !== 'approved' &&
        isKycVerified !== 'rejected' &&
        isKycVerified !== 'verify' && (
          <VerifiedBackground isKycVerified={isKycVerified}>
            <img src={InReview} alt="Waiting" />
            <VerifiedText>InReview</VerifiedText>
          </VerifiedBackground>
        )}

      {/* {isKycVerified === 'reviewing' && (
        <VerifiedBackground isKycVerified={isKycVerified}>
          <img src={Tick} alt="InReview" />
          <VerifiedText>InReview</VerifiedText>
        </VerifiedBackground>
      )} */}
    </AddressButton>
  );
}

export default ChangeAddressButton;
