import styled from 'styled-components';

export const PoolType = styled.div`
  display: flex;
  justify-content: center;
  width: auto;
  font-weight: 500;
  font-size: 30px;
  line-height: 100%;
  letter-spacing: -0.5px;
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['200']
      : props.theme.colors.black['400']};
  margin: 35px;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    font-size: 2em;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 22px;
    margin: 25px 0 25px 0;
  }
`;

export const ActivePoolContainer = styled.div``;

export const PoolStatus = styled.div`
  background: rgba(251, 89, 89, 0.2);
  color: #fb5959;
  opacity: 0.8;
  font-weight: 500;
  font-size: 10px;
  padding: 6px 6px;
  margin: 0 20px;
  border-radius: 6px;
  letter-spacing: 3px;
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 10px;
    padding: 3px 3px;
    margin: 0 12px;
  }
`;

// vertical Card Css

export const VerticalPoolCardWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
  /* For Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    flex-direction: column;
  }
`;
