import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { Skeleton } from 'antd';
import {
  OverallStakingContainer,
  OverallStakingContainerTop,
  OverallStakingContainerLeft,
  OverallStakingHeading,
  OverallStakingValue,
  OverallStakingInnerContainerRight,
  OverallStakingContainerImage,
  OverallStakingContainerBottom,
  OverallStakingHeadingTop,
  OverallStakingHeadingBottom,
  OverallStakingBottomPara,
  OverallStakingButtonLeft,
  OverallStakingButtons,
  OverallStakingButtonRight,
  LeftTopSkeleton,
} from './OverallStaking.style';
import StakeAsva from '../assets/StakeAsva.png';
import PancakeSwap2 from '../assets/PancakeSwap2.svg';
import pancakelight from '../assets/pancakelight.svg';
import maxlight from '../assets/maxlight.svg';
import Mexc from '../assets/Mexc.svg';
import pools from '../../../config/stakePool';
import { totalAsvaStake } from '../../../Web3/web3';
import { currentEnv } from '../StakeEnv';

function OverallStaking({ active, initLoading }) {
  const theme = useTheme();
  const [totalStake2, setTotalStake2] = useState(0);
  const [totalLpStake, setTotalLpStake] = useState(0);
  const [loading, setLoading] = useState(false);
  const isConnected = useSelector((state) => state.user);

  useMemo(async () => {
    try {
      // if (isConnected.isConnected) {
      setLoading(true);
      const address = await currentEnv(process.env.REACT_APP_ENV);
      const total0 = await totalAsvaStake(
        pools.pools[0].contractAddress[address]
      );
      const total1 = await totalAsvaStake(
        pools.pools[1].contractAddress[address]
      );
      const total2 = await totalAsvaStake(
        pools.pools[2].contractAddress[address]
      );
      const total3 = await totalAsvaStake(
        pools.pools[3].contractAddress[address]
      );
      const total4 = await totalAsvaStake(
        pools.pools[4].contractAddress[address]
      );
      const total5 = await totalAsvaStake(
        pools.pools[5].contractAddress[address]
      );
      const total6 = await totalAsvaStake(
        pools.pools[6].contractAddress[address]
      );
      const total7 = await totalAsvaStake(
        pools.pools[7].contractAddress[address]
      );
      const total8 = await totalAsvaStake(
        pools.pools[8].contractAddress[address]
      );
      const lp = await totalAsvaStake(pools.lp[0].contractAddress[address]);

      setTotalStake2(
        parseFloat(total0) +
          parseFloat(total1) +
          parseFloat(total2) +
          parseFloat(total3) +
          parseFloat(total4) +
          parseFloat(total5) +
          parseFloat(total6) +
          parseFloat(total7) +
          parseFloat(total8)
      );
      setTotalLpStake(lp);
      setLoading(false);
      // }
    } catch (error) {
      setLoading(false);
    }
  }, [isConnected]);
  return (
    <OverallStakingContainer>
      {loading || initLoading ? (
        <LeftTopSkeleton>
          <Skeleton.Input active block />
        </LeftTopSkeleton>
      ) : (
        <OverallStakingContainerTop>
          <OverallStakingContainerLeft>
            <OverallStakingHeading>
              {active === 2 ? `Total LP Staked` : `Total ASVA Staked`}
            </OverallStakingHeading>
            <OverallStakingValue>
              {active === 2
                ? `${parseFloat(totalLpStake).toLocaleString('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })} LP`
                : `${parseFloat(totalStake2).toLocaleString('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })} ASVA`}
            </OverallStakingValue>
          </OverallStakingContainerLeft>
          <OverallStakingInnerContainerRight>
            <OverallStakingContainerImage src={StakeAsva} />
          </OverallStakingInnerContainerRight>
        </OverallStakingContainerTop>
      )}
      <OverallStakingContainerBottom>
        <OverallStakingHeadingTop>
          Stake or Farm Your ASVA to Join IDO on Launchpad
        </OverallStakingHeadingTop>
        <OverallStakingBottomPara>
          Get exclusive access to top-tier launches and earn high APR
        </OverallStakingBottomPara>
        <OverallStakingHeadingBottom>
          Supported Exchanges To Buy ASVA
        </OverallStakingHeadingBottom>
        <OverallStakingButtons>
          <a
            href="https://pancakeswap.finance/swap?inputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56&outputCurrency=0xf7b6d7e3434cb9441982f9534e6998c43eef144a"
            target="_blank"
            rel="noreferrer"
          >
            <OverallStakingButtonLeft
              src={theme.mode === 'dark' ? PancakeSwap2 : pancakelight}
            />
          </a>
          <a
            href="https://www.mexc.com/exchange/ASVA_USDT"
            target="_blank"
            rel="noreferrer"
          >
            <OverallStakingButtonRight
              src={theme.mode === 'dark' ? Mexc : maxlight}
            />
          </a>
        </OverallStakingButtons>
      </OverallStakingContainerBottom>
    </OverallStakingContainer>
  );
}

export default OverallStaking;
