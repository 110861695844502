import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { useWindowSize } from '../../hooks';
import {
  fetchActivePoolList,
  fetchUpcomingPoolList,
  fetchEndedPoolList,
  fetchEndedPoolStats,
  // fetchFeaturedPoolList,
} from '../../store/slices/pool';
import { fetchServerTimestamp } from '../../store/slices/time';
import Active from './AllPools/Active';
import Ended from './AllPools/Ended';
import Upcomming from './AllPools/Upcomming';
import CalenderImage from './assets/calender.svg';

import {
  Pools,
  PoolHeading,
  PoolCount,
  PoolLeft,
  PoolRight,
  PoolButton,
  PoolLi,
  CalenderBtn,
  CalenderImg,
  PoolsWrapper,
} from './Pool.style';
import FeaturedPoolsCarousel from '../FeaturedPoolsCarousel/FeaturedPoolsCarousel';

function Pool() {
  const [currentTab, setCurrentTab] = useState('All');
  const dispatch = useDispatch();
  const { activePools, upcomingPools, endedPools } = useSelector(
    (state) => state.pools
  );

  const theme = useTheme();
  const { width } = useWindowSize();

  const fetchPools = () => {
    // dispatch(fetchFeaturedPoolList());
    dispatch(fetchActivePoolList());
    dispatch(fetchUpcomingPoolList());
    dispatch(fetchEndedPoolList({ number: 1, search: '', default: true }));
    dispatch(fetchServerTimestamp());
    dispatch(fetchEndedPoolStats());
  };

  useEffect(() => {
    fetchPools();
  }, []);

  return (
    <>
      <FeaturedPoolsCarousel />
      <Pools>
        <PoolLeft>
          <PoolHeading>List of IDO Projects</PoolHeading>
          <PoolCount>
            Count:
            {activePools.length + upcomingPools.length + endedPools.length}
          </PoolCount>
        </PoolLeft>
        <PoolRight>
          <PoolButton>
            <PoolLi
              onClick={() => setCurrentTab('All')}
              active={currentTab === 'All'}
            >
              All Pools
            </PoolLi>
            <PoolLi
              onClick={() => setCurrentTab('Active')}
              active={currentTab === 'Active'}
            >
              Active
            </PoolLi>
            <PoolLi
              onClick={() => setCurrentTab('Upcomming')}
              active={currentTab === 'Upcomming'}
            >
              Upcoming
            </PoolLi>
            <PoolLi
              onClick={() => setCurrentTab('Ended')}
              active={currentTab === 'Ended'}
            >
              Ended
            </PoolLi>
          </PoolButton>
          <CalenderBtn>
            <CalenderImg src={CalenderImage} />
            {width <= parseInt(theme.breakpoints.desktop, 10)
              ? ''
              : 'Calender View'}
          </CalenderBtn>
        </PoolRight>
      </Pools>
      {currentTab === 'All' && (
        <>
          <PoolsWrapper>
            <Active />
            <Upcomming />
          </PoolsWrapper>
          <Ended />
        </>
      )}
      {currentTab === 'Active' && (
        <PoolsWrapper>
          <Active />
        </PoolsWrapper>
      )}
      {currentTab === 'Upcomming' && (
        <PoolsWrapper>
          <Upcomming />
        </PoolsWrapper>
      )}
      {currentTab === 'Ended' && <Ended />}
    </>
  );
}

export default Pool;
