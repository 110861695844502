import { DownOutlined, UpOutlined } from '@ant-design/icons/lib/icons';
import { Carousel } from 'antd';
import styled from 'styled-components';

export const FlexContainer = styled.div`
  display: flex;
  /* justify-content: space-between; */
  margin: 2rem 5rem;
  column-gap: 1rem;

  /* For Tablet */
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    margin: 2rem;
  }
  /* For Tablet */
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: 1rem;
    flex-direction: column;
  }
`;

export const VidoeContainer = styled.div`
  position: relative;
  margin: auto 0;
  /* max-width: 75%; */
  width: ${(props) => props.width};

  /* For Tablet */
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    /* max-width: 100%; */
    width: 100%;
  }
`;

export const AbsoluteControls = styled.div`
  position: absolute;
  top: 10px;
  width: 100%;
`;
export const AbsoluteVideoInfo = styled.div`
  position: absolute;
  bottom: 50px;
  width: 100%;

  /* For Tablet */
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    bottom: 20px;
  }
`;
export const AbsoluteVideoInfoHorizontal = styled.div`
  position: absolute;
  /* bottom: 50px; */
  top: 85%;
  width: 100%;

  /* For Tablet */
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    top: 80%;
  }
  /* For Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    top: 70%;
  }
`;

export const ControlsRow = styled.div`
  display: flex;
  align-items: center;
`;

export const SoundImg = styled.img`
  /* background: rgba(0, 0, 0, 0.48); */
  /* border-radius: 40px; */
  width: 42px;
  height: 42px;
  align-self: center;
  /* margin: auto 1rem; */
  margin-right: 1rem;
  margin-left: auto;
  padding: 5px;
  &:hover {
    cursor: pointer;
  }

  /* For Tablet */
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin-right: 0.5rem;
  }
  /* For Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-right: 0.4rem;
    width: 35px;
    height: 35px;
  }
`;

export const VideoTag = styled.div`
  font-weight: 500;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.theme.colors.black['400']};
  border-radius: 24px;
  padding: 0.5rem 1rem;
  margin: auto 1rem;
  margin-left: ${(props) => props.marginLeft};
  margin-right: ${(props) => props.marginRight};
  display: inline-flex;
  justify-content: center;
  align-items: center;

  /* For Tablet */
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: 1.5em;
  }
  /* For Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    /* padding: 0.2rem 1rem; */
    height: 2rem;
    margin: auto 0.5rem;
  }
`;

export const VideoTitleRow = styled.div`
  position: relative;
  /* grid-template-columns: minmax(50px, 80px) 2fr 1fr; */
  /* display: grid;
  grid-template-columns: 0.5fr 2fr auto 1fr;
  align-items: center; */

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ProjectInfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 3rem;

  /* For Tablet */
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    margin-left: 1rem;
  }
`;

export const SaleTimingContainer = styled.div`
  display: flex;
`;

// export const LogoWrapper = styled.div`
//   position: absolute;
//   border-radius: 50%;
//   padding: 10px;
//   background: ${(props) => props.theme.colors.black['300']};
//   bottom: 50px;
//   left: 50px;
// `;

export const ProjectLogo = styled.img`
  width: 3rem;
  height: 3rem;
  background: ${(props) => props.theme.colors.black['300']};
  border-radius: 50%;
  padding: 10px;
  /* justify-self: end; */
  margin-right: 1rem;
  /* grid-column: 1/2; */

  /* For Tablet */
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin-right: 0.5rem;
    padding: 8px;
    width: 2.5rem;
    height: 2.5rem;
  }
`;

export const ProjectName = styled.h2`
  margin: 0;
  font-size: 2em;
  color: ${(props) => props.theme.colors.grey['200']};
  font-weight: 600;
  /* grid-column: 2/3; */
`;

export const VideoText = styled.p`
  margin: 0;
  font-weight: ${(props) => (props.weight ? props.weight : '400')};
`;

export const DateTime = styled.p`
  margin: 0;
  margin-right: 3rem;
  padding: 0.5rem;
  width: fit-content;
  border: 1px solid #31374d;
  box-sizing: border-box;
  border-radius: 4px;
  text-align: center;
  background: ${(props) => props.theme.colors.black['300']};
  color: ${(props) => props.theme.colors.grey['200']};

  /* For Tablet */
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    margin-right: 1rem;
    font-size: 1.5em;
  }
  /* For Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    font-size: 1.2em;
  }
`;

// export const VideoContainer = styled.div`
//   background-color: #000;
//   min-width: 100%;
//   min-height: 30%;
// `;

export const VideoHorizontal = styled.video`
  border-radius: 20px;
  object-fit: fill;
  height: 31rem;
  max-height: 470px;
  background: linear-gradient(
    175.13deg,
    #191c27 3.93%,
    rgba(25, 28, 39, 0) 85.37%
  );

  /* For Tablet */
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    max-height: 400px;
  }
  /* For Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    max-height: 15rem;
  }
`;

export const Video = styled.video`
  border-radius: 20px;
  /* width: 100%; */
  background: linear-gradient(
    175.13deg,
    #191c27 3.93%,
    rgba(25, 28, 39, 0) 85.37%
  );

  margin: auto;
  max-height: 75vh;
  /* min-width: calc(100vw - 200px); */
  /* min-width: 100vw; */
  width: 100vw;
  object-fit: fill;

  /* For Tablet */
  /* @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    max-width: 100%;
  } */
  /* For Mobile */
  /* @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    max-width: 100%;
  } */

  /* min-width: 50vw; */
  /* height: 500px; */
  /* max-height: 500px; */
  /* width: 50%; */
  /* margin: 0 auto; */
  /* display: table; */
`;

export const CaraouselContainerHorizontal = styled.div`
  position: relative;
  width: 100%;
  margin: auto;
  /* max-height: 40rem; */
  /* margin-bottom: 1rem; */

  /* For Tablet */
  /* @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    max-height: 26rem;
  } */
  /* For Mobile */
  /* @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    max-height: 14rem;
  } */

  /* For Tablet */
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    width: 100%;
  }
`;

export const CaraouselContainer = styled.div`
  /* display: flex;
  justify-content: space-between; */
  position: relative;
  /* margin-left: 1rem; */
  margin-left: ${(props) => props.marginLeft};
  /* margin-top: 1.5rem; */
  margin-top: ${(props) => props.marginTop};
  max-width: 100%;
  /* For Tablet */
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: 1rem 0;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const IndicatorsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CustomCarouselHorizontal = styled(Carousel)`
  width: 100%;
  /* max-height: 650px; */
  /* max-height: 40rem; */

  > .ant-carousel {
    width: 100%;
    height: 40rem;
  }

  > .slick-list {
    /* height: 650px; */
    width: 100%;
  }
  > .slick-dots-bottom {
    /* bottom: 230px; */
    /* bottom: -25px; */
    top: 102%;
  }
  > .slick-dots li button {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.grey['100']
        : props.theme.colors.grey['300']};
  }
  > .slick-dots li.slick-active button {
    width: 20px;
    height: 8px;
    border-radius: 10px;
    background: linear-gradient(118.21deg, #ec4781 22.9%, #6038f7 130.15%);
    box-shadow: -1px 2px 5px rgba(179, 39, 155, 0.54);
  }
`;

export const CustomCarousel = styled(Carousel)`
  /* background: red; */
  /* width: 250px; */
  width: 15rem;
  display: flex !important;
  /* position: relative; */
  /* height: 500px; */
  > .slick-list {
    width: 100%;
  }
  /* .slick-track {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    height: auto !important;
  } */
  /* height: ; */
  /* display: flex;
  flex-direction: column;
  row-gap: 10px; */
  /* > .ant-carousel .slick-slider {
    display: flex;
  } */
  > .slick-dots li button {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.grey['100']
        : props.theme.colors.grey['300']};
  }
  > .slick-dots li.slick-active button {
    width: 8px;
    height: 20px;
    border-radius: 10px;
    background: linear-gradient(118.21deg, #ec4781 22.9%, #6038f7 130.15%);
    box-shadow: -1px 2px 5px rgba(179, 39, 155, 0.54);
  }
  > .slick-dots-right {
    right: -25px;
  }
  > .slick-dots {
    /* top: 270px; // or 100px */
    top: 15rem; // or 100px
    position: relative;
  }

  /* For Tablet */
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    width: 100%;
    > .slick-dots {
      top: 170px; // or 100px
      position: absolute;
    }
    > .slick-dots li.slick-active button {
      width: 20px;
      height: 8px;
    }
  }
  /* For Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    > .slick-dots {
      top: 100px;
    }
  }
`;

export const ImageSlide = styled.img`
  border-radius: 10px;
  margin: 0.5rem auto;
  width: 100%;
`;
export const ImageSlideVertical = styled.img`
  border-radius: 10px;
  margin: 0.5rem auto;
  width: 100%;
  height: 9rem;

  /* For Tablet */
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: 0 5px;
    border-radius: 20px;
  }
  /* For Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    height: 4.5rem;
    border-radius: 10px;
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 10px; */
  /* height: 500px;
  padding-bottom: 15%; */

  height: auto;

  /* For Tablet */
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    flex-direction: row;
    height: auto;
  }
`;

export const UpButton = styled(UpOutlined)`
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['100']
      : props.theme.colors.black['400']};

  &:hover {
    cursor: ${(props) => (props.currentslide !== 0 ? 'pointer' : 'default')};
  }

  /* For Tablet */
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    transform: rotate(270deg);
  }
`;
export const DownButton = styled(DownOutlined)`
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['100']
      : props.theme.colors.black['400']};

  &:hover {
    cursor: ${(props) =>
      props.currentslide !== props.lastslide ? 'pointer' : 'default'};
  }

  /* For Tablet */
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    transform: rotate(270deg);
  }
`;

export const SlideNumsWrapper = styled.div`
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  text-align: center;
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['100']
      : props.theme.colors.black['400']};
  display: contents;
  writing-mode: vertical-rl;
  text-orientation: sideways-right;

  /* For Tablet */
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    writing-mode: inherit;
  }
`;

export const SlideNum = styled.p`
  margin: 0.2rem;
  font-weight: 600;
  opacity: ${(props) => props.opacity};
`;

// Defaul Video Components

export const DefaultSoundImg = styled(SoundImg)`
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const DefaultVideoContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const DefaultVideo = styled(Video)`
  width: inherit;
`;
