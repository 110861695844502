import styled from 'styled-components';
import { Avatar } from 'antd';
import { VscCopy } from 'react-icons/vsc';

export const Container = styled.div`
  width: auto;
  height: auto;
  margin: 20px 50px;
  /* padding: 0px 20px; */
  border-radius: 12px;
`;

export const ProfileDiv = styled.div`
  position: relative;
  height: 200px;
  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['300']
      : props.theme.colors.grey['100']};
  border-radius: 6px;
`;
export const CustomAvatar = styled(Avatar)`
  position: absolute;
  left: 50%;
  bottom: -55px;
  transform: translate(-50%, 0);
`;
export const InfoDiv = styled.div`
  padding-top: 30px;
  margin-top: 20px;
  text-align: center;
  color: #fff;
`;
export const UserName = styled.div`
  padding-top: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  /* identical to box height, or 38px */

  text-align: center;
  letter-spacing: -1px;

  /* Light/Light_300 */

  /* color: #b8c1da; */
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['100']
      : props.theme.colors.black['400']};
`;
export const UserEmail = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  /* or 20px */

  text-align: center;

  /* Dark/Dark_100 */

  color: #777d92;

  /* Inside auto layout */

  flex: none;
  order: 1;
`;
export const UpdateButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
export const UpdateButton = styled.div`
  color: #b8c1da;
  width: auto;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  /* identical to box height, or 18px */

  text-align: center;

  /* color: #b8c1da; */
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['300']
      : props.theme.colors.black['400']};

  /* background: #191c27; */
  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['300']
      : props.theme.colors.grey['100']};
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 4px;

  cursor: pointer;
`;
export const WalletInfoWrapper = styled.div`
  padding: 20px;
  margin-top: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;

  height: 138px;
  /* background: #191c27; */
  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['300']
      : props.theme.colors.grey['100']};
  border-radius: 8px;
`;
export const WalletCard = styled.div`
  width: 372px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 22px;
  gap: 15px;

  background: rgba(18, 20, 28, 0.4);
  background: ${(props) =>
    props.theme.mode === 'dark' ? '#12141c66' : '#E7EAF366'};
  /* Dark/Dark_200 */

  border: 1px solid #31374d;
  border-radius: 10px;
`;

export const TextInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const TextHeading = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  /* or 22px */

  /* text-align: center; */

  /* Light/Light_300 */

  /* color: #b8c1da; */
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['100']
      : props.theme.colors.black['400']};
`;
export const SubText = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  /* identical to box height, or 18px */

  text-align: center;

  /* Dark/Dark_100 */

  color: #777d92;
`;
export const CopyIcon = styled(VscCopy)`
  margin-left: 10px;
  margin-top: -1px;
  font-size: 12px;
  cursor: pointer;
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['300']
      : props.theme.colors.black['300']};
`;
export const LeftDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: auto;
`;
export const WalletHeading = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  /* identical to box height, or 26px */

  display: flex;
  align-items: center;

  /* Light/Light_200 */

  /* color: #e7eaf3; */
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['100']
      : props.theme.colors.black['400']};
`;
export const WalletSubHeading = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 168%;
  /* identical to box height, or 24px */

  /* Dark/Dark_100 */

  color: #777d92;
`;

export const JoinWhiteListButton = styled.button`
  padding: 10px 20px;
  background: ${(props) =>
    props.disabled === true
      ? '#31374D'
      : 'linear-gradient(118.21deg, #ec4781 22.9%, #6038f7 130.15%);'};
  border: none;
  color: ${(props) => (props.disabled === true ? '#777D92' : '#ffffff')};
  border-radius: 10px;
  font-weight: 500;
  font-size: 16px;
  cursor: ${(props) => (props.disabled === true ? 'not-allowed' : 'pointer')};
`;

export const MainButton = styled(JoinWhiteListButton)`
  margin-left: auto;
  border-radius: 6px;
`;

// wallet not connected

export const NotConnectedContainer = styled.div`
  width: auto;
  height: 500px;
  margin: 20px 50px;
  /* padding: 0px 20px; */
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['300']
      : props.theme.colors.grey['100']};
`;
export const NotConnectedHeading = styled(WalletHeading)`
  margin: 1rem 0;
  font-size: 40px;
`;
export const NotConnectedSubHeading = styled(WalletSubHeading)`
  /* margin: 1rem 0; */
  width: 30%;
  text-align: center;
`;

export const ConnectWalletButton = styled(JoinWhiteListButton)`
  border-radius: 6px;
  margin: 1rem 0;
`;
