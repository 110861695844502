import React from 'react';
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { PoolType, VerticalPoolCardWrapper } from './AllPools.style';
import { useWindowSize } from '../../../hooks';
import VerticalCard from '../../VerticalCard/VerticalCard';
import HorizontalCard from '../../HorizontalCard/HorizontalCard';
import HorizontalCardSkeleton from '../../HorizontalCard/HorizontalCardSkeleton';

function Upcomming() {
  const { width } = useWindowSize();

  const theme = useTheme();

  const { upcomingPools, upcomingPoolsLoading } = useSelector(
    (state) => state.pools
  );

  if (upcomingPoolsLoading) {
    return <HorizontalCardSkeleton />;
  }

  if (upcomingPools.length === 0) {
    return null;
  }
  if (upcomingPools.length === 1) {
    return (
      <>
        <PoolType>Upcoming Projects</PoolType>
        {width >= parseInt(theme.breakpoints.tablet, 10) ? (
          <HorizontalCard type="upcoming" data={upcomingPools[0]} />
        ) : (
          <VerticalPoolCardWrapper>
            <VerticalCard type="upcoming" data={upcomingPools[0]} />
          </VerticalPoolCardWrapper>
        )}
      </>
    );
  }

  return (
    <>
      <PoolType>Upcoming Projects</PoolType>
      <VerticalPoolCardWrapper>
        {upcomingPools.map((list) => (
          <VerticalCard key={list._id} type="upcoming" data={list} />
        ))}
      </VerticalPoolCardWrapper>
    </>
  );
}

export default Upcomming;
