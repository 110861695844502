/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { getBackendUrl } from '../../api/constants';

const apiUrl = getBackendUrl(process.env.REACT_APP_ENV);

const fetchServerTimestamp = createAsyncThunk(
  `${apiUrl}projects/client/v1/getCurrentDateTime`,
  async () => {
    const response = await axios.get(
      `${apiUrl}projects/client/v1/getCurrentDateTime`
    );

    return response.data.date;
  }
);

export const initialState = {
  timeStamp: '',
  loading: false,
  error: null,
};

export const timeSlice = createSlice({
  name: 'time',
  initialState,
  extraReducers: (builder) => {
    /*
     * fetchServerTimestamp Cases
     */

    builder.addCase(fetchServerTimestamp.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchServerTimestamp.fulfilled, (state, action) => {
      state.loading = false;
      state.timeStamp = action.payload;
    });
    builder.addCase(fetchServerTimestamp.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default timeSlice.reducer;

export { fetchServerTimestamp };
