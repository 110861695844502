import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { useTheme } from 'styled-components';
import {
  toggleMobileNavbar,
  toggleSwitchNetwork,
} from '../../../../store/slices/modal';

import ArrowDark from '../../Images/ArrowDark.svg';
import ArrowLight from '../../Images/ArrowLight.svg';
import BNB from '../../Images/BNB.svg';
import Avalanche from './assets/avalanche.svg';
import Polygon from '../../../../assets/poly.svg';
import OptiLogo from '../../../../assets/opti.png';
import PolygonZkevm from '../../../../assets/polyzkvm.png';
import ArbiLogo from '../../../../assets/arbi.png';
// import Solana from './assets/solana.svg';

const NavDivCompose = styled.div`
  /* padding-right: 5px;
  padding-left: 5px; */

  /* padding: 1px 5px 5px 5px; */
  padding: 0px 2px 4px 3px;

  /* @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    display: none;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  } */
`;

const NavDivNetworkBtn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* background: #31374d; */
  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['200']
      : props.theme.colors.grey['100']};
  padding: 2px 25px 0px 3px;
  border-radius: 6px;
  /* margin-left: 5px; */
  margin: 10px 5px 10px 10px;

  /* @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    display: none;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  } */
`;

const NavSpan = styled.span`
  color: ${(props) => props.color};
  /* color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['100']
      : props.theme.colors.black['200']}; */
  font-weight: 500;
  padding: 1px 0 2px 5px;

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: 16px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 16px;
  }
`;

const NavBtnDiv = styled.button`
  /* background: #191c27; */
  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['300']
      : props.theme.colors.grey['200']};
  border-radius: 6px;
  display: flex;
  align-items: center;
  /* margin-right: 10px; */
  padding: 0 12px 0 0;
  border: none;

  /* @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    display: none;
    padding: 0;
    margin: 0;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
    padding: 0;
    margin: 0;
  } */
`;
const NetworkImg = styled.img`
  height: 20px;
  width: 20px;
`;
function ChangeNetworkButton() {
  const theme = useTheme();
  const { balance, networkId } = useSelector((state) => state.user);
  const { isMobileNavbarModalModalVisible } = useSelector(
    (state) => state.modal
  );

  const dispatch = useDispatch();

  const openSwitchNetworkModal = () => {
    if (isMobileNavbarModalModalVisible) dispatch(toggleMobileNavbar());

    dispatch(toggleSwitchNetwork());
  };

  return (
    <NavBtnDiv onClick={openSwitchNetworkModal}>
      <NavDivNetworkBtn>
        <NavDivCompose>
          {networkId === 56 && <NetworkImg src={BNB} alt="BNB" />}
          {networkId === 97 && <NetworkImg src={BNB} alt="BNB" />}
          {networkId === 43114 && (
            <NetworkImg src={Avalanche} alt="Avalanche" />
          )}
          {networkId === 43113 && (
            <NetworkImg src={Avalanche} alt="Avalanche" />
          )}
          {networkId === 137 && <NetworkImg src={Polygon} alt="Polygon" />}
          {networkId === 80001 && <NetworkImg src={Polygon} alt="Polygon" />}
          {networkId === 42161 && <NetworkImg src={ArbiLogo} alt="BNB" />}
          {networkId === 421613 && <NetworkImg src={ArbiLogo} alt="BNB" />}
          {networkId === 10 && <NetworkImg src={OptiLogo} alt="Avalanche" />}
          {networkId === 420 && <NetworkImg src={OptiLogo} alt="Avalanche" />}
          {networkId === 1101 && (
            <NetworkImg src={PolygonZkevm} alt="Polygon" />
          )}
          {networkId === 1442 && (
            <NetworkImg src={PolygonZkevm} alt="Polygon" />
          )}
        </NavDivCompose>
        <NavDivCompose>
          <NavSpan
            color={
              theme.mode === 'dark'
                ? theme.colors.grey['100']
                : theme.colors.black['200']
            }
          >
            {networkId === 56 && 'BSC'}
            {networkId === 97 && 'BSC'}
            {networkId === 43114 && 'Avalanche'}
            {networkId === 43113 && 'Avalanche'}
            {networkId === 137 && 'Polygon'}
            {networkId === 80001 && 'Polygon'}
            {networkId === 42161 && 'Arbitrum'}
            {networkId === 421613 && 'Arbitrum'}
            {networkId === 10 && 'Optimism'}
            {networkId === 420 && 'Optimism'}
            {networkId === 1101 && 'Polygon zkEVM'}
            {networkId === 1442 && 'Polygon zkEVM'}
          </NavSpan>
        </NavDivCompose>
        <NavDivCompose>
          <img
            src={theme.mode === 'dark' ? ArrowDark : ArrowLight}
            alt="Arrow"
            style={{ paddingBottom: '3px', marginLeft: 'auto' }}
          />
        </NavDivCompose>
      </NavDivNetworkBtn>
      {/* <NavDivCompose> */}
      <NavSpan
        color={
          theme.mode === 'dark'
            ? theme.colors.grey['300']
            : theme.colors.black['300']
        }
      >
        {parseFloat(balance).toFixed(3)}
      </NavSpan>
      {/* </NavDivCompose> */}
    </NavBtnDiv>
  );
}

export default ChangeNetworkButton;
