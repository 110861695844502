import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Skeleton } from 'antd';
import {
  UserStakeContainer,
  LeftStake,
  RightStake,
  LogoImg,
  StakeLeftHeading,
  MyStake,
  Textbox,
  StakeReward,
  MyStakeStatus,
  HiddenDiv,
  MyStakeContainer,
  RewardsEarned,
  DepositBtn,
  ConnectWalletbtn,
  NativeSkeleton,
  RightButton,
} from '../AsvaStake.style';
import LpLogo from '../../assets/logo.svg';
import pools from '../../../../config/stakePool';
import {
  // totalAsvaStake,
  // asvaTokenBalance,
  userStakeInfo,
  rewardPercent,
  switchNetwork,
} from '../../../../Web3/web3';
import {
  toggleConnectWallet,
  toggleStakeModal,
  toggleUnStakeModal,
} from '../../../../store/slices/modal';
// import { storeStakeInfo } from '../../../../store/slices/staking';
import { currentEnv } from '../../StakeEnv';
import uptoTwoDecimal from '../../../../utils/uptoTwoDecimal';

function LpStake() {
  const [userInfo, setUserInfo] = useState();
  const [reward, setReward] = useState();
  const [active, setActive] = useState(false);
  const [chainId, setChainId] = useState(56);
  const [loading, setLoading] = useState(false);
  const isConnected = useSelector((state) => state.user);
  const { lp } = useSelector((state) => state.stake);
  const contract = pools.lp[0];
  const dispatch = useDispatch();

  useMemo(async () => {
    try {
      if (isConnected.isConnected) {
        setLoading(true);
        const address = await currentEnv(process.env.REACT_APP_ENV);
        const rewardPer = await rewardPercent(
          contract.contractAddress[address]
        );
        const userStake = await userStakeInfo(
          contract.contractAddress[address]
        );
        setChainId(address);
        setUserInfo(userStake);
        const rewards =
          (parseFloat(rewardPer) / 10000) * parseFloat(userStake.amount);
        setReward(rewards.toFixed(2));
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }, [isConnected, lp]);
  const changeNetwork = async () => {
    const network = await switchNetwork(97);
    console.log(network);
  };
  if (isConnected?.isConnected && loading) {
    return (
      <MyStakeContainer>
        <NativeSkeleton>
          <Skeleton.Input active block />
        </NativeSkeleton>
      </MyStakeContainer>
    );
  }
  return isConnected.isConnected ? (
    isConnected.networkId === chainId ? (
      <MyStakeContainer className={active ? 'active' : ''} cursor>
        <UserStakeContainer onClick={() => setActive(!active)}>
          <LeftStake>
            <LogoImg src={LpLogo} />
            <Textbox>
              <StakeLeftHeading>Farm ASVA/BUSD LP</StakeLeftHeading>
              <MyStake>Deposit LP to Earn ASVA</MyStake>
            </Textbox>
          </LeftStake>
          <RightStake>
            <Textbox margin="20px">
              <MyStake>Reward</MyStake>
              <StakeReward color="#3BEEAD">46% APR</StakeReward>
            </Textbox>
            <Textbox margin="20px">
              <MyStake>Period</MyStake>
              <StakeReward color="#FCB778">30 days</StakeReward>
            </Textbox>
            <Textbox margin="20px">
              <MyStake>My Stake</MyStake>
              <MyStakeStatus>
                {userInfo?.amount ? uptoTwoDecimal(userInfo?.amount) : ''}
              </MyStakeStatus>
            </Textbox>
          </RightStake>
        </UserStakeContainer>
        <HiddenDiv>
          <LeftStake>
            <Textbox>
              <RewardsEarned>Reward Earned </RewardsEarned>
              <RewardsEarned>Maturity Date</RewardsEarned>
            </Textbox>
            <Textbox>
              <RewardsEarned>
                {' '}
                {reward ? uptoTwoDecimal(reward) : ''} ASVA{' '}
              </RewardsEarned>
              <RewardsEarned>
                {userInfo?.amount === '0'
                  ? '------'
                  : `${moment
                      .utc(parseFloat(userInfo?.date) * 1000)
                      .format('DD MMM YYYY, HH:mm ')}
                 UTC`}
                {/* {' '}
                {moment(parseFloat(userInfo?.date) * 1000).format(
                  'DD MMM YYYY, HH:mm zz'
                )}{' '} */}
              </RewardsEarned>
            </Textbox>
          </LeftStake>
          <RightButton>
            <DepositBtn
              background="linear-gradient(118.21deg, #ec4781 22.9%, #6038f7 130.15%)"
              onClick={() =>
                dispatch(
                  toggleStakeModal({
                    apr: 46,
                    period: 30,
                    isLp: true,
                  })
                )
              }
            >
              Deposit
            </DepositBtn>
            <DepositBtn
              background="#5E50FF"
              disabled={
                new Date() < new Date(parseFloat(userInfo?.date)) * 1000 ||
                userInfo?.amount <= 0
              }
              className={
                new Date() < new Date(parseFloat(userInfo?.date)) * 1000 ||
                userInfo?.amount <= 0
                  ? 'disable'
                  : ''
              }
              onClick={() =>
                dispatch(
                  toggleUnStakeModal({
                    apr: 46,
                    period: 30,
                    isLp: true,
                    stakedInPool: userInfo?.amount || 0,
                    earnedRewards: reward,
                  })
                )
              }
            >
              withdrow
            </DepositBtn>
          </RightButton>
        </HiddenDiv>
      </MyStakeContainer>
    ) : (
      <MyStakeContainer className={active ? 'active' : ''} cursor>
        <UserStakeContainer onClick={() => setActive(!active)}>
          <LeftStake>
            <LogoImg src={LpLogo} />
            <Textbox>
              <StakeLeftHeading>Farm ASVA</StakeLeftHeading>
              <MyStake>Deposit ASVA to Earn ASVA</MyStake>
            </Textbox>
          </LeftStake>
          <RightStake>
            <Textbox margin="20px">
              <MyStake>Reward</MyStake>
              <StakeReward color="#3BEEAD">46% APR</StakeReward>
            </Textbox>
            <Textbox margin="20px">
              <MyStake>Period</MyStake>
              <StakeReward color="#FCB778">30 days</StakeReward>
            </Textbox>
            <Textbox margin="20px">
              <MyStake>My Stake</MyStake>
              <MyStakeStatus>N/A</MyStakeStatus>
            </Textbox>
          </RightStake>
        </UserStakeContainer>
        <HiddenDiv>
          <LeftStake>
            <Textbox>
              <RewardsEarned>Reward Earned </RewardsEarned>
              <RewardsEarned>Maturity Date</RewardsEarned>
            </Textbox>
            <Textbox>
              <RewardsEarned> 00.00 ASVA </RewardsEarned>
              <RewardsEarned>DD/MM/YYYY 00.00</RewardsEarned>
            </Textbox>
          </LeftStake>
          <RightStake>
            <ConnectWalletbtn onClick={changeNetwork}>
              Change Network
            </ConnectWalletbtn>
          </RightStake>
        </HiddenDiv>
      </MyStakeContainer>
    )
  ) : (
    <MyStakeContainer className={active ? 'active' : ''} cursor>
      <UserStakeContainer onClick={() => setActive(!active)}>
        <LeftStake>
          <LogoImg src={LpLogo} />
          <Textbox>
            <StakeLeftHeading>Farm ASVA</StakeLeftHeading>
            <MyStake>Deposit ASVA to Earn ASVA</MyStake>
          </Textbox>
        </LeftStake>
        <RightStake>
          <Textbox margin="20px">
            <MyStake>Reward</MyStake>
            <StakeReward color="#3BEEAD">46% APR</StakeReward>
          </Textbox>
          <Textbox margin="20px">
            <MyStake>Period</MyStake>
            <StakeReward color="#FCB778">30 days</StakeReward>
          </Textbox>
          <Textbox margin="20px">
            <MyStake>My Stake</MyStake>
            <MyStakeStatus>N/A</MyStakeStatus>
          </Textbox>
        </RightStake>
      </UserStakeContainer>
      <HiddenDiv>
        <LeftStake>
          <Textbox>
            <RewardsEarned>Reward Earned </RewardsEarned>
            <RewardsEarned>Maturity Date</RewardsEarned>
          </Textbox>
          <Textbox>
            <RewardsEarned> 00.00 ASVA </RewardsEarned>
            <RewardsEarned>DD/MM/YYYY 00.00</RewardsEarned>
          </Textbox>
        </LeftStake>
        <RightStake>
          <ConnectWalletbtn onClick={() => dispatch(toggleConnectWallet())}>
            Connect Wallet
          </ConnectWalletbtn>
        </RightStake>
      </HiddenDiv>
    </MyStakeContainer>
  );
}

export default LpStake;
