/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  Allocation,
  CloseIcon,
  Container,
  LogoBackground,
  ModalStyles,
  NoteText,
  ProjectLogo,
  ProjectName,
  Tag,
  TagContainer,
  AmountContainer,
  AmountInput,
  DetailsContainer,
  Flex,
  MaxButton,
  SubmitButton,
  TokenLogo,
  TokenName,
  WalletBalance,
  WalletBalanceContainer,
  LoadingContainer,
} from './StakeModal.styles';
import closeIcon from '../../../../assets/Close.svg';
import asvaLogo from '../../../../assets/asvaLogo.svg';
import lpLogo from '../../assets/logo.svg';
import Loader from '../../../Loader/Loader';
import {
  InnerContainer,
  ThankYouText,
  TickBackground,
} from '../../../Modal/ThankYou/ThankYouModal.styles';
import TickPng from '../../../../assets/tick.png';
import { toggleStakeModal } from '../../../../store/slices/modal';
import {
  asvaLpTokenBalance,
  asvaTokenBalance,
  stakeAsvaToken,
  userStakeInfo,
} from '../../../../Web3/web3';
import pools from '../../../../config/stakePool';
import formatAddress from '../../../../utils/formatAddress';
import { currentEnv } from '../../StakeEnv';
import { storeStakeInfo } from '../../../../store/slices/staking';
import uptoTwoDecimal from '../../../../utils/uptoTwoDecimal';

function StakeModal() {
  const [amount, setAmount] = useState('');
  // const [modalOpen, setModalOpen] = useState(true);
  const userData = useSelector((state) => state.user);
  const { isStakeModalVisible, rewardPercent, period, isLp, stake2 } =
    useSelector((state) => state.modal);
  console.log(stake2, ' rewardPercent, period, isLp ');
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [success, setSuccess] = useState(false);
  const [asvaBalance, setAsvaBalance] = useState(0);
  const [txHash, setTxHash] = useState('');
  const [mDate, setMDate] = useState('');

  const dispatch = useDispatch();

  const handleCancel = () => {
    dispatch(toggleStakeModal());
  };

  const setMaxUserCanInvest = () => {
    setAmount(asvaBalance);
  };

  const handleStake = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const address = await currentEnv(process.env.REACT_APP_ENV);
      let contract;
      let tokenContractAddress;
      if (isLp) {
        console.log('world');
        // eslint-disable-next-line prefer-destructuring
        contract = pools.lp[0];
        tokenContractAddress = pools.lp[0].stakingTokenAddress[address];
      } else {
        console.log('hello');
        let index = 0;
        if (period === '7') {
          index = 0;
        } else if (period === '15') {
          index = 1;
        } else if (period === '30') {
          index = 2;
        } else if (period === '90') {
          index = 3;
        } else if (period === '21') {
          index = 4;
        } else if (period === '60') {
          index = 5;
        } else if (period === '91') {
          index = 6;
        } else if (period === '180') {
          index = 7;
        } else if (period === '365') {
          index = 8;
        }
        tokenContractAddress =
          pools?.pools[index]?.stakingTokenAddress[address];
        contract = pools?.pools[index];
      }
      console.log(tokenContractAddress, contract, 'contractcontract');
      console.log(
        contract.contractAddress[address],
        ' contract.contractAddress[address],'
      );
      const result = await stakeAsvaToken(
        amount,
        contract.contractAddress[address],
        tokenContractAddress
      );
      console.log('done', result);
      const displayTxHash = formatAddress(result?.transactionHash);
      console.log(displayTxHash, 'displayTxHash');
      setTxHash(result?.transactionHash);
      setSuccess(true);
      setLoading(false);
      const network = await currentEnv(process.env.REACT_APP_ENV);
      const userStake = await userStakeInfo(contract.contractAddress[network]);
      console.log(address, userStake, 'sssssssssssss');
      setMDate(userStake.date);
      dispatch(
        storeStakeInfo({
          pool: isLp
            ? 'lp'
            : period === '7'
            ? 1
            : period === '15'
            ? 2
            : period === '30'
            ? 3
            : period === '90'
            ? 4
            : period === '21'
            ? 5
            : period === '60'
            ? 6
            : period === '91'
            ? 7
            : period === '180'
            ? 8
            : period === '365'
            ? 9
            : null,
          stakedAmount: userStake.amount,
          maturityDate: userStake.date,
        })
      );
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(async () => {
    if (isLp) {
      // console.log('ttttttttt');
      const balance = await asvaLpTokenBalance();
      setAsvaBalance(uptoTwoDecimal(balance));
    } else {
      // console.log('ffffffff');
      const balance = await asvaTokenBalance();
      setAsvaBalance(uptoTwoDecimal(balance));
    }
  }, [userData]);

  return (
    <ModalStyles
      footer={null}
      visible={isStakeModalVisible}
      onCancel={handleCancel}
      width={400}
    >
      <CloseIcon src={closeIcon} onClick={handleCancel} />

      <Container>
        <LogoBackground>
          <ProjectLogo src={isLp ? lpLogo : asvaLogo} alt="close" />
        </LogoBackground>
        <ProjectName>{isLp ? 'Stake ASVA/BUSD' : 'Stake ASVA'}</ProjectName>
        {loading && <Allocation>Processing...</Allocation>}
        {success && (
          <Allocation>
            Staked {amount} {isLp ? 'ASVA/BUSD' : 'ASVA'}
          </Allocation>
        )}
        {!loading && success === false && (
          <TagContainer>
            <Tag bgColor="green">{rewardPercent}% APR</Tag>
            <Tag bgColor="yellow">{stake2 ? '90' : period} Days</Tag>
          </TagContainer>
        )}
      </Container>
      {loading ? (
        <DetailsContainer>
          <LoadingContainer>
            <Loader />
            <NoteText>Confirm transaction on Metamask</NoteText>
          </LoadingContainer>
        </DetailsContainer>
      ) : success ? (
        <DetailsContainer>
          <InnerContainer>
            <TickBackground>
              <img src={TickPng} alt="tick" />
            </TickBackground>
            <ThankYouText>
              Congratulations, your tokens are now staked and you immediately
              start earning rewards in $ASVA!
            </ThankYouText>
            {/* <Text>Trns Hash   0xD9992.....7f78dB7</Text>
            <Text>Maturity period  12, MAR, 2022 12:20 UTC </Text> */}

            <NoteText>
              Trns Hash{' '}
              <a
                href={
                  userData.networkId === 56
                    ? `https://bscscan.com/tx/${txHash}`
                    : `https://testnet.bscscan.com/tx/${txHash}`
                }
                target="_blank"
                rel="noreferrer"
              >
                {formatAddress(txHash)}
              </a>
            </NoteText>
            <NoteText>
              Maturity period{' '}
              {moment
                .utc(parseFloat(mDate) * 1000)
                .format('DD MMM YYYY, HH:mm ')}{' '}
              UTC
            </NoteText>
          </InnerContainer>
        </DetailsContainer>
      ) : (
        <DetailsContainer>
          <WalletBalanceContainer>
            <Flex>
              <TokenLogo src={isLp ? lpLogo : asvaLogo} alt="BUSD" />
              <TokenName>{isLp ? 'ASVA/BUSD' : 'ASVA'}</TokenName>
            </Flex>

            <WalletBalance>
              Wallet Bal:
              <span>{asvaBalance}</span>
            </WalletBalance>
          </WalletBalanceContainer>

          <AmountContainer>
            <AmountInput
              type="text"
              placeholder="Enter Amount"
              onChange={(e) => {
                // console.log(e.target.value);
                if (e.target.value === '') {
                  setAmount('');
                  // console.log('unset');
                } else {
                  // console.log('set');
                  setAmount(uptoTwoDecimal(e.target.value));
                }
              }}
              value={amount}
            />
            <MaxButton onClick={setMaxUserCanInvest}>Max</MaxButton>
          </AmountContainer>

          <NoteText>
            Note: Your {isLp ? 'LP tokens' : 'tokens'} will be locked for the
            chosen period and you won't be able to withdraw it before lock
            expiration.
          </NoteText>

          <SubmitButton
            disabled={
              asvaBalance <= 0 || amount * 1 <= 0 || amount * 1 > asvaBalance
            }
            onClick={handleStake}
          >
            Approve to Stake
          </SubmitButton>
        </DetailsContainer>
      )}
    </ModalStyles>
  );
}

export default StakeModal;
