import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import socketio from 'socket.io-client';
import { getBackendUrl } from '../api/constants';
import { Footer, Navbar } from '../components';
import ConnectWalletModal from '../components/Modal/ConnectWallet/ConnectWalletModal';
import DisconnectWalletModal from '../components/Modal/Disconnect/DisconnectWalletModal';
import JoinPoolModal from '../components/Modal/JoinPoolModal/JoinPoolModal';
import JoinPoolModalFCFS from '../components/Modal/JoinPoolModal/JoinPoolModalFCFS';
import SwitchNetworkModal from '../components/Modal/SwitchNetwork/SwitchNetworkModal';
import ThankYouModal from '../components/Modal/ThankYou/ThankYouModal';
import { NotificationContainer } from './Layout.styles';
import { initializeConnection } from '../store/slices/socket';

const Container = styled.div`
  max-width: 1500px;
  margin: 0 auto;
  width: 95%;
`;

// eslint-disable-next-line react/prop-types
function Layout({ children }) {
  const { text, show, type } = useSelector((state) => state.notification);
  const { selectedPoolV1 } = useSelector((state) => state.pools);
  const { networkId, isConnected } = useSelector((state) => state.user);
  // console.log(networkId, 'layout');
  const {
    isJoinPoolModalVisible,
    isThankYouModalVisible,
    isJoinPoolFcfsModalVisible,
  } = useSelector((state) => state.modal);

  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const connection = getBackendUrl(process.env.REACT_APP_ENV);
    try {
      const socket = socketio.connect(connection, {
        transports: ['websocket'],
      });
      console.log(socket, 'sc');
      socket.emit('connected', 'user connected');
      dispatch(
        initializeConnection({
          data: socket,
          isConnected: true,
        })
      );
      return () => {
        // console.log('disconnect');
        socket.disconnect();
      };
    } catch (error) {
      // console.log('err', error);
      dispatch(
        initializeConnection({
          data: error,
          isConnected: false,
        })
      );
    }
  }, []);

  return (
    <>
      {show && location.pathname.includes('pools') && type === 'connect' && (
        <NotificationContainer type={type}>
          <p className="text" type={type}>
            {text}
          </p>
        </NotificationContainer>
      )}

      {show && type === 'install' && (
        <NotificationContainer type={type}>
          <p className="text" type={type}>
            {text}
          </p>
        </NotificationContainer>
      )}

      {/* {location.pathname === '/' && (
        <NotificationContainer type="old-link">
          <p className="text" type="old-link">
            You are on Metalaunch V2. To visit older version{' '}
            <a
              className="old-link-a"
              target="_blank"
              href="https://app-v1.metalaunch.io/"
              rel="noreferrer"
            >
              Click Here
            </a>
          </p>
        </NotificationContainer>
      )} */}
      {location.pathname === '/stake' && networkId !== 56 && isConnected && (
        <NotificationContainer type="network">
          <p className="text" type="network">
            Please switch to BSC Network and Connect.
          </p>
        </NotificationContainer>
      )}
      {location.pathname === '/stake' && !isConnected && (
        <NotificationContainer type="install">
          <p className="text" type="install">
            Please Connect Your Metamask Wallet
          </p>
        </NotificationContainer>
      )}

      {/* {!show && location.pathname.includes('pools') && (
        <NotificationContainer type="old-link">
          <p className="text" type="old-link">
            You are on Metalaunch V2. To visit older version{' '}
            <a
              className="old-link-a"
              target="_blank"
              href="https://app-v1.metalaunch.io/"
              rel="noreferrer"
            >
              Click Here
            </a>
          </p>
        </NotificationContainer>
      )} */}

      <Navbar />
      <Container>{children}</Container>
      <Footer />

      <ConnectWalletModal />
      <DisconnectWalletModal />
      <SwitchNetworkModal />
      {selectedPoolV1 && (
        <>
          {isJoinPoolModalVisible && <JoinPoolModal />}
          {isThankYouModalVisible && <ThankYouModal />}
          {isJoinPoolFcfsModalVisible && <JoinPoolModalFCFS />}
        </>
      )}
    </>
  );
}

export default Layout;
