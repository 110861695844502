import { Spin } from 'antd';
import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: '#FF0074' }} spin />
);

function Loader() {
  return <Spin indicator={antIcon} />;
}

export default Loader;
