import styled from 'styled-components';
import { Modal } from 'antd';
import { Link } from 'react-router-dom';

export const ModalStyles = styled(Modal)`
  & .ant-modal-close {
    display: none;
  }
  /* @media (max-width: 767px) {
    &.ant-modal {
      margin: 0 0 0 7px;
    }
  } */
  /* &.ant-modal {
    margin: 0 0 0 7px;
  } */
  & .ant-modal-content {
    /* background: transparent; */
    background-color: ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black['400']
        : props.theme.colors.grey['100']};
    border-radius: 12px;
  }
  & .ant-modal-body {
    padding: 24px 0 24px 0;
  }
`;

export const NavContainer = styled.div`
  /* display: flex; */
  background-size: cover;
  background-position: top;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border: 1.5px solid rgba(255, 255, 255, 0.08); */
  border-bottom: 2px solid
    ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black['300']
        : props.theme.colors.grey['200']};

  overflow: hidden;
  padding-right: 60px;

  @media screen and (max-width: 1290px) {
    padding-right: 0px !important;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    flex-direction: row;
    overflow: hidden;
    padding: 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    /* width: 130%; */
    flex-direction: row;
    overflow: hidden;
    padding: 0;
  }

  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['400']
      : props.theme.colors.grey['100']};
`;

export const NavRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    margin: 10px 0px 10px auto;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 10px 0px 10px 0;
  }
`;

export const NavMobileButton = styled.div`
  display: none;
  cursor: pointer;
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    display: inline;
    margin-left: 10px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: inline;
    margin-left: 10px;
  }
`;

export const NavWrapperDiv = styled(Link)`
  padding: 0px 21px 0px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &:before {
    border-right: 1.5px solid
      ${(props) =>
        props.theme.mode === 'dark'
          ? props.theme.colors.black['300']
          : props.theme.colors.grey['200']};
    content: '';
    position: absolute;
    right: 0;
    top: -15px;
    z-index: 100;
    width: 0px;
    height: 165%;
    /* background: rgba(255, 255, 255, 0.08); */
    background: ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black['300']
        : props.theme.colors.grey['200']};
  }
  @media screen and (max-width: 1290px) {
    padding: 0px 21px 0px 0px !important;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    padding: 0px 21px 0px 0px;
    &:before {
      border: 0;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 0px 0px 0px 0px;
    width: 292px;

    &:before {
      border: 0;
    }
  }
`;

export const NavImageContainer = styled.div`
  display: block;
  width: 170px;
  /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
  border-radius: 5px;
  padding-right: 5px;
  padding-left: 10px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    /* max-width: 15px;
    max-height: 20px; */
  }
`;

export const NavTextContainer = styled.h1`
  /* font-family: ${(props) => props.theme.font.family.primary}; */
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['100']
      : props.theme.colors.black['100']};
  margin-bottom: 0px;
  padding-top: 1px;
`;
export const NavDivContainer = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    display: none;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;

export const NavUL = styled.ul`
  display: flex;
  align-items: center;
  gap: 30px;
  list-style: none;
  margin: 0;
  padding: 0 0 0 60px;
  position: relative;

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    /* padding: 0 0 0 60px; */
    display: flex;
    flex-direction: column;
    padding: 0px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;
    flex-direction: column;
    padding: 0px;
  }
`;

export const NavLI = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  gap: 30px;
  list-style: none;
  padding: 33px 0px 33px 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;

  display: block;
  cursor: ${(props) => (props.disable ? 'not-allowed' : 'pointer')};

  border-bottom: 4px solid transparent;
  border-image: ${(props) => props.borderImage};
  border-image-slice: 1;
  height: 80px;
  border-radius: 15px 15px 0px 0px;
  &:hover {
    /* color: #b8c1da; */
    color: ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.grey['300']
        : props.theme.colors.black['200']};
  }
  /* color: ${(props) => props.color}; */

  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['100']
      : props.theme.colors.black['100']};

  // Active Dark
  color: ${(props) =>
    props.active &&
    props.theme.mode === 'dark' &&
    props.theme.colors.grey['200']};

  // Active Light
  color: ${(props) =>
    props.active &&
    props.theme.mode === 'light' &&
    props.theme.colors.black['400']};

  /* &:active {
    color: #ffffff;
    cursor: pointer;
    gap: 30px;
    padding: 33px 0px 33px 0px;

    &:after {
      position: absolute;
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      bottom: 0;
      padding: 0 0 3px 0;
      background: linear-gradient(118.21deg, #ec4781, #6038f7 130.15%);
      box-shadow: -6px 2px 13px rgba(179, 39, 155, 0.54);
      border-radius: 15px 15px 0px 0px;
    }
  } */
  /* &.active {
    color: #ffb7e2;
    text-shadow: 0px 4px 7px #ff2d78;
    height: 10px;
  } */

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    padding: 0px;
    border-bottom: 4px solid transparent;
    border-image: ${(props) => props.borderImage};
    border-image-slice: 1;
    height: 30px;
    border-radius: 15px 15px 0px 0px;
    &:hover {
      /* color: #b8c1da; */
      color: ${(props) =>
        props.theme.mode === 'dark'
          ? props.theme.colors.grey['300']
          : props.theme.colors.black['200']};
    }
    /* color: ${(props) => props.color}; */

    color: ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black['100']
        : props.theme.colors.black['100']};

    // Active Dark
    color: ${(props) =>
      props.active &&
      props.theme.mode === 'dark' &&
      props.theme.colors.grey['200']};

    // Active Light
    color: ${(props) =>
      props.active &&
      props.theme.mode === 'light' &&
      props.theme.colors.black['400']};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 0px;
    border-bottom: 4px solid transparent;
    border-image: ${(props) => props.borderImage};
    border-image-slice: 1;
    height: 30px;
    border-radius: 15px 15px 0px 0px;
    &:hover {
      /* color: #b8c1da; */
      color: ${(props) =>
        props.theme.mode === 'dark'
          ? props.theme.colors.grey['300']
          : props.theme.colors.black['200']};
    }
    /* color: ${(props) => props.color}; */

    color: ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black['100']
        : props.theme.colors.black['100']};

    // Active Dark
    color: ${(props) =>
      props.active &&
      props.theme.mode === 'dark' &&
      props.theme.colors.grey['200']};

    // Active Light
    color: ${(props) =>
      props.active &&
      props.theme.mode === 'light' &&
      props.theme.colors.black['400']};
  }
`;

export const NavHoverDiv = styled.div`
  margin-left: 10px;
`;

export const NavDiv = styled.div`
  display: flex;
  /* padding-right: 60px; */
  /* width: 20%; */
  justify-content: space-evenly;
  margin-left: auto;

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    margin-left: auto;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-left: 0;
  }
`;

export const NavBtnDivConnect = styled.div`
  /* background: #191c27; */
  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['300']
      : props.theme.colors.grey['200']};
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin-right: 10px;
  /* padding: 0 12px 0 0; */

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    display: none;
    padding: 0;
    margin: 0;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
    padding: 0;
    margin: 0;
  }
`;

export const ShowDesktop = styled.div`
  display: flex;
  gap: 20px;
  margin-right: 14px;

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    display: none;
    padding: 0;
    margin: 0;
  }
`;

// export const NavBtnDiv = styled.button`
//   /* background: #191c27; */
//   background: ${(props) =>
//     props.theme.mode === 'dark'
//       ? props.theme.colors.black['300']
//       : props.theme.colors.grey['200']};
//   border-radius: 6px;
//   display: flex;
//   align-items: center;
//   margin-right: 10px;
//   padding: 0 12px 0 0;
//   border: none;

//   @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
//     display: none;
//     padding: 0;
//     margin: 0;
//   }
//   @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
//     display: none;
//     padding: 0;
//     margin: 0;
//   }
// `;

export const NavButton = styled.button`
  display: flex;
  align-items: center;
  gap: 5px;
  background: transparent;
  padding: 6px 16px;
  outline: none;
  border: none;
  height: 48px;

  background: #5e50ff;
  border-radius: 6px;
`;

export const NavP = styled.p`
  /* font-family: 'Roboto'; */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;

  text-align: center;

  color: #e7eaf3;

  line-height: 20px;
  color: #ffffff;
  margin: 0;
`;

// export const NavDivNetworkBtn = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;
//   /* background: #31374d; */
//   background: ${(props) =>
//     props.theme.mode === 'dark'
//       ? props.theme.colors.black['200']
//       : props.theme.colors.grey['100']};
//   padding: 2px 25px 0px 3px;
//   border-radius: 6px;
//   /* margin-left: 5px; */
//   margin: 10px 5px 10px 10px;

//   @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
//     display: none;
//   }
//   @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
//     display: none;
//   }
// `;

// export const NavDivCompose = styled.div`
//   /* padding-right: 5px;
//   padding-left: 5px; */

//   /* padding: 1px 5px 5px 5px; */
//   padding: 0px 2px 6px 3px;

//   @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
//     display: none;
//   }
//   @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
//     display: none;
//   }
// `;

// export const NavDivComposeMetaMaskandSpan = styled.div`
//   /* padding-right: 5px;
//   padding-left: 5px; */

//   /* padding: 1px 5px 5px 5px; */
//   padding: 0px 2px 0px 3px;

//   @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
//     display: none;
//   }
//   @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
//     display: none;
//   }
// `;

// export const NavDivVerified = styled.div`
//   background: rgba(59, 238, 173, 0.2);
//   /* padding: 0px 2px 6px 3px; */
//   display: flex;
//   padding: 3px 7px 3px 7px;
//   border-radius: 20px;
//   align-items: center;
//   justify-content: center;
//   gap: 5px;
//   margin: 0 0 0 5px;

//   @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
//     display: none;
//   }
//   @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
//     display: none;
//   }
// `;

// export const NavSpanVerified = styled.span`
//   color: ${(props) => props.color};
//   /* color: ${(props) =>
//     props.theme.mode === 'dark'
//       ? props.theme.colors.grey['100']
//       : props.theme.colors.black['200']}; */
//   font-weight: 500;
//   /* color: #30d89b; */
//   color: ${(props) => props.theme.colors.green['400']};
//   font-size: 0.625rem;

//   @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
//     font-size: 16px;
//   }
//   @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
//     font-size: 16px;
//   }
// `;

// export const NavDivComposeImage = styled.img`
//   padding: 0 0 0 5px;
// `;

export const NavSpan = styled.span`
  color: ${(props) => props.color};
  /* color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['100']
      : props.theme.colors.black['200']}; */
  font-weight: 500;
  padding: 1px 0 2px 5px;

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: 16px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 16px;
  }
`;

export const NavToggleBtnDiv = styled.div`
  /* background: #191c27; */
  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['300']
      : props.theme.colors.grey['200']};
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin-right: 10px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    /* display: none; */
    padding: 0;
    /* margin: 0; */
    margin-right: 10px;
    /* margin-right: 20px; */
  }
`;

export const NavToggleBtn = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  /* background: #191c27; */
  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['300']
      : props.theme.colors.grey['200']};
  border-radius: 6px;

  border: none;
  outline: none;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 0;
  }
`;

export const NavImage = styled.div`
  color: ${(props) => props.color};
`;

export const NavModalDiv = styled.div``;

export const NavContainerModal = styled.div`
  padding: 10% 0% 10% 0%;
  border-radius: 20px;
  position: relative;
`;

export const NavCloseIcon = styled.img`
  position: absolute;
  top: -1%;
  left: 15px;
  right: -12px;
  z-index: 100;
  cursor: pointer;
`;

export const NavLinks = styled.div`
  margin: 0 0 90px 0;
`;

export const NavConnectInfo = styled.div`
  /* padding: 10px 0px 0 60px; */
  padding: 10px 0px 0 0px;
  position: relative;
  border-top: 1px solid
    ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black['200']
        : props.theme.colors.grey['200']};
  /* &:before {
    border-top: 1px solid rgba(255, 255, 255, 0.08);
    content: '';
    position: absolute;
    right: -59px;
    top: -7px;
    z-index: 100;
    width: 399px;

    background: rgba(255, 255, 255, 0.08);
  } */

  /* @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    &:before {
      border-top: 1px solid rgba(255, 255, 255, 0.08);
      content: '';
      position: absolute;
      right: -54px;
      top: -7px;
      z-index: 100;
      width: 357px;

      background: rgba(255, 255, 255, 0.08);
    }
  } */

  display: flex;
  align-content: center;
  justify-content: center;
  align-items: stretch;
`;

export const NavModalConnectBtnDiv = styled.div`
  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['300']
      : props.theme.colors.grey['200']};
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin-right: 10px;

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    /* padding: 5px; */
    margin: 3px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    /* padding: 5px; */
    margin: 3px;
  }
`;

export const NavModalContainerBtnDiv = styled.div`
  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['300']
      : props.theme.colors.grey['200']};
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin-right: 10px;

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    padding: 5px;
    margin: 3px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 5px;
    margin: 3px;
  }
`;

export const NavModalDivNetworkBtn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['200']
      : props.theme.colors.grey['100']};
  padding: 5px 13px 2px 6px;
  border-radius: 6px;
  margin-left: 5px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
  }
`;

export const NavModalDivCompose = styled.div`
  /* padding-right: 5px;
  padding-left: 5px; */
  padding: 0 5px 5px 3px;

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    display: inline;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: inline;
  }
`;

export const NavModalImage = styled.img`
  max-width: 25px;
`;
