import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
* {
    box-sizing: border-box;
    margin :0;
    padding: 0;
    font-family: ${(props) => props.theme.font.family.primary};

    img {
      display: block;
      max-width: 100%;
    }

    /* @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      font-size: 80%;
    } */
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      font-size: 90%;
    }
  }

  body {
    background: ${(props) => props.theme.background};
    font-family: 'Roboto Condensed', sans-serif;

    // for rakesh's laptop screen resolution :P
    @media (max-width: 1300px) and (min-width: 1366px) {
      zoom: 90%
    }
  }

  button {
    cursor: pointer;

    :disabled {
      cursor: not-allowed;
    }
  }

`;

export default GlobalStyle;
