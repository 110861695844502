import styled from 'styled-components';
import { Collapse } from 'antd';

const { Panel } = Collapse;

export const Container = styled.div`
  margin: 50px;
  padding: 10px 20px;
  border-radius: 12px;
`;
export const Heading = styled.h1`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['200']
      : props.theme.colors.black['400']};
  text-align: center;
`;
export const Description = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #777d92;
  margin: auto;
  text-align: center;
  max-width: 40%;
`;

// acordian

export const CustomCollapse = styled(Collapse)`
  border: none;
  background: none;
  margin: 0 10%;
  .ant-collapse-content-box {
    padding-top: 0px;
  }
  /* .ant-collapse-item:last-child > .ant-collapse-header {
    border-radius: ${(props) =>
    props.currentActive !== props.panelKey ? '10px 10px 0 0' : '10px'};
  } */
`;
export const CustomLink = styled.a`
  margin-left: 4px;
`;
export const CustomPanel = styled(Panel)`
  border-bottom: none !important;
  margin: 1rem auto;

  .ant-collapse-header {
    padding: 16px 48px !important;
    padding-top: ${(props) =>
      props.currentActive === props.panelKey
        ? '30px !important'
        : '16px !important'};
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    color: ${(props) =>
      props.theme.mode === 'dark'
        ? `${props.theme.colors.grey['300']} !important`
        : `${props.theme.colors.black['100']} !important`};
    background: ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black['300']
        : props.theme.colors.grey['100']};
    border-radius: ${(props) =>
      props.currentActive === props.panelKey ? '10px 10px 0 0' : '10px'};
  }
  .ant-collapse-content {
    padding: 0px 32px !important;
    padding-bottom: ${(props) =>
      props.currentActive === props.panelKey
        ? '25px !important'
        : '0px !important'};
    border-top: none;
    border-radius: ${(props) =>
      props.currentActive === props.panelKey ? '0 0 10px 10px' : '10px'};
    background: ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black['300']
        : props.theme.colors.grey['100']};
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #777d92;
  }
  .ant-collapse-arrow {
    right: 48px !important;
  }
`;
