import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import NativeStake from './NativeStake';
import {
  // totalAsvaStake,
  userStakeInfo,
  rewardPercent,
} from '../../../../../Web3/web3';
import pools from '../../../../../config/stakePool';
// import { storeStakeInfo } from '../../../../../store/slices/staking';
import { currentEnv } from '../../../StakeEnv';

function Ninetydays({ nintyToggele, open }) {
  const { pool8 } = useSelector((state) => state.stake);
  // const dispatch = useDispatch();
  const [userInfo, setUserInfo] = useState();
  const [reward, setReward] = useState();
  const [loading, setLoading] = useState(false);
  const isConnected = useSelector((state) => state.user);

  const contract = pools.pools[7];
  // console.log(contract.contractAddress[isConnected.networkId]);
  const isDisabled = true;
  useMemo(async () => {
    try {
      if (isConnected.isConnected) {
        setLoading(true);
        // const total = await totalAsvaStake(
        //   contract.contractAddress[isConnected.networkId]
        // );
        const address = await currentEnv(process.env.REACT_APP_ENV);
        const rewardPer = await rewardPercent(
          contract.contractAddress[address]
        );
        const userStake = await userStakeInfo(
          contract.contractAddress[address]
        );
        // dispatch(
        //   storeStakeInfo({
        //     pool: 4,
        //     stakedAmount: userStake.amount,
        //     maturityDate: userStake.date,
        //   })
        // );
        setUserInfo(userStake);
        const rewards =
          (parseFloat(rewardPer) / 10000) * parseFloat(userStake.amount);
        setReward(rewards.toFixed(2));
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }, [isConnected, pool8]);
  return (
    <NativeStake
      HandleToggle={nintyToggele}
      open={open}
      Reward={reward}
      Period="180"
      Apr="18"
      isDisabled={isDisabled}
      userInfo={userInfo}
      loading={loading}
    />
  );
}

export default Ninetydays;
