import React from 'react';
import PropTypes from 'prop-types';
import bscNetworkImg from '../../assets/Bnbchain.png';
import avaxNetworkImg from '../../assets/Avalanche.png';
import polygonNetworkImg from '../../assets/poly.svg';
import optimism from '../../assets/opti.png';
import arbitrum from '../../assets/arbi.png';
import Polyzkevm from '../../assets/polyzkvm.png';

function NetworkImg({ networkId, networkName }) {
  let renderImg = bscNetworkImg;
  if (networkId === 97 || networkId === 56) {
    renderImg = bscNetworkImg;
  } else if (networkId === 137 || networkId === 80001) {
    renderImg = polygonNetworkImg;
  } else if (networkId === 43114 || networkId === 43113) {
    renderImg = avaxNetworkImg;
  } else if (networkId === 1442 || networkId === 1101) {
    renderImg = Polyzkevm;
  } else if (networkId === 420 || networkId === 10) {
    renderImg = optimism;
  } else if (networkId === 421613 || networkId === 42161) {
    renderImg = arbitrum;
  }
  return (
    <img
      src={renderImg}
      alt={networkName}
      style={{
        display: 'inline',
        width: '20px',
        height: '20px',
        marginRight: '5px',
      }}
    />
  );
}

NetworkImg.defaultProps = {
  networkName: '',
};
NetworkImg.propTypes = {
  networkId: PropTypes.number.isRequired,
  networkName: PropTypes.string,
};

export default NetworkImg;
