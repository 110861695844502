import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import toast from 'react-hot-toast';
import {
  CloseIcon,
  Container,
  CustomButton,
  CustomForm,
  CustomInput,
  CustomUpload,
  DetailsWrapper,
  FlexContainer,
  FormHeading,
  FormItem,
  FormSubHeading,
  FormWrapper,
  ModalStyles,
  UploadWrapper,
} from './EditProfileModal.style';
import Close from '../../../../assets/Close.png';
import Upload from '../../../../assets/Upload.svg';
import axiosHelper from '../../../../api/axios-helper';
import { fetchProfileInfo } from '../../../../store/slices/user';

function EditProfileModal({ profileModalOpen, closeModal }) {
  const { address, profileInfo, isConnected } = useSelector(
    (state) => state.user
  );

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [img, setImg] = useState(null);
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (profileInfo) {
      // console.log('sdadsadqwewq', profileInfo);
      setAvatarUrl(profileInfo?.avatarUrl);
      setUsername(profileInfo?.firstName);
      setEmail(profileInfo?.email);
      form.setFieldsValue({
        name: profileInfo?.firstName,
        email: profileInfo?.email,
      });
    }
  }, [profileInfo]);

  const handleCancel = () => {
    closeModal();
  };

  const onFinish = async (values) => {
    try {
      setUploading(true);
      // console.log('Success:', values, img);

      const formData = new FormData();
      formData.append('file', img);

      const result = await axiosHelper('upload', 'POST', null, formData);
      const url = result?.data?.data?.location;
      // console.log(result?.data?.data?.location, 'result');

      await axiosHelper('user-profile', 'PUT', null, {
        walletAddress: address || '',
        firstName: values?.name || '',
        lastName: '',
        email: values?.email || '',
        avatarUrl: url || avatarUrl || '',
        solanaWallet: profileInfo?.solanaWallet || '',
      });
      setUploading(false);
      dispatch(fetchProfileInfo({ address: address.toLowerCase() }));
      closeModal();
    } catch (error) {
      console.log(error);
      setUploading(false);
      toast.error('Something went wrong!');
    }
  };

  const onFinishFailed = (errorInfo) => {
    toast.error('Something went wrong!');
    console.log('Failed:', errorInfo);
  };

  const dummyRequest = async ({ file, onSuccess }) => {
    // console.log(file, 'req');
    setImg(file);

    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  return (
    <ModalStyles
      footer={null}
      visible={profileModalOpen}
      onCancel={handleCancel}
      width={400}
    >
      <Container>
        <CloseIcon src={Close} onClick={handleCancel} />

        <FormWrapper>
          <FormHeading>Edit your profile</FormHeading>
          <FormSubHeading>Please fill the details given below</FormSubHeading>

          <CustomForm
            name="basic"
            form={form}
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 24,
            }}
            // initialValues={{
            //   remember: true,
            // }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <FlexContainer>
              <UploadWrapper>
                <FormItem label="Upload Profile" valuePropName="fileList">
                  <CustomUpload
                    maxCount="1"
                    showUploadList={{ showPreviewIcon: false }}
                    // action="http://dev-admin-api.metalaunch.io/upload" // change to dynamic
                    customRequest={dummyRequest}
                    listType="picture-card"
                    showImg={!!img}
                    onRemove={() => setImg(null)}
                  >
                    <div style={{ position: 'relative' }}>
                      <img
                        src={Upload}
                        alt="upload"
                        style={
                          avatarUrl
                            ? {
                                position: 'absolute',
                                left: '50%',
                                top: '50%',
                                transform: 'translate(-50%, -50%)',
                                opacity: 1,
                                zIndex: 99,
                              }
                            : null
                        }
                      />
                      {avatarUrl ? (
                        <img
                          width="100px"
                          height="100px"
                          src={avatarUrl}
                          alt=""
                          style={{ opacity: 0.4 }}
                        />
                      ) : (
                        <div style={{ marginTop: 8 }}>
                          Browse your computer to upload
                        </div>
                      )}
                    </div>
                  </CustomUpload>
                </FormItem>
              </UploadWrapper>

              <DetailsWrapper>
                <FormItem
                  label="Your Name"
                  name="name"
                  rules={[
                    {
                      // required: true,
                      message: 'Please input your name!',
                    },
                  ]}
                >
                  <CustomInput
                    placeholder="Enter your username"
                    value={username}
                  />
                </FormItem>

                <FormItem
                  name="email"
                  label="Your Email"
                  rules={[
                    {
                      type: 'email',
                      message: 'The input is not valid E-mail!',
                    },
                    {
                      // required: true,
                      message: 'Please input your E-mail!',
                    },
                  ]}
                >
                  <CustomInput placeholder="Enter your email" value={email} />
                </FormItem>
              </DetailsWrapper>
            </FlexContainer>
            <FormItem
              wrapperCol={{
                offset: 0,
                span: 24,
              }}
            >
              <CustomButton disabled={uploading || !isConnected}>
                Submit
              </CustomButton>
            </FormItem>
          </CustomForm>
        </FormWrapper>
      </Container>
    </ModalStyles>
  );
}

export default EditProfileModal;
