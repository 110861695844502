import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
  // Container,
  Endedpoollist,
  Endedpoolcard,
  Image,
  Wrapper,
  Idotype,
  Head,
  Data,
  TrData,
  Status,
  SHead,
  SData,
  Imagediv,
  Network,
  NetworkHead,
  NetworkName,
  NetworkText,
  ProjectType,
  ProjectTypeHead,
  ProjectTypeText,
  ImageBackground,
} from './EndedPoolCard.styles';
import NetworkImg from '../../NetworkImg/NetworkImg';
import EndedPoolCardSkeleton from './EndedPoolCardSkeleton';
import networkName from '../../../utils/configNetworkName';
import { completedProjects } from '../../../helpers/common';

function EndedpoolDesktop({ data, datas }) {
  if (datas) {
    return <EndedPoolCardSkeleton />;
  }

  return (
    <Link to={data.projectStatus === 'ended' && `/pools/${data._id}`}>
      <Endedpoollist to={`/pools/${data._id}`}>
        <Endedpoolcard>
          <Imagediv image={data.projectBannerUrl}>
            <ImageBackground>
              <Image alt={data.projectName} src={data.projectLogoUrl} />
            </ImageBackground>
          </Imagediv>

          <Wrapper>
            <Idotype>
              <Head>Project Name</Head>
              <Data>{data.projectName}</Data>
            </Idotype>
            <Network>
              <NetworkHead>Network</NetworkHead>
              <NetworkText>
                <NetworkImg
                  networkId={data.projectNetwork.networkId}
                  style={{ height: '16px' }}
                />

                <NetworkName>
                  {networkName[data.projectNetwork.networkId]}
                </NetworkName>
              </NetworkText>
            </Network>
            <ProjectType>
              <ProjectTypeHead>Project type</ProjectTypeHead>
              <ProjectTypeText>{data.typeOfProject}</ProjectTypeText>
            </ProjectType>
            <ProjectType>
              <ProjectTypeHead>Total raise</ProjectTypeHead>
              <TrData>{`${Math.floor(
                data.tokenDetails.totalFundraise
              ).toLocaleString('en-US')} USD`}</TrData>
            </ProjectType>
            <ProjectType>
              <ProjectTypeHead>Ended Date</ProjectTypeHead>
              <ProjectTypeText>
                {moment(data.idoDates.roundTwoEndDate).format('DD MMM  YYYY')}
              </ProjectTypeText>
            </ProjectType>
            <Status>
              <SHead>Status</SHead>
              <SData
                color={data.projectStatus === 'ended' ? '#FB5959' : '#FCB778'}
              >
                {completedProjects.includes(data._id)
                  ? 'Refunded'
                  : data.projectStatus === 'ended'
                  ? 'Ended'
                  : 'Claimable'}
              </SData>
            </Status>
          </Wrapper>
        </Endedpoolcard>
      </Endedpoollist>
    </Link>
  );
}
export default EndedpoolDesktop;
