import { Modal } from 'antd';
import styled from 'styled-components';

export const ModalStyles = styled(Modal)`
  & .ant-modal-close {
    display: none;
  }

  & .ant-modal-content {
    /* background: transparent; */
    background-color: ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black['400']
        : props.theme.colors.grey['100']};
    border-radius: 12px;
  }
  & .ant-modal-body {
    padding: 24px 0 12px 0;
  }
`;

export const NavContainerModal = styled.div`
  padding-top: 30px;
  border-radius: 20px;
  position: relative;
`;

export const NavCloseIcon = styled.img`
  position: absolute;
  top: -1%;
  left: 15px;
  right: -12px;
  z-index: 100;
  cursor: pointer;
`;

export const NavLinks = styled.div`
  margin: 0 0 90px 0;
`;

export const NavUL = styled.ul`
  display: flex;
  align-items: center;
  gap: 30px;
  list-style: none;
  margin: 0;
  padding: 0 0 0 60px;

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    /* padding: 0 0 0 60px; */
    display: flex;
    flex-direction: column;
    padding: 0px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;
    flex-direction: column;
    padding: 0px;
  }
`;

export const NavLI = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  gap: 30px;
  list-style: none;
  padding: 33px 0px 33px 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;

  display: block;
  cursor: pointer;

  border-bottom: 4px solid transparent;
  border-image: ${(props) => props.borderImage};
  border-image-slice: 1;
  height: 80px;
  border-radius: 15px 15px 0px 0px;
  &:hover {
    /* color: #b8c1da; */
    color: ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.grey['300']
        : props.theme.colors.black['200']};
  }
  /* color: ${(props) => props.color}; */

  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['100']
      : props.theme.colors.black['100']};

  // Active Dark
  color: ${(props) =>
    props.active &&
    props.theme.mode === 'dark' &&
    props.theme.colors.grey['200']};

  // Active Light
  color: ${(props) =>
    props.active &&
    props.theme.mode === 'light' &&
    props.theme.colors.black['400']};

  /* &:active {
    color: #ffffff;
    cursor: pointer;
    gap: 30px;
    padding: 33px 0px 33px 0px;

    &:after {
      position: absolute;
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      bottom: 0;
      padding: 0 0 3px 0;
      background: linear-gradient(118.21deg, #ec4781, #6038f7 130.15%);
      box-shadow: -6px 2px 13px rgba(179, 39, 155, 0.54);
      border-radius: 15px 15px 0px 0px;
    }
  } */
  /* &.active {
    color: #ffb7e2;
    text-shadow: 0px 4px 7px #ff2d78;
    height: 10px;
  } */

  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    padding: 0px;
    border-bottom: 4px solid transparent;
    border-image: ${(props) => props.borderImage};
    border-image-slice: 1;
    height: 30px;
    border-radius: 15px 15px 0px 0px;
    &:hover {
      /* color: #b8c1da; */
      color: ${(props) =>
        props.theme.mode === 'dark'
          ? props.theme.colors.grey['300']
          : props.theme.colors.black['200']};
    }
    /* color: ${(props) => props.color}; */

    color: ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black['100']
        : props.theme.colors.black['100']};

    // Active Dark
    color: ${(props) =>
      props.active &&
      props.theme.mode === 'dark' &&
      props.theme.colors.grey['200']};

    // Active Light
    color: ${(props) =>
      props.active &&
      props.theme.mode === 'light' &&
      props.theme.colors.black['400']};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 0px;
    border-bottom: 4px solid transparent;
    border-image: ${(props) => props.borderImage};
    border-image-slice: 1;
    height: 30px;
    border-radius: 15px 15px 0px 0px;
    &:hover {
      /* color: #b8c1da; */
      color: ${(props) =>
        props.theme.mode === 'dark'
          ? props.theme.colors.grey['300']
          : props.theme.colors.black['200']};
    }
    /* color: ${(props) => props.color}; */

    color: ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black['100']
        : props.theme.colors.black['100']};

    // Active Dark
    color: ${(props) =>
      props.active &&
      props.theme.mode === 'dark' &&
      props.theme.colors.grey['200']};

    // Active Light
    color: ${(props) =>
      props.active &&
      props.theme.mode === 'light' &&
      props.theme.colors.black['400']};
  }
`;

export const NavBtnDivConnect = styled.button`
  outline: none;
  border: none;
  /* background: #191c27; */
  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['300']
      : props.theme.colors.grey['200']};
  border-radius: 6px;
  display: flex;
  align-items: center;
  /* margin-right: 10px; */
`;

export const NavButton = styled.button`
  display: flex;
  align-items: center;
  gap: 5px;
  background: transparent;
  padding: 6px 16px;
  outline: none;
  border: none;
  height: 48px;

  background: #5e50ff;
  border-radius: 6px;
`;

export const NavP = styled.p`
  /* font-family: 'Roboto'; */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;

  text-align: center;

  color: #e7eaf3;

  line-height: 20px;
  color: #ffffff;
  margin: 0;
`;

export const NavConnectInfo = styled.div`
  /* padding: 10px 0px 0 60px; */
  /* padding: 10px 0px 0 0px; */
  position: relative;
  border-top: 1px solid
    ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black['200']
        : props.theme.colors.grey['200']};
  /* &:before {
    border-top: 1px solid rgba(255, 255, 255, 0.08);
    content: '';
    position: absolute;
    right: -59px;
    top: -7px;
    z-index: 100;
    width: 399px;

    background: rgba(255, 255, 255, 0.08);
  } */

  /* @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    &:before {
      border-top: 1px solid rgba(255, 255, 255, 0.08);
      content: '';
      position: absolute;
      right: -54px;
      top: -7px;
      z-index: 100;
      width: 357px;

      background: rgba(255, 255, 255, 0.08);
    }
  } */

  display: flex;
  align-content: center;
  justify-content: center;
  align-items: stretch;
`;

export const ButtonList = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
  /* flex-direction: column; */
  /* margin-top: 20px; */
  margin-top: 12px;
`;
