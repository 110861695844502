/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { getBackendUrl } from '../../api/constants';

const apiUrl = getBackendUrl(process.env.REACT_APP_ENV);
const fetchFeaturedPoolList = createAsyncThunk(
  `fetchFeaturedProjects`,
  async () => {
    const response = await axios.get(`${apiUrl}featured-projects`);

    return response.data.data;
  }
);

const fetchActivePoolList = createAsyncThunk(
  `${apiUrl}v2/projects/active`,
  async () => {
    const response = await axios.get(`${apiUrl}v2/projects/active`);

    return response.data.data;
  }
);

const fetchEndedPoolStats = createAsyncThunk(
  'fetchEndedPoolStats',
  async () => {
    const response = await axios.get(
      `${apiUrl}projects/client/v1/stats?status=ended`
    );

    return response.data.data;
  }
);

const fetchUpcomingPoolList = createAsyncThunk(
  'fetchUpcomingPoolList',
  async () => {
    const response = await axios.get(`${apiUrl}v2/projects/upcoming`);

    return response.data.data;
  }
);

const fetchEndedPoolList = createAsyncThunk(
  `fetchEndedPool`,
  async (Objectkey) => {
    const response = await axios.get(
      `${apiUrl}v2/projects/ended?pageNo=${Objectkey.number}&&search=${Objectkey.search}`
    );

    return response.data.data;
  }
);

const fetchSelectedPoolDetails = createAsyncThunk(
  'fetchSelectedPoolDetails',
  async (id) => {
    const response = await axios.get(`${apiUrl}project-details/${id}`);

    return response.data.data;
  }
);

const fetchSelectedPoolDetailsFromV1 = createAsyncThunk(
  'fetchSelectedPoolDetailsV1',
  async (id) => {
    const response = await axios.post(
      `${apiUrl}projects/client/v1/getProjectById`,
      { id }
    );

    return response.data.data;
  }
);
const fetchTokenMetricsPoolDetails = createAsyncThunk(
  'fetchTokenMetricsPoolDetails',
  async (id) => {
    const response = await axios.get(`${apiUrl}v2/tokenomics/${id}`);

    return response.data.data;
  }
);
const fetchMetalaunchScreening = createAsyncThunk(
  'fetchMetalaunchScreening',
  async (id) => {
    const response = await axios.get(`${apiUrl}v2/metalaunch-screening/${id}`);

    return response.data.data;
  }
);

export const initialState = {
  featuredPools: [],
  featuredPoolsLoading: false,
  featuredPoolsError: null,
  activePools: [],
  activePoolsLoading: false,
  activePoolsError: null,
  upcomingPools: [],
  upcomingPoolsLoading: false,
  upcomingPoolsError: null,
  endedPools: [],
  endedPoolsLoading: false,
  endedPoolsError: null,
  PageNumber: 1,
  selectedPoolLoading: false,
  selectedPool: null,
  selectedPoolError: null,
  selectedPoolV1Loading: false,
  selectedPoolV1: null,
  selectedPoolV1Error: null,
  TokenMetricsLoading: false,
  TokenMetrics: null,
  TokenMetricsError: false,
  MetalaunchscreeningLoading: false,
  Metalaunchscreening: null,
  MetalaunchscreeningError: false,
  EndedPoolStatsLoading: false,
  EndedPoolStatsError: false,
  EndedPoolStats: null,
  loadMoreDisable: false,
};

export const poolSlice = createSlice({
  name: 'pools',
  initialState,
  extraReducers: (builder) => {
    /*
     * fetchFeaturedPoolList Cases
     */

    builder.addCase(fetchFeaturedPoolList.pending, (state) => {
      state.featuredPoolsLoading = true;
    });
    builder.addCase(fetchFeaturedPoolList.fulfilled, (state, action) => {
      state.featuredPoolsLoading = false;
      state.featuredPools = action.payload;
    });
    builder.addCase(fetchFeaturedPoolList.rejected, (state) => {
      state.featuredPoolsLoading = false;
    });

    /*
     * fetchActivePoolList Cases
     */

    builder.addCase(fetchActivePoolList.pending, (state) => {
      state.activePoolsLoading = true;
    });
    builder.addCase(fetchActivePoolList.fulfilled, (state, action) => {
      state.activePoolsLoading = false;
      state.activePools = action.payload;
    });
    builder.addCase(fetchActivePoolList.rejected, (state) => {
      state.activePoolsLoading = false;
    });

    /*
     * fetchUpcomingPoolList Cases
     */

    builder.addCase(fetchUpcomingPoolList.pending, (state) => {
      state.upcomingPoolsLoading = true;
    });
    builder.addCase(fetchUpcomingPoolList.fulfilled, (state, action) => {
      state.upcomingPoolsLoading = false;
      state.upcomingPools = action.payload;
    });
    builder.addCase(fetchUpcomingPoolList.rejected, (state) => {
      state.upcomingPoolsLoading = false;
    });

    /*
     * fetchEndedPoolList Cases
     */

    builder.addCase(fetchEndedPoolList.pending, (state) => {
      state.endedPoolsLoading = true;
      state.loadMoreDisable = false;
    });
    builder.addCase(fetchEndedPoolList.fulfilled, (state, action) => {
      state.endedPoolsLoading = false;
      if (action.payload.length < 6) {
        state.loadMoreDisable = true;
      }
      if (action.meta.arg.search === '' && action.meta.arg.append) {
        action.payload.map((item) => state.endedPools.push(item));
      } else {
        state.endedPools = action.payload;
      }

      if (action.meta.arg.default) {
        state.PageNumber = 2;
      }
      if (action.meta.arg.append) {
        state.PageNumber += 1;
      }
      if (action.meta.arg.decrement) {
        state.PageNumber = 2;
      }
    });
    builder.addCase(fetchEndedPoolList.rejected, (state) => {
      state.endedPoolsLoading = false;
      state.loadMoreDisable = false;
    });

    /*
     * fetchSelectedPoolDetails Cases
     */

    builder.addCase(fetchSelectedPoolDetails.pending, (state) => {
      state.selectedPoolLoading = true;
    });
    builder.addCase(fetchSelectedPoolDetails.fulfilled, (state, action) => {
      state.selectedPoolLoading = false;
      state.selectedPool = action.payload;
    });
    builder.addCase(fetchSelectedPoolDetails.rejected, (state) => {
      state.selectedPoolLoading = false;
    });
    // TokenMetrics cases
    builder.addCase(fetchTokenMetricsPoolDetails.pending, (state) => {
      state.TokenMetricsLoading = true;
    });
    builder.addCase(fetchTokenMetricsPoolDetails.fulfilled, (state, action) => {
      state.TokenMetricsLoading = false;
      state.TokenMetrics = action.payload;
    });
    builder.addCase(fetchTokenMetricsPoolDetails.rejected, (state) => {
      state.TokenMetricsLoading = false;
    });

    // Metalaunch screening cases

    builder.addCase(fetchMetalaunchScreening.pending, (state) => {
      state.MetalaunchscreeningLoading = true;
    });
    builder.addCase(fetchMetalaunchScreening.fulfilled, (state, action) => {
      state.MetalaunchscreeningLoading = false;
      state.Metalaunchscreening = action.payload;
    });
    builder.addCase(fetchMetalaunchScreening.rejected, (state) => {
      state.MetalaunchscreeningLoading = false;
    });

    /*
     * fetchSelectedPoolDetailsFromV1 Cases
     */

    builder.addCase(fetchSelectedPoolDetailsFromV1.pending, (state) => {
      state.selectedPoolV1Loading = true;
    });
    builder.addCase(
      fetchSelectedPoolDetailsFromV1.fulfilled,
      (state, action) => {
        state.selectedPoolV1Loading = false;
        state.selectedPoolV1 = action.payload;
      }
    );
    builder.addCase(fetchSelectedPoolDetailsFromV1.rejected, (state) => {
      state.selectedPoolV1Loading = false;
    });

    builder.addCase(fetchEndedPoolStats.pending, (state) => {
      state.EndedPoolStatsLoading = true;
    });
    builder.addCase(fetchEndedPoolStats.fulfilled, (state, action) => {
      state.EndedPoolStatsLoading = false;
      state.EndedPoolStats = action.payload;
    });
    builder.addCase(fetchEndedPoolStats.rejected, (state) => {
      state.EndedPoolStatsLoading = false;
    });
  },
});

export default poolSlice.reducer;

export {
  fetchFeaturedPoolList,
  fetchActivePoolList,
  fetchUpcomingPoolList,
  fetchEndedPoolList,
  fetchSelectedPoolDetails,
  fetchSelectedPoolDetailsFromV1,
  fetchTokenMetricsPoolDetails,
  fetchMetalaunchScreening,
  fetchEndedPoolStats,
};
