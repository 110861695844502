import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Skeleton } from 'antd';
import {
  UserStakeContainer,
  LeftStake,
  RightStake,
  LogoImg,
  StakeLeftHeading,
  MyStake,
  Textbox,
  StakeReward,
  MyStakeStatus,
  HiddenDiv,
  MyStakeContainer,
  RewardsEarned,
  DepositBtn,
  MyStake1,
  ConnectWalletbtn,
  NativeSkeleton,
  RightButton,
} from '../../AsvaStake.style';
import AsvaLogo from '../../../assets/asvaLogo.svg';
import {
  toggleConnectWallet,
  toggleStakeModal,
  toggleUnStakeModal,
} from '../../../../../store/slices/modal';
import { switchNetwork } from '../../../../../Web3/web3';
import { currentEnv } from '../../../StakeEnv';
import uptoTwoDecimal from '../../../../../utils/uptoTwoDecimal';

function NativeStake({
  Reward,
  Period,
  userInfo,
  Apr,
  HandleToggle,
  open,
  loading,
}) {
  const { isConnected, networkId } = useSelector((state) => state.user);
  // const [active, setActive] = useState(false);
  const [chainId, setChainId] = useState(56);
  // const dates = parseInt(userInfo?.date, 10);
  const dispatch = useDispatch();
  useMemo(async () => {
    const address = await currentEnv(process.env.REACT_APP_ENV);
    setChainId(address);
  }, []);
  const changeNetwork = async () => {
    const address = await currentEnv(process.env.REACT_APP_ENV);
    await switchNetwork(address);
    setChainId(address);
  };
  if (isConnected && loading) {
    return (
      <MyStakeContainer>
        <NativeSkeleton>
          <Skeleton.Input active block />
        </NativeSkeleton>
      </MyStakeContainer>
    );
  }
  return isConnected ? (
    networkId === chainId ? (
      <MyStakeContainer className={open === Period ? 'active' : ''} cursor>
        <UserStakeContainer
          onClick={() => {
            if (open !== Period) {
              // console.log('hello');
              HandleToggle(Period);
            } else {
              HandleToggle('1');
            }
          }}
        >
          <LeftStake>
            <LogoImg src={AsvaLogo} />
            <Textbox>
              <StakeLeftHeading>Stake ASVA</StakeLeftHeading>
              <MyStake1>Deposit ASVA to Earn ASVA</MyStake1>
            </Textbox>
          </LeftStake>
          <RightStake>
            <Textbox
              margin={
                Apr === '6' || Apr === '5' || Apr === '2' ? '25px' : '20px'
              }
            >
              <MyStake>Reward</MyStake>
              <StakeReward color="#3BEEAD">{`${Apr}%  APR`}</StakeReward>
            </Textbox>
            <Textbox
              margin={Apr === '6' ? '25px' : '20px'}
              style={{ width: '80px' }}
            >
              <MyStake>Period</MyStake>
              <StakeReward color="#FCB778">
                {Period === '91' ? '90' : Period} days
              </StakeReward>
            </Textbox>
            <Textbox margin="20px">
              <MyStake>My Stake</MyStake>
              <MyStakeStatus>
                {userInfo?.amount ? uptoTwoDecimal(userInfo?.amount) : ''}
              </MyStakeStatus>
            </Textbox>
          </RightStake>
        </UserStakeContainer>
        <HiddenDiv>
          <LeftStake>
            <Textbox>
              <RewardsEarned>Reward Earned </RewardsEarned>
              <RewardsEarned>Maturity Date</RewardsEarned>
            </Textbox>
            <Textbox>
              <RewardsEarned>
                {' '}
                {Reward ? uptoTwoDecimal(Reward) : ''} ASVA{' '}
              </RewardsEarned>
              <RewardsEarned>
                {userInfo?.amount === '0'
                  ? '------'
                  : `${moment
                      .utc(parseFloat(userInfo?.date) * 1000)
                      .format('DD/MM/YYYY, HH:mm')}`}
              </RewardsEarned>
            </Textbox>
          </LeftStake>
          <RightButton>
            <DepositBtn
              onClick={() =>
                dispatch(
                  toggleStakeModal({
                    apr: Apr,
                    period: Period,
                    stake2: Period === '91' && true,
                  })
                )
              }
              background="linear-gradient(118.21deg, #ec4781 22.9%, #6038f7 130.15%)"
            >
              Deposit
            </DepositBtn>
            <DepositBtn
              background="#5E50FF"
              disabled={
                new Date() < new Date(parseFloat(userInfo?.date)) * 1000 ||
                userInfo?.amount <= 0
              }
              className={
                new Date() < new Date(parseFloat(userInfo?.date)) * 1000 ||
                userInfo?.amount <= 0
                  ? 'disable'
                  : ''
              }
              onClick={() =>
                dispatch(
                  toggleUnStakeModal({
                    apr: Apr,
                    period: Period,
                    stakedInPool: userInfo?.amount || 0,
                    earnedRewards: Reward,
                    stake2: Period === '91' && true,
                  })
                )
              }
            >
              Withdraw
            </DepositBtn>
          </RightButton>
        </HiddenDiv>
      </MyStakeContainer>
    ) : (
      <MyStakeContainer className={open === Period ? 'active' : ''} cursor>
        <UserStakeContainer
          onClick={() => {
            if (open !== Period) {
              // console.log('hello');
              HandleToggle(Period);
            } else {
              HandleToggle('1');
            }
          }}
        >
          <LeftStake>
            <LogoImg src={AsvaLogo} />
            <Textbox>
              <StakeLeftHeading>Stake ASVA</StakeLeftHeading>
              <MyStake1>Deposit ASVA to Earn ASVA</MyStake1>
            </Textbox>
          </LeftStake>
          <RightStake>
            <Textbox margin="20px">
              <MyStake>Reward</MyStake>
              <StakeReward color="#3BEEAD">{Apr}% APR</StakeReward>
            </Textbox>
            <Textbox margin="20px">
              <MyStake>Period</MyStake>
              <StakeReward color="#FCB778">{Period} days</StakeReward>
            </Textbox>
            <Textbox margin="20px">
              <MyStake>My Stake</MyStake>
              <MyStakeStatus>N/A</MyStakeStatus>
            </Textbox>
          </RightStake>
        </UserStakeContainer>
        <HiddenDiv>
          <LeftStake>
            <Textbox>
              <RewardsEarned>Reward Earned </RewardsEarned>
              <RewardsEarned>Maturity Date</RewardsEarned>
            </Textbox>
            <Textbox>
              <RewardsEarned> 00.00 ASVA </RewardsEarned>
              <RewardsEarned>DD/MM/YYYY 00.00</RewardsEarned>
            </Textbox>
          </LeftStake>
          <RightButton>
            <ConnectWalletbtn onClick={changeNetwork}>
              Change Network
            </ConnectWalletbtn>
          </RightButton>
        </HiddenDiv>
      </MyStakeContainer>
    )
  ) : (
    <MyStakeContainer className={open === Period ? 'active' : ''} cursor>
      <UserStakeContainer
        onClick={() => {
          if (open !== Period) {
            // console.log('hello');
            HandleToggle(Period);
          } else {
            HandleToggle('1');
          }
        }}
      >
        <LeftStake>
          <LogoImg src={AsvaLogo} />
          <Textbox>
            <StakeLeftHeading>Stake ASVA</StakeLeftHeading>
            <MyStake1>Deposit ASVA to Earn ASVA</MyStake1>
          </Textbox>
        </LeftStake>
        <RightStake>
          <Textbox margin="20px">
            <MyStake>Reward</MyStake>
            <StakeReward color="#3BEEAD">{Apr}% APR</StakeReward>
          </Textbox>
          <Textbox margin="20px">
            <MyStake>Period</MyStake>
            <StakeReward color="#FCB778">{Period} days</StakeReward>
          </Textbox>
          <Textbox margin="20px">
            <MyStake>My Stake</MyStake>
            <MyStakeStatus>N/A</MyStakeStatus>
          </Textbox>
        </RightStake>
      </UserStakeContainer>
      <HiddenDiv>
        <LeftStake>
          <Textbox>
            <RewardsEarned>Reward Earned </RewardsEarned>
            <RewardsEarned>Maturity Date</RewardsEarned>
          </Textbox>
          <Textbox>
            <RewardsEarned> 00.00 ASVA </RewardsEarned>
            <RewardsEarned>DD/MM/YYYY 00.00</RewardsEarned>
          </Textbox>
        </LeftStake>
        <RightButton>
          <ConnectWalletbtn onClick={() => dispatch(toggleConnectWallet())}>
            Connect Wallet
          </ConnectWalletbtn>
        </RightButton>
      </HiddenDiv>
    </MyStakeContainer>
  );
}

export default NativeStake;
