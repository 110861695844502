import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import copy from 'copy-to-clipboard';
import toast from 'react-hot-toast';
// import { UserOutlined } from '@ant-design/icons';
import {
  ConnectWalletButton,
  Container,
  CopyIcon,
  CustomAvatar,
  FlexContainer,
  InfoDiv,
  LeftDiv,
  MainButton,
  NotConnectedContainer,
  NotConnectedHeading,
  NotConnectedSubHeading,
  ProfileDiv,
  SubText,
  TextHeading,
  TextInfoWrapper,
  UpdateButton,
  UpdateButtonWrapper,
  UserEmail,
  UserName,
  WalletCard,
  WalletHeading,
  WalletInfoWrapper,
  WalletSubHeading,
} from './Profile.style';
import MetaMaskIcon from '../assets/MetaMaskIcon.svg';
import SolanaIcon from '../assets/SolanaIcon.svg';
import EditIcon from '../assets/Edit.svg';
import defaultProfile from '../assets/defaultProfile.svg';
import formatAddress from '../../../utils/formatAddress';
import EditProfileModal from './Modals/EditProfileModal';
import SolanaWalletModal from './Modals/SolanaWalletModal';
import {
  VerifiedBackground,
  VerifiedText,
} from '../../Navbar/components/ChangeAddressButton/ChangeAddressButton';
import Tick from '../../Navbar/Images/Tick.svg';
import InReview from '../../Navbar/Images/InReview.svg';
import failedIcon from '../../Navbar/Images/failedIcon.svg';
import { toggleConnectWallet } from '../../../store/slices/modal';
import walletIcon from '../../PoolDetails/assets/wallet.svg';

function Profile() {
  const user = useSelector((state) => state.user);
  const { isKycVerified } = user;
  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const [solanaModalOpen, setSolanaModalOpen] = useState(false);

  const dispatch = useDispatch();

  const closeModal = () => {
    setProfileModalOpen(false);
    setSolanaModalOpen(false);
  };

  if (user.isConnected) {
    return (
      <Container>
        {profileModalOpen && (
          <EditProfileModal
            profileModalOpen={profileModalOpen}
            closeModal={closeModal}
          />
        )}
        {solanaModalOpen && (
          <SolanaWalletModal
            solanaModalOpen={solanaModalOpen}
            closeModal={closeModal}
          />
        )}

        <ProfileDiv>
          <CustomAvatar
            shape="round"
            size={116}
            icon={
              user?.profileInfo?.avatarUrl ? (
                <img src={user?.profileInfo?.avatarUrl} alt="profile" />
              ) : (
                <img src={defaultProfile} alt="profile" />
              )
            }
          />
        </ProfileDiv>

        <InfoDiv>
          <UserName>
            {user?.profileInfo?.firstName
              ? `${user?.profileInfo?.firstName} ${user?.profileInfo?.lastName}`
              : 'Enter User Name'}
          </UserName>
          <UserEmail>
            {user?.profileInfo?.email || 'Enter Email Address'}
          </UserEmail>
          <UpdateButtonWrapper>
            <UpdateButton onClick={() => setProfileModalOpen(true)}>
              Update
              <img src={EditIcon} alt="edit" />
            </UpdateButton>
          </UpdateButtonWrapper>
        </InfoDiv>

        <WalletInfoWrapper>
          <LeftDiv>
            <WalletHeading>Wallet Addresses</WalletHeading>
            <WalletSubHeading>Your wallet addresses</WalletSubHeading>
          </LeftDiv>
          <WalletCard>
            <img src={SolanaIcon} alt="solana" />
            <TextInfoWrapper>
              <TextHeading>Connected wallet</TextHeading>
              <FlexContainer>
                <SubText>
                  {user?.address ? formatAddress(user?.address) : 'NA'}
                </SubText>
                {user?.address && (
                  <CopyIcon
                    onClick={() => {
                      copy(user?.address);
                      toast.success('Copied to clipboard');
                    }}
                  />
                )}
              </FlexContainer>
            </TextInfoWrapper>
            {user?.isConnected ? null : (
              <MainButton onClick={() => dispatch(toggleConnectWallet())}>
                Connect
              </MainButton>
            )}
            {(isKycVerified === 'verify' || isKycVerified === false) &&
              user?.isConnected && (
                <a
                  href="https://verify-with.blockpass.org/?clientId=asva_ido_on_metalaunch_cce82&serviceName=Asva%20IDO%20on%20MetaLaunch&env=prod"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginLeft: 'auto' }}
                >
                  <MainButton>Verify KYC</MainButton>
                </a>
              )}
            {isKycVerified === 'approved' && (
              <VerifiedBackground
                marginLeft="auto"
                isKycVerified={isKycVerified}
              >
                <img src={Tick} alt="Verified" />
                <VerifiedText>KYC Verified</VerifiedText>
              </VerifiedBackground>
            )}
            {isKycVerified === 'rejected' && (
              <VerifiedBackground
                marginLeft="auto"
                isKycVerified={isKycVerified}
              >
                <img src={failedIcon} alt="InReview" />
                <VerifiedText
                  onClick={() =>
                    window.open(
                      'https://verify-with.blockpass.org/?clientId=asva_ido_on_metalaunch_cce82&serviceName=Asva%20IDO%20on%20MetaLaunch&env=prod'
                    )
                  }
                >
                  KYC Failed
                </VerifiedText>
              </VerifiedBackground>
            )}
            {isKycVerified !== 'approved' &&
              isKycVerified !== 'rejected' &&
              isKycVerified !== 'verify' &&
              isKycVerified !== false &&
              user?.isConnected && (
                <VerifiedBackground
                  marginLeft="auto"
                  isKycVerified={isKycVerified}
                >
                  <img src={InReview} alt="Waiting" />
                  <VerifiedText>InReview</VerifiedText>
                </VerifiedBackground>
              )}
          </WalletCard>
          <WalletCard>
            <img src={MetaMaskIcon} alt="metamask" />
            <TextInfoWrapper>
              <TextHeading>Solana wallet </TextHeading>
              <FlexContainer>
                <SubText>
                  {user?.profileInfo?.solanaWallet
                    ? formatAddress(user?.profileInfo?.solanaWallet)
                    : 'Enter address'}
                </SubText>
                {user?.profileInfo?.solanaWallet && (
                  <CopyIcon
                    onClick={() => {
                      copy(user?.profileInfo?.solanaWallet);
                      toast.success('Copied to clipboard');
                    }}
                  />
                )}
              </FlexContainer>
            </TextInfoWrapper>
            {user?.isConnected ? (
              <MainButton onClick={() => setSolanaModalOpen(true)}>
                Update
              </MainButton>
            ) : (
              <MainButton onClick={() => dispatch(toggleConnectWallet())}>
                Connect
              </MainButton>
            )}
          </WalletCard>
        </WalletInfoWrapper>
      </Container>
    );
  }

  return (
    <NotConnectedContainer>
      <img src={walletIcon} alt="" />
      <NotConnectedHeading>Wallet Not Connected</NotConnectedHeading>
      <NotConnectedSubHeading>
        Please connect your wallet to see your investment history and claim
        rewards
      </NotConnectedSubHeading>
      <ConnectWalletButton onClick={() => dispatch(toggleConnectWallet())}>
        Connect Wallet
      </ConnectWalletButton>
    </NotConnectedContainer>
  );
}

export default Profile;
