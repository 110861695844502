import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import {
  PoolType,
  PoolStatus,
  VerticalPoolCardWrapper,
  ActivePoolContainer,
} from './AllPools.style';
import HorizontalCard from '../../HorizontalCard/HorizontalCard';
import VerticalCard from '../../VerticalCard/VerticalCard';
import { useWindowSize } from '../../../hooks';
import HorizontalCardSkeleton from '../../HorizontalCard/HorizontalCardSkeleton';

function Active() {
  const { activePools, activePoolsLoading } = useSelector(
    (state) => state.pools
  );
  const theme = useTheme();
  const { width } = useWindowSize();

  if (activePoolsLoading) {
    return <HorizontalCardSkeleton />;
  }

  return (
    activePools.length > 0 && (
      <ActivePoolContainer>
        <PoolType>
          Active Projects
          <PoolStatus>LIVE NOW</PoolStatus>
        </PoolType>
        {activePools.length === 1 &&
        width >= parseInt(theme.breakpoints.tablet, 10) ? (
          activePools.map((list) => (
            <HorizontalCard key={list._id} type="active" data={list} />
          ))
        ) : (
          <VerticalPoolCardWrapper>
            {activePools.map((list) => (
              <VerticalCard key={list._id} type="active" data={list} />
            ))}
          </VerticalPoolCardWrapper>
        )}
      </ActivePoolContainer>
    )
  );
}

export default Active;
