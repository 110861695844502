import styled from 'styled-components';

export const Container = styled.div`
  width: auto;
  margin: 50px;
  padding: 10px 20px;
  border-radius: 12px;
  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['300']
      : props.theme.colors.grey['200']};
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    margin: 50px auto;
  }
`;
export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 41px; */

  /* For Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    padding: 1rem;
    flex-direction: column;
    box-sizing: border-box;
  }
`;
export const HeadPoolList = styled.div`
  margin-right: auto;
  width: 90%;
  /* height: 90%; */
  display: flex;
  align-items: center;
  /* justify-content: center; */

  /* For Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    flex-direction: column;
    align-items: center !important;
    justify-content: center;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    flex-direction: column;
    align-items: baseline;
  }
`;
export const Heading = styled.h3`
  font-weight: 600;
  /* font-size: 28px; */
  font-size: 2em;
  line-height: 135%;
  /* margin: 20px; */
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['100']
      : props.theme.colors.black['400']};

  /* For Tablet */
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    margin: 0;

    font-size: 2.3em;
  }

  /* For Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-right: 0;
    /* font-size: 2em; */
  }
`;
export const Span = styled.span`
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['100']
      : props.theme.colors.grey['500']};
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;

  padding-top: 1px;
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    font-size: 0.9em;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
  }
`;
export const Inputfilter = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  max-width: 650px;

  /* For Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    /* flex-direction: row-reverse; */
    margin-top: 1.5rem;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    width: 100%;
    height: 100%;
  }
`;

export const SearchIcon = styled.img`
  width: auto;
  position: absolute;
  top: 20%;
  left: 1%;
  height: auto;
  color: white;
  padding: 2px;
  border-radius: 5px;
  cursor: pointer;
`;
export const Input = styled.input`
  width: 100%;
  height: 100%;
  outline: 1px grey;
  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['400']
      : props.theme.colors.grey['200']};
  border: 1px solid
    ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black['200']
        : props.theme.colors.grey['300']};
  padding: 15px 30px;

  border-radius: 6px;
  padding: 10px 37px;
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['100']
      : props.theme.colors.grey['500']};
  font-size: 14px;
  &::placeholder {
    color: ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black['100']
        : props.theme.colors.grey['500']};
    font-size: 12px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    outline: 1px grey;
    background-color: ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black['400']
        : props.theme.colors.grey['200']};

    padding: 12px 40px;

    border-radius: 6px;
    &::placeholder {
      color: #777d92;
      font-size: 13px;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    width: 247px;
    height: 100%;
    padding: 10px 40px;
  }
`;

export const Filter = styled.div`
  height: 100%;
  display: flex;
  padding: 5px 5px 5px 0px;
  align-items: center;
  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['500']
      : props.theme.colors.grey['200']};
  border-radius: 6px;

  margin-left: 2%;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: max-content;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    height: 100%;
    display: flex;
    padding: 5px 5px 5px 0;
    align-items: center;
    background: ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black['500']
        : props.theme.colors.grey['200']};
    border-radius: 6px;

    margin-left: 2%;
  }
`;
export const Li = styled.span`
  font-size: 12px;
  margin-left: 6px;
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['200']
      : props.theme.colors.grey['500']};

  color: ${(props) =>
    props.active &&
    props.theme.mode === 'dark' &&
    props.theme.colors.grey['200']};

  background: ${(props) =>
    props.active &&
    props.theme.mode === 'dark' &&
    props.theme.colors.black['200']};

  // Active Light

  color: ${(props) =>
    props.active &&
    props.theme.mode === 'light' &&
    props.theme.colors.grey['200']};

  background: ${(props) =>
    props.active &&
    props.theme.mode === 'light' &&
    props.theme.colors.black['200']};

  cursor: pointer;
  padding: 9px 16px;
  border-radius: 6px;

  /* For Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 1.5em;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    font-size: 1.2em;
  }
`;
export const SearchIconDiv = styled.div`
  border: 1px solid
    ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black['200']
        : props.theme.colors.grey['200']};
  padding: 3px;
  border-radius: 5px;
  z-index: 100;
  margin-left: 3%;
`;
export const SearchIconMobile = styled.img`
  margin-bottom: 2%;
`;
export const InputfilterMobile = styled.div`
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  align-content: space-between;
  justify-content: center;
  margin-top: 2.5em;
`;

// table
export const TableHeader = styled.div`
  background: #12141c;
  border-radius: 3px;
`;
export const Table = styled.div`
  width: 100%;
`;

export const Header = styled.div``;
export const TBody = styled.div``;

export const THead = styled.th`
  /* white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: middle !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; */
  //
  text-align: left;
  color: #777d92;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
`;

export const TRow = styled.div`
  display: grid;
  grid-template-columns: 1.5fr repeat(7, 1fr);
  padding: 15px 28px;
  border-radius: 3px;
  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['300']
      : props.theme.colors.grey['100']};
  border: 1px solid
    ${(props) => (props.theme === 'dark' ? '#ffffff33' : '#00000033')};
`;

export const THRow = styled(TRow)`
  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['300']
      : props.theme.colors.grey['100']};
  border: 1px solid
    ${(props) => (props.theme === 'dark' ? '#ffffff33' : '#00000033')};
`;

export const TCell = styled.div`
  /* vertical-align: middle !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; */
  //
  display: flex;
  align-items: center;
  color: ${(props) => (props.textColor ? props.textColor : '#8897c1')};
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
`;

export const ProjectLogo = styled.img`
  margin-right: 10px;
`;

export const ProjectName = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['200']
      : props.theme.colors.black['100']};
`;

export const ProjectType = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #777d92;
`;

export const JoinWhiteListButton = styled.button`
  padding: 15px 25px;
  background: ${(props) =>
    props.disabled === true
      ? '#31374D'
      : 'linear-gradient(118.21deg, #ec4781 22.9%, #6038f7 130.15%);'};
  border: none;
  color: ${(props) => (props.disabled === true ? '#777D92' : '#ffffff')};
  border-radius: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  cursor: ${(props) => (props.disabled === true ? 'not-allowed' : 'pointer')};
`;

export const ClaimButton = styled(JoinWhiteListButton)`
  border-radius: 6px;
  padding: 0.6rem 2.5rem;
  max-width: 114px;
  max-height: 41px;
`;
