import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../Layout/Layout';
import { fetchProfileInfo } from '../../store/slices/user';
import { Wrapper } from './Dashboard.style';
import Faq from './Faq/Faq';
import InvestmentHistory from './InvestmentHistory/InvestmentHistory';
import Profile from './Profile/Profile';

function Dashboard() {
  const dispatch = useDispatch();
  const { address, isConnected } = useSelector((state) => state.user);

  useEffect(() => {
    if (address) {
      dispatch(fetchProfileInfo({ address: address.toLowerCase() }));
    }
  }, [address]);

  return (
    <Layout>
      <Wrapper>
        <Profile />
        {isConnected && <InvestmentHistory />}
        <Faq />
      </Wrapper>
    </Layout>
  );
}

export default Dashboard;
