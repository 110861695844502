import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { Link, NavLink } from 'react-router-dom';
import { toggleMobileNavbar } from '../../../store/slices/modal';
import ChangeAddressButton from '../../Navbar/components/ChangeAddressButton/ChangeAddressButton';
import ChangeNetworkButton from '../../Navbar/components/ChangeNetworkButton/ChangeNetworkButton';
import {
  ButtonList,
  ModalStyles,
  NavBtnDivConnect,
  NavButton,
  NavCloseIcon,
  NavConnectInfo,
  NavContainerModal,
  NavLI,
  NavLinks,
  NavP,
  NavUL,
} from './MobileNavbarModal.styles';

import CloseButton from './assets/CloseButton.svg';
import Wallet from './assets/Wallet.svg';
import { fetchAccountDetailsWindow } from '../../../Web3/web3';
import { setAccountDetails } from '../../../store/slices/user';

function MobileNavbarModal({ currentTab, setCurrentTab }) {
  const { isMobileNavbarModalModalVisible } = useSelector(
    (state) => state.modal
  );

  const { isConnected } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const handleOk = () => {
    dispatch(toggleMobileNavbar());
  };

  const handleCancel = () => {
    dispatch(toggleMobileNavbar());
  };

  const connectWallet = async () => {
    if (window.ethereum === undefined) {
      toast.error('Plase install metamask');
      return;
    }

    const details = await fetchAccountDetailsWindow();

    dispatch(setAccountDetails(details));
  };

  return (
    <div>
      <ModalStyles
        footer={null}
        visible={isMobileNavbarModalModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={400}
      >
        <NavContainerModal>
          <NavCloseIcon src={CloseButton} onClick={handleCancel} />
          <NavLinks>
            <NavUL>
              <Link to="/">
                <NavLI
                  onClick={() => setCurrentTab('Home')}
                  borderImage={
                    currentTab === 'Home'
                      ? 'linear-gradient(118.21deg, #ec4781, #6038f7 130.15%)'
                      : '#12141C'
                  }
                  active={currentTab === 'Home'}
                >
                  Home
                </NavLI>
              </Link>
              {/* <NavLI
                onClick={() => setCurrentTab('Project Pool')}
                borderImage={
                  currentTab === 'Project Pool'
                    ? 'linear-gradient(118.21deg, #ec4781, #6038f7 130.15%)'
                    : '#12141C'
                }
                active={currentTab === 'Project Pool'}
              >
                Project Pool
              </NavLI> */}
              <NavLink to="/stake" rel="noreferrer">
                <NavLI
                  onClick={() => setCurrentTab('Stake/Farm')}
                  borderImage={
                    currentTab === 'Stake/Farm'
                      ? 'linear-gradient(118.21deg, #ec4781, #6038f7 130.15%)'
                      : '#12141C'
                  }
                  active={currentTab === 'Stake/Farm'}
                >
                  Stake/Farm
                </NavLI>
              </NavLink>
              <NavLI
                onClick={() => setCurrentTab('Dashboard')}
                borderImage={
                  currentTab === 'Dashboard'
                    ? 'linear-gradient(118.21deg, #ec4781, #6038f7 130.15%)'
                    : '#12141C'
                }
                active={currentTab === 'Dashboard'}
              >
                Dashboard
              </NavLI>
            </NavUL>
          </NavLinks>
          <NavConnectInfo>
            {isConnected ? (
              <ButtonList>
                <ChangeAddressButton />
                <ChangeNetworkButton />
              </ButtonList>
            ) : (
              <ButtonList>
                <NavBtnDivConnect onClick={() => connectWallet()}>
                  <NavButton>
                    <img src={Wallet} alt="Wallet" />
                    <NavP>Connect Wallet</NavP>
                  </NavButton>
                </NavBtnDivConnect>
              </ButtonList>
            )}
          </NavConnectInfo>
        </NavContainerModal>
      </ModalStyles>
    </div>
  );
}

export default MobileNavbarModal;
