import styled from 'styled-components';

export const Container = styled.div`
  width: auto;
  margin: 50px;
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    margin: 50px auto;
  }
`;
export const Wrapperdetail = styled.div`
  display: flex;
  width: 100%;

  margin: 20px auto;
  margin-top: 59px;
  max-width: 31rem;
  justify-content: space-between;
  align-items: center;
  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['300']
      : props.theme.colors.grey['100']};
  padding: 30px 50px;
  border-radius: 12px;
  border: 1px solid
    ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black['200']
        : props.theme.colors.grey['300']};
  box-sizing: border-box;

  /* For Tablet */
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    width: 70%;
  }

  /* For Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 90%;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.2rem;
  }
`;
export const TotalProject = styled.div`
  display: flex;
  align-items: center;
`;
export const Icon = styled.div``;
export const Text = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: left;

  margin-left: 10px;
  box-sizing: border-box;
  padding-left: 0px;
  position: relative;
`;
export const Number = styled.span`
  color: #fcb778;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 145%;
  width: 100%;
`;
export const Data = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 145%;
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['300']
      : props.theme.colors.black['300']};
`;
export const NumberParticipants = styled.span`
  color: #5e50ff;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 145%;
  width: 100%;
`;
export const DataParticipants = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 145%;
  color: #b8c1da;
`;
export const NumberRaisedCapital = styled.span`
  color: #30d89b;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 145%;
`;
export const DataRaisedCapital = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 145%;
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['300']
      : props.theme.colors.black['300']};
  width: 93%;
`;

export const UniqueParticipants = styled.div`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 145%;
`;
export const RaisedCapital = styled.div`
  display: flex;
  align-items: center;
`;
export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 50px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 41px;

  /* For Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    padding: 1rem;
    flex-direction: column;
    box-sizing: border-box;
  }
`;
export const HeadPoolList = styled.div`
  margin-right: auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  /* justify-content: center; */

  /* For Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    flex-direction: column;
    align-items: center !important;
    justify-content: center;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    flex-direction: column;
    align-items: baseline;
  }
`;
export const Heading = styled.h3`
  font-weight: 600;
  /* font-size: 28px; */
  font-size: 2em;
  line-height: 135%;
  margin: 20px;
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['100']
      : props.theme.colors.black['400']};

  /* For Tablet */
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    margin: 0;

    font-size: 2.3em;
  }

  /* For Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-right: 0;
    /* font-size: 2em; */
  }
`;
export const Span = styled.span`
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['100']
      : props.theme.colors.grey['500']};
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;

  padding-top: 1px;
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    font-size: 0.9em;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
  }
`;
export const Inputfilter = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  max-width: 572px;

  /* For Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    /* flex-direction: row-reverse; */
    margin-top: 1.5rem;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    width: 100%;
    height: 100%;
  }
`;

export const SearchIcon = styled.img`
  width: auto;
  position: absolute;
  top: 20%;
  left: 1%;
  height: auto;
  color: white;
  padding: 2px;
  border-radius: 5px;
  cursor: pointer;
`;
export const Input = styled.input`
  width: 100%;
  height: 100%;
  outline: 1px grey;
  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['400']
      : props.theme.colors.grey['200']};
  border: 1px solid
    ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black['200']
        : props.theme.colors.grey['300']};
  padding: 15px 30px;

  border-radius: 6px;
  padding: 10px 37px;
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['100']
      : props.theme.colors.grey['500']};
  font-size: 14px;
  &::placeholder {
    color: ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black['100']
        : props.theme.colors.grey['500']};
    font-size: 12px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    outline: 1px grey;
    background-color: ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black['400']
        : props.theme.colors.grey['200']};

    padding: 12px 40px;

    border-radius: 6px;
    &::placeholder {
      color: #777d92;
      font-size: 13px;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    width: 247px;
    height: 100%;
    padding: 10px 40px;
  }
`;

export const Filter = styled.div`
  height: 100%;
  display: flex;
  padding: 5px 5px 5px 0px;
  align-items: center;
  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['500']
      : props.theme.colors.grey['200']};
  border-radius: 6px;

  margin-left: 2%;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: max-content;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    height: 100%;
    display: flex;
    padding: 5px 5px 5px 0;
    align-items: center;
    background: ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black['500']
        : props.theme.colors.grey['200']};
    border-radius: 6px;

    margin-left: 2%;
  }
`;
export const Li = styled.span`
  font-size: 12px;
  margin-left: 6px;
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['200']
      : props.theme.colors.grey['500']};

  color: ${(props) =>
    props.active &&
    props.theme.mode === 'dark' &&
    props.theme.colors.grey['200']};

  background: ${(props) =>
    props.active &&
    props.theme.mode === 'dark' &&
    props.theme.colors.black['200']};

  // Active Light

  color: ${(props) =>
    props.active &&
    props.theme.mode === 'light' &&
    props.theme.colors.grey['200']};

  background: ${(props) =>
    props.active &&
    props.theme.mode === 'light' &&
    props.theme.colors.black['200']};

  cursor: pointer;
  padding: 9px 16px;
  border-radius: 6px;

  /* For Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 1.5em;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    font-size: 1.2em;
  }
`;
export const SectionHead = styled.h1`
  font-style: normal;
  font-weight: 600;
  /* font-size: 40px; */
  font-size: 2em;
  line-height: 110%;
  /* identical to box height, or 44px */

  letter-spacing: -1px;

  /* Light/Light_200 */

  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['100']
      : props.theme.colors.black['400']};

  /* opacity: 0.9; */
  text-align: center;
`;
export const Tagline = styled.h2`
  color: #777d92;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  width: 51%;
  margin: 27px auto;

  /* For Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    padding: 1rem;
  }
`;

export const PoolCardList = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-top: -2.5rem;
  }
`;
export const BottomSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4%;

  /* For Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    flex-direction: column;
    margin-top: 1.5rem;
  }
`;
export const ProjectListed = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 145%;
  color: #777d92;
  margin-right: 5%;

  /* For Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: auto;
  }
`;
export const LoadMore = styled.button`
  background: #5e50ff;
  border-radius: 8px;
  padding: 18px 56px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  outline: none;
  border: none;
  /* or 22px */

  text-align: center;

  color: #ffffff;

  /* For Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-top: 1rem;
  }
`;
export const SearchIconDiv = styled.div`
  border: 1px solid
    ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black['200']
        : props.theme.colors.grey['200']};
  padding: 3px;
  border-radius: 5px;
  z-index: 100;
  margin-left: 3%;
`;
export const SearchIconMobile = styled.img`
  margin-bottom: 2%;
`;
export const InputfilterMobile = styled.div`
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  align-content: space-between;
  justify-content: center;
  margin-top: 2.5em;
`;
