/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  isConnectWalletModalVisible: false,
  isDisconnectWalletVisible: false,
  isSwitchNetworkModalVisible: false,
  isMobileNavbarModalModalVisible: false,
  isPublicModallVisible: false,
  isCommunityModallVisible: false,
  isJoinPoolModalVisible: false,
  isJoinPoolFcfsModalVisible: false,
  isThankYouModalVisible: false,
  isStakeModalVisible: false,
  rewardPercent: '',
  period: '',
  isLp: false,
  stake2: false,
  isUnStakeModalVisible: false,
  stakedInPool: 0,
  rewardsEarned: 0,
};

export const modalSlice = createSlice({
  name: 'theme',
  initialState,

  reducers: {
    toggleConnectWallet: (state) => {
      state.isConnectWalletModalVisible = !state.isConnectWalletModalVisible;
    },

    toggleMobileNavbar: (state) => {
      state.isMobileNavbarModalModalVisible =
        !state.isMobileNavbarModalModalVisible;
    },

    toggleDisconnectWallet: (state) => {
      state.isDisconnectWalletVisible = !state.isDisconnectWalletVisible;
    },

    toggleSwitchNetwork: (state) => {
      state.isSwitchNetworkModalVisible = !state.isSwitchNetworkModalVisible;
    },
    togglePublicModal: (state) => {
      state.isPublicModallVisible = !state.isPublicModallVisible;
    },
    toggleCommunityModal: (state) => {
      state.isCommunityModallVisible = !state.isCommunityModallVisible;
    },
    toggleJoinPoolModal: (state) => {
      state.isJoinPoolModalVisible = !state.isJoinPoolModalVisible;
    },
    toggleJoinPoolFcfsModal: (state) => {
      state.isJoinPoolFcfsModalVisible = !state.isJoinPoolFcfsModalVisible;
    },
    toggleThankYouModal: (state) => {
      state.isThankYouModalVisible = !state.isThankYouModalVisible;
    },
    toggleStakeModal: (state, action) => {
      state.isStakeModalVisible = !state.isStakeModalVisible;
      state.rewardPercent = action.payload ? action.payload.apr : '';
      state.period = action.payload ? action.payload.period : '';
      state.stake2 = action.payload ? action.payload.stake2 : false;
      state.isLp = action.payload ? action.payload.isLp : false;
    },
    toggleUnStakeModal: (state, action) => {
      state.isUnStakeModalVisible = !state.isUnStakeModalVisible;
      state.rewardPercent = action.payload ? action.payload.apr : '';
      state.period = action.payload ? action.payload.period : '';
      state.isLp = action.payload ? action.payload.isLp : false;
      state.stake2 = action.payload ? action.payload.stake2 : false;
      state.stakedInPool = action.payload ? action.payload.stakedInPool : 0;
      state.rewardsEarned = action.payload ? action.payload.earnedRewards : 0;
    },
  },
});

export const {
  toggleConnectWallet,
  toggleMobileNavbar,
  toggleDisconnectWallet,
  toggleSwitchNetwork,
  togglePublicModal,
  toggleCommunityModal,
  toggleJoinPoolModal,
  toggleJoinPoolFcfsModal,
  toggleThankYouModal,
  toggleStakeModal,
  toggleUnStakeModal,
} = modalSlice.actions;

export default modalSlice.reducer;
