import styled from 'styled-components';

export const FooterDivFull = styled.div`
  padding: 15px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 15px;
  }
`;

export const FooterMainDiv = styled.div`
  /* background-color: #191c27; */
  background-color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['300']
      : props.theme.colors.grey['100']};
  border-radius: 18px;
`;
export const Message = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  padding-top: 16px;
  text-align: start;
  /* color: #ffffff; */
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['100']
      : props.theme.colors.black['400']};
  opacity: 0.7;
`;

export const FooterDiv = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 100px 40px 65px 10px;
  /* border-bottom: 1px solid #31374d; */
  border-bottom: 1px solid
    ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black[200]
        : props.theme.colors.grey[200]};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 0;
    flex-direction: column;
  }
`;

export const FooterInputDivColumn = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: block;
    padding-top: 30px;
  }
  @media (min-width: ${(props) =>
      props.theme.breakpoints.tablet}) and (max-width: ${(props) =>
      props.theme.breakpoints.desktop}) {
    margin-left: 2rem;
  }
`;

export const FooterP1 = styled.p`
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  /* color: #ffffff; */
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['100']
      : props.theme.colors.black['300']};
  opacity: 0.9;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 22px;
    padding: 0;
    margin: 0;
    color: #ffffff;
    align-items: center;
    color: ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.grey['100']
        : props.theme.colors.black['400']};
  }
  margin: 0;
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    font-size: 20px;
  }
`;

export const FooterP2 = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  padding-top: 16px;
  text-align: start;
  /* color: #ffffff; */
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['100']
      : props.theme.colors.black['400']};
  opacity: 0.7;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    font-size: 12px;
  }
`;

export const FooterDivTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  /* gap: 60px; */
  gap: 25px;
`;

export const FooterDivContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px;
  text-align: left;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  /* color: #e7eaf3; */
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['200']
      : props.theme.colors.black['400']};

  @media (min-width: ${(props) =>
      props.theme.breakpoints.tablet}) and (max-width: ${(props) =>
      props.theme.breakpoints.desktop}) {
    padding: 13px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 25px 10px 25px 10px;
  }
`;

export const FooterHiddenForSmallScreen = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;

export const FooterP3 = styled.p`
  padding: 10px;
  margin: 0;
`;

export const FooterDivInsider = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  /* color: #777d92; */
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['100']
      : props.theme.colors.grey['500']};

  cursor: pointer;
  /*
  &:hover {
    color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['300']
      : props.theme.colors.black['200']};
  } */

  // Active Dark
  color: ${(props) =>
    props.active &&
    props.theme.mode === 'dark' &&
    props.theme.colors.grey['300']};

  // Active Light
  color: ${(props) =>
    props.active &&
    props.theme.mode === 'light' &&
    props.theme.colors.black['400']};

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: 12px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 12px 10px 12px 10px;
  }
`;

export const FooterAnchor = styled.a`
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['100']
      : props.theme.colors.grey['500']};

  cursor: pointer;

  &:hover {
    color: ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black['100']
        : props.theme.colors.grey['500']};
  }

  // Active Dark
  color: ${(props) =>
    props.active &&
    props.theme.mode === 'dark' &&
    props.theme.colors.grey['300']};

  // Active Light
  color: ${(props) =>
    props.active &&
    props.theme.mode === 'light' &&
    props.theme.colors.black['400']};

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: 11px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 15px;
  }
`;

export const FooterDivInput = styled.div`
  display: flex;
  width: 100%;
  margin-top: 6%;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    width: max-content;
  }
`;

export const FooterInput = styled.input`
  outline: none;
  background: #12141c;
  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['400']
      : props.theme.colors.grey['200']};
  /* color: #777d92; */
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['100']
      : props.theme.colors.grey['500']};
  flex: 1;
  padding: 27px 20px;
  font-size: 1.1em;
  font-weight: 400;
  -webkit-border-top-left-radius: 12px;
  -webkit-border-bottom-left-radius: 12px;
  -moz-border-radius-topleft: 12px;
  -moz-border-radius-bottomleft: 12px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  box-shadow: none;
  border: none;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 2.1em;
    padding: 20px 20px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    /* margin: 0 0 0 5px; */
    width: inherit;
    /* padding: 25px 10px 25px 15px; */
    font-size: 1rem;
  }
`;
export const FooterInputBtn = styled.button`
  padding-right: 10px;

  /* background-color: #12141c; */
  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['400']
      : props.theme.colors.grey['200']};

  -webkit-border-top-right-radius: 12px;
  -webkit-border-bottom-right-radius: 12px;
  -moz-border-radius-topright: 12px;
  -moz-border-radius-bottomright: 12px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  border: none;
  cursor: pointer;
  cursor: hand;
`;
export const FooterInputBtnspan = styled.span`
  margin-left: 50px;
  padding: 18px 31px;
  font-size: 1.2em;
  font-weight: 500;
  letter-spacing: -0.02em;
  color: #fff;
  background: linear-gradient(118.21deg, #ec4781 22.9%, #6038f7 130.15%);
  border-radius: 12px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 13px 5px;
    font-size: 1.8em;
    margin-left: auto;
    border-radius: 12px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    width: max-content;
    /* padding: 18px 5px; */
    font-size: 1rem;
    /* margin: 0px; */

    padding: 13px 5px;
    /* font-size: 1.8em; */
    margin-left: auto;
    border-radius: 12px;
  }
`;

export const FooterArrowImage = styled.img`
  margin-left: 10px;
  display: initial;
  max-width: unset;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 15px;
    display: initial;
    max-width: unset;
  }
`;

export const FooterDivMedium = styled.div`
  display: none;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #31374d;
  }
`;
export const FooterDivMediumDiv = styled.div`
  padding: 15px;
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['100']
      : props.theme.colors.grey['500']};
  cursor: pointer;
  /*
  &:hover {
    color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['300']
      : props.theme.colors.black['200']};
  } */

  // Active Dark
  color: ${(props) =>
    props.active &&
    props.theme.mode === 'dark' &&
    props.theme.colors.grey['300']};

  // Active Light
  color: ${(props) =>
    props.active &&
    props.theme.mode === 'light' &&
    props.theme.colors.black['400']};
`;
export const FooterDivMediumDivText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  /* color: #777d92; */
  margin: 0;
`;

export const FooterDivBottom = styled.div`
  /* background-color: #191c27; */
  background-color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['300']
      : props.theme.colors.grey['100']};
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0px 25px;
  border-radius: 18px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0px 20px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0px 20px;
  }
`;

export const FooterWrapperDiv = styled.div`
  /* padding: 0px 21px 0px 5px; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 100px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-right: 0;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    margin-right: 20px;
  }
`;

export const FooterImageContainer = styled.div`
  display: block;
  width: 170px;
  /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
  border-radius: 5px;
  padding-right: 5px;
  padding-left: 10px;
  margin-left: 100px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 0 auto 0 auto;
    margin: 0 0 0 auto;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    margin-left: 10px;
    margin-bottom: 5px;
  }
`;

export const FooterAsvaText = styled.h1`
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  color: #777d92;
  margin-bottom: 0px;
  padding-top: 1px;
`;
export const FooterP4 = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #777d92;
  display: contents;
`;

export const FooterImgDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0.15;
`;

export const FooterImgDivIcon = styled.div`
  display: flex;
  /* flex: 0.5; */
  margin-right: 20px;
`;
