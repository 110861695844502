import styled from 'styled-components';

export const Pools = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin: 25px 50px;
  width: auto;
  border-bottom: 2px solid
    ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black['300']
        : props.theme.colors.grey['200']};

  /* For Tablet */
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    margin: 0;
    /* padding: 10px; */
  }

  /* For Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    flex-direction: column;
    margin: auto;
    padding: 10px;
  }
`;
export const PoolLeft = styled.div`
  display: flex;

  /* For Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    flex-direction: column;
  }
`;
export const PoolRight = styled.div`
  display: flex;

  /* For Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 1rem auto;
  }
`;
export const PoolHeading = styled.p`
  /* font-size: 28px; */
  font-size: 1.8em;
  line-height: 35px;
  margin: 0 15px;
  display: flex;
  align-items: center;
  letter-spacing: -1px;

  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['200']
      : props.theme.colors.black['400']};
  /* For Tablet */
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    font-size: 1.5em;
  }

  /* For Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: auto;
    /* font-size: 1.5em; */
    font-size: 1.3rem;
  }
`;
export const PoolCount = styled.p`
  margin: 0;
  line-height: 30px;
  color: #777d92;
  display: flex;
  justify-content: center;
  align-items: center;

  /* For Tablet */
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    font-size: 0.8em;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 0.7rem;
  }
`;
export const PoolButton = styled.ul`
  /* background-color: #090a0e; */
  margin: 0;
  padding: 2px;
  display: flex;
  border-radius: 3px;
  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['500']
      : props.theme.colors.grey['200']};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    border-radius: 6px;
  }
`;

export const PoolsWrapper = styled.div`
  margin: auto 2rem;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 0;
  }
`;

export const PoolLi = styled.li`
  background: transparent;
  border-radius: 3px;
  padding: 10px 16px;
  margin: 3px;
  list-style: none;
  cursor: pointer;
  border-radius: 6px;

  /* For Tablet */
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    font-size: 1.2em;
  }

  /* For Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 0.8rem;
    padding: 3px 10px;
  }

  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['100']
      : props.theme.colors.grey['500']};

  // Active Dark

  color: ${(props) =>
    props.active &&
    props.theme.mode === 'dark' &&
    props.theme.colors.grey['200']};

  background: ${(props) =>
    props.active &&
    props.theme.mode === 'dark' &&
    props.theme.colors.black['200']};

  // Active Light

  color: ${(props) =>
    props.active &&
    props.theme.mode === 'light' &&
    props.theme.colors.grey['200']};

  background: ${(props) =>
    props.active &&
    props.theme.mode === 'light' &&
    props.theme.colors.black['200']};

  //////

  /* background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['200']
      : props.theme.colors.grey['500']};

  background: ${(props) =>
    props.active &&
    props.theme.mode === 'light' &&
    props.theme.colors.black['200']}; */

  /* background: ${(props) =>
    props.themeType === 'dark'
      ? props.theme.colors.black['200']
      : 'transparent'}; */

  /* background: ${(props) =>
    props.themeType === 'light'
      ? props.theme.colors.grey['200']
      : 'transparent'};

  background: ${(props) =>
    props.active && props.themeType === 'dark'
      ? props.theme.colors.black['200']
      : 'transparent'};

  background: ${(props) =>
    props.active && props.themeType === 'light'
      ? props.theme.colors.grey['200']
      : 'transparent'}; */
`;
export const CalenderBtn = styled.div`
  background-color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.secondary['400']
      : props.theme.colors.grey['500']};
  color: ${(props) => props.theme.colors.grey['200']};
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin: 3px 10px;
  padding: 5px 12px;
  cursor: pointer;
  position: relative;

  /* For Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-right: 0;
  }

  &:before {
    content: '';
    display: none;
    width: 120px;

    color: ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black['100']
        : props.theme.colors.grey['500']};
    background: ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black['300']
        : props.theme.colors.grey['200']};
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 1000;

    top: 120%;
    left: 50%;
    margin-left: -60px;
  }
  &:hover:before {
    content: 'Coming Soon';
    display: inline;
  }
`;

export const CalenderImg = styled.img`
  width: 15px;
  margin-right: 10px;

  /* For Tablet */
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    margin: 0;
  }

  /* For Mobile */
  /* @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: auto;
  } */
`;
