import styled from 'styled-components';

export const CountDownContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

export const CountdownBlock = styled.div`
  /* margin: 0% 5%; */
  padding: 2% 0%;
  width: 30px;
  text-align: center;
  /* background: #191c27; */
  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['300']
      : props.theme.colors.grey['200']};
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['200']
      : props.theme.colors.black['400']};
  border: ${(props) =>
    props.theme.mode === 'dark'
      ? `1px solid ${props.theme.colors.black['200']}`
      : `1px solid ${props.theme.colors.grey['300']}`};
  /* 1px solid #31374d; */
  box-sizing: border-box;
  border-radius: 4px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 16px;
  }
`;

export const Seprator = styled.span`
  /* margin: 15px; */
  margin: 0.5rem;
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['200']
      : props.theme.colors.black['400']};
  font-weight: 500;
  font-size: 1em;

  /* For Tablet */
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: 0.5rem;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 16px;
  }
`;

export const CompletedState = styled.span`
  margin: 20px 0;
  font-size: 20px;
  color: ${(props) => props.theme.text};
`;
