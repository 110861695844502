import { Spin } from 'antd';
import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: '#FF0074' }} spin />
);

const LoaderBackground = styled.div`
  background: ${(props) => props.theme.background};
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function FullScreenLoader() {
  return (
    <LoaderBackground>
      <Spin indicator={antIcon} />
    </LoaderBackground>
  );
}

export default FullScreenLoader;
