import { Skeleton } from 'antd';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding-top: 30px;
`;

const Image = styled.div`
  text-align: center;
`;

const Title = styled.div`
  margin: 0 auto;
  width: 60%;

  .ant-skeleton-input {
    margin: 0.5rem 0;
    height: 25px;
    border-radius: 4px;
  }
`;

const Allocation = styled.div`
  margin: 0 auto;
  width: 50%;

  .ant-skeleton-input {
    margin: 0.2rem 0;
    height: 20px;
    border-radius: 4px;
  }
`;

const TokenContainer = styled.div`
  margin: 1.5rem 1.5rem 0 1.5rem;

  .ant-skeleton-input {
    margin: 0.2rem 0;
    height: 25px;
    border-radius: 4px;
  }
`;

const InputContainer = styled.div`
  margin: 0 1.5rem;

  .ant-skeleton-input {
    margin: 0.5rem 0;
    height: 40px;
    border-radius: 4px;
  }
`;

const Content = styled.div`
  margin: 0 1.5rem;

  .ant-skeleton-input {
    margin: 0.5rem 0;
    height: 80px;
    border-radius: 4px;
  }
`;

function JoinPoolModalSkeleton() {
  return (
    <Container>
      <Image>
        <Skeleton.Avatar active block size="large" />
      </Image>
      <Title>
        <Skeleton.Input active block />
      </Title>
      <Allocation>
        <Skeleton.Input active block />
      </Allocation>
      <TokenContainer>
        <Skeleton.Input active block />
      </TokenContainer>
      <InputContainer>
        <Skeleton.Input active block />
      </InputContainer>
      <Content>
        <Skeleton.Input active block />
      </Content>
      <InputContainer>
        <Skeleton.Input active block />
      </InputContainer>
    </Container>
  );
}

export default JoinPoolModalSkeleton;
