/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import {
  toggleJoinPoolFcfsModal,
  toggleThankYouModal,
} from '../../../store/slices/modal';

import Close from '../../../assets/Close.svg';
import {
  Allocation,
  AmountContainer,
  AmountInput,
  CloseIcon,
  Container,
  DetailsContainer,
  Flex,
  LogoBackground,
  MaxButton,
  ModalStyles,
  ProjectLogo,
  ProjectName,
  RecieveContainer,
  SubmitButton,
  Text,
  TokenLogo,
  TokenName,
  WalletBalance,
  WalletBalanceContainer,
} from './JoinPoolModalStyles';
import { Web3Class } from '../../../Web3/web3Class';
import { calculatePriceOfTokensRoundDown } from '../../../utils/helpers';
import { buyPoolToken, getCurrencyBalance } from '../../../Web3/web3';
import { convertNegetiveNumberToNumber } from '../../../helpers/convertDecimals';
import JoinPoolModalSkeleton from './JoinPoolModalSkeleton';
import TokenImages from '../../../config/tokenImg';

function JoinPoolModal() {
  const { isJoinPoolFcfsModalVisible } = useSelector((state) => state.modal);
  const { selectedPoolV1 } = useSelector((state) => state.pools);
  const userData = useSelector((state) => state.user);
  const projectContract = useSelector((state) => state.user.projectContract);
  const isSocketConnected = useSelector((state) => state.socket.isConnected);
  const socket = useSelector((state) => state.socket.data);

  const [loading, setLoading] = useState(false);
  const [buyPoolLoading, setBuyPoolLoading] = useState(false);
  const [amount, setAmount] = useState('');
  const [maxAmount, setMaxAmount] = useState('loading...');
  const [userMaxAmountBuy, setUserMaxAmountBuy] = useState('loading...');
  const [allocationLeft, setAllocationLeft] = useState('loading...');
  const [userCurrencyBalance, setUserCurrencyBalance] = useState('loading');
  const [userRecieveValue, setUserRecieveValue] = useState(0);

  const dispatch = useDispatch();

  const handleCancel = () => {
    dispatch(toggleJoinPoolFcfsModal());
  };

  const fetchUserCurrencyBalance = async () => {
    // setLoading(true);
    let balance = await getCurrencyBalance(
      selectedPoolV1.abi.currencyABI,
      selectedPoolV1.smartContractAddress.currency,
      selectedPoolV1.currDecimal
    );
    balance = Math.floor(balance);
    setUserCurrencyBalance(balance);
    // setLoading(false);
  };

  useEffect(() => {
    fetchUserCurrencyBalance();
  }, [userData]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const web3Instance = new Web3Class(selectedPoolV1);

      let tierMax =
        await web3Instance.calculateTotalAvailableTokensInAllTiers();

      let tierMax1 = calculatePriceOfTokensRoundDown(
        tierMax,
        selectedPoolV1.tokenDetails.tokenPrice
      );

      tierMax1 = convertNegetiveNumberToNumber(tierMax1);

      setUserMaxAmountBuy(tierMax1);

      tierMax = calculatePriceOfTokensRoundDown(
        tierMax,
        selectedPoolV1.tokenDetails.tokenPrice
      );

      tierMax = convertNegetiveNumberToNumber(tierMax1);

      const userPurchased = await web3Instance.checkSales();

      userPurchased.amount = calculatePriceOfTokensRoundDown(
        userPurchased.amount,
        selectedPoolV1.tokenDetails.tokenPrice
      );

      setMaxAmount(tierMax);

      //   let allocationLefttokenValue = tierMax - userPurchased.amount;

      setAllocationLeft(tierMax);
      setLoading(false);
    };

    if (isJoinPoolFcfsModalVisible) {
      fetchData();
    }
  }, [isJoinPoolFcfsModalVisible]);

  const setMaxUserCanInvest = () => {
    setAmount(parseInt(userMaxAmountBuy, 10));
    setUserRecieveValue(
      parseFloat(
        parseFloat(userMaxAmountBuy) /
          parseFloat(selectedPoolV1.tokenDetails.tokenPrice)
      ).toFixed(2)
    );
  };

  useEffect(() => {
    if (amount === '') {
      setUserRecieveValue(0);
    } else {
      setUserRecieveValue(
        parseFloat(amount) / parseFloat(selectedPoolV1.tokenDetails.tokenPrice)
      );
    }
  }, [amount, selectedPoolV1.tokenDetails.tokenPrice]);

  useEffect(() => {
    if (isSocketConnected) {
      socket.on('project-progress', (projectData) => {
        // if (projectData.projectId === selectedPoolV1._id)
        //   setTierPercentage(projectData.overallPercentage);

        let { availableTokens } = projectData;

        availableTokens = calculatePriceOfTokensRoundDown(
          availableTokens,
          selectedPoolV1.tokenDetails.tokenPrice
        );
        setAllocationLeft(availableTokens);
        setMaxAmount(availableTokens);
      });
    }
  }, [isSocketConnected, socket, selectedPoolV1]);

  const submitJoinPool = async (e) => {
    e.preventDefault();
    if (isNaN(amount)) {
      toast.error('Please enter a valid number');
      return;
    }

    // if (amount.toString().includes(".")) {
    //   toast.info("Please enter a whole number");
    //   return;
    // }

    if (parseFloat(amount) <= 0) {
      toast.error('Amount cannot be 0 or less than 0');
      return;
    }

    if (parseFloat(amount) > parseFloat(maxAmount)) {
      toast.error('Amount cannot be more than the Allocation left');
    }

    // else if (parseFloat(amount) > parseFloat(maxAmount)) {
    //   toast.info("Amount cannot be more than the max amount");
    // }

    if (parseFloat(amount) > userCurrencyBalance) {
      toast.error('You do not have enough tokens to join the pool');
    } else {
      setBuyPoolLoading(true);

      let userEnteredAmount = 0;

      // userEnteredAmount = parseFloat(amount) - 0.01;
      userEnteredAmount = parseFloat(amount);

      try {
        await buyPoolToken(
          userEnteredAmount,
          selectedPoolV1.projectContractAddress,
          selectedPoolV1.tokenDetails.tokenPrice,
          selectedPoolV1.abi.currencyABI,
          selectedPoolV1.smartContractAddress.currency,
          selectedPoolV1.currDecimal
        );
        toast.success('Tokens Successfully Bought');

        if (isSocketConnected) {
          // console.log("first");
          socket.emit('project-progress', {
            projectId: selectedPoolV1._id,
            tier: projectContract.tier,
          });
          // console.log("second");
          // socket.emit("all-project-progress");
        }
        // window.location.reload();
        dispatch(toggleThankYouModal());
      } catch (error) {
        console.log(error);
        toast.error('Something Went Wrong');
      }
      handleCancel();
      setBuyPoolLoading(false);
    }
  };

  return (
    <ModalStyles
      footer={null}
      visible={isJoinPoolFcfsModalVisible}
      onCancel={handleCancel}
      width={400}
    >
      <CloseIcon src={Close} onClick={handleCancel} />
      {loading ? (
        <JoinPoolModalSkeleton />
      ) : (
        <Container>
          <LogoBackground>
            <ProjectLogo
              src={selectedPoolV1.projectLogoUrl}
              alt={selectedPoolV1.projectName}
            />
          </LogoBackground>
          <ProjectName>Join {selectedPoolV1.projectName} pool</ProjectName>
          <Allocation>
            Allocation{' '}
            <span>
              {`$${allocationLeft}`} Left out of {`$${maxAmount}`}
            </span>
          </Allocation>

          <DetailsContainer>
            <WalletBalanceContainer>
              <Flex>
                <TokenLogo
                  src={TokenImages[selectedPoolV1.supportedToken]}
                  alt="BUSD"
                />
                <TokenName>{selectedPoolV1.supportedToken}</TokenName>
              </Flex>

              <WalletBalance>
                Wallet Bal:
                <span>{`$${userCurrencyBalance} ${selectedPoolV1.supportedToken}`}</span>
              </WalletBalance>
            </WalletBalanceContainer>

            <AmountContainer>
              <AmountInput
                type="text"
                placeholder="Enter Amount"
                onChange={(e) => {
                  if (e.target.value === '') {
                    setUserRecieveValue(0);
                    setAmount('');
                  } else {
                    setAmount(e.target.value);
                  }
                }}
                value={amount}
              />
              <MaxButton onClick={setMaxUserCanInvest}>Max</MaxButton>
            </AmountContainer>

            <RecieveContainer>
              <Flex marginBottom="9px">
                <Text>You will Receive</Text>
                <Text>
                  {parseFloat(userRecieveValue).toFixed(2)}{' '}
                  {selectedPoolV1.tokenDetails.tokenSymbol}
                </Text>
              </Flex>

              <Flex>
                <Text>Conversion Rate</Text>
                <Text>{selectedPoolV1.tokenDetails.rate}</Text>
              </Flex>
            </RecieveContainer>

            <SubmitButton
              onClick={submitJoinPool}
              disabled={
                buyPoolLoading ||
                loading ||
                amount <= 0 ||
                amount > allocationLeft
              }
            >
              {buyPoolLoading ? 'Processing...' : 'Submit'}
            </SubmitButton>
          </DetailsContainer>
        </Container>
      )}
    </ModalStyles>
  );
}

export default JoinPoolModal;
