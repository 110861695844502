import React from 'react';
import { useSelector } from 'react-redux';
import { Skeleton } from 'antd';
// import moment from 'moment';
import styled from 'styled-components';
// import Countdown from 'react-countdown';
// import CustomCountdown from '../../CustomCountdown/CustomCountdown';
import ProjectTimer from '../../ProjectTimer/ProjectTimer';

const TimerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 20px 0 24px 0;
  }
`;

const RowText = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: 400;
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['200']
      : props.theme.colors.black['400']};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 14px;
  }
`;

// const Text = styled.p`
//   margin: 0;
//   padding: 4px 6px;

//   background: ${(props) =>
//     props.theme.mode === 'dark'
//       ? props.theme.colors.black['300']
//       : props.theme.colors.grey['200']};
//   border: ${(props) =>
//     props.theme.mode === 'dark'
//       ? `1px solid ${props.theme.colors.black['200']}`
//       : `1px solid ${props.theme.colors.grey['300']}`};

//   border-radius: 4px;
//   color: ${(props) =>
//     props.theme.mode === 'dark'
//       ? props.theme.colors.grey['200']
//       : props.theme.colors.black['400']};
// `;

function FooterText({ type, data, percentage }) {
  if (type === 'active') {
    return percentage < 100 ? (
      <RowText>Sale ends in</RowText>
    ) : (
      <RowText>Sale ended</RowText>
    );
  }

  if (type === 'upcoming') {
    if (data.isIDOLauched) return <RowText>Sale starts in</RowText>;
    return <RowText>Sale starts date</RowText>;
  }

  return <RowText>Sale starts in</RowText>;
}

// function FooterTextValue({ type, data }) {
//   const { timeStamp } = useSelector((state) => state.time);
//   const [whitelistState, setWhitelistState] = useState({
//     showTimer: false,
//     text: '',
//     timer: '',
//   });

//   const calculateTimeStamp = () => {
//     if (data.isTBA) {
//       setWhitelistState({
//         showTimer: false,
//         text: 'TBA',
//         timer: '',
//       });

//       return;
//     }

//     if (data.isIDOLauched === false) {
//       const date = moment(data.idoDates.whitelistStartDate).format(
//         'Do MMM YYYY | h:mm a UTC'
//       );
//       setWhitelistState({
//         showTimer: false,
//         text: date,
//         timer: '',
//       });

//       return;
//     }

//     const timestampFromServer = new Date(timeStamp).toISOString();

//     if (timestampFromServer < data.idoDates.whitelistStartDate) {
//       setWhitelistState({
//         showTimer: true,
//         text: '',
//         timer: data.idoDates.whitelistStartDate,
//       });
//     }
//   };

//   useEffect(() => {
//     if (type === 'upcoming') {
//       calculateTimeStamp();
//     }
//   }, []);

//   if (type === 'active') {
//     return (
//       <Countdown
//         date={data.idoDates.roundTwoEndDate}
//         renderer={CustomCountdown}
//       />
//     );
//   }

//   if (type === 'upcoming') {
//     return whitelistState.showTimer ? (
//       <Countdown date={whitelistState.timer} renderer={CustomCountdown} />
//     ) : (
//       <Text>{whitelistState.text}</Text>
//     );
//   }

//   return <RowText>Sale ends in</RowText>;
// }

function VerticalCardFooter({ type, data, percentage }) {
  const { loading } = useSelector((state) => state.time);

  return loading ? (
    <Skeleton.Input active />
  ) : (
    <TimerContainer>
      <FooterText type={type} data={data} percentage={percentage} />
      <ProjectTimer type={type} data={data} percentage={percentage} />
    </TimerContainer>
  );
}

export default VerticalCardFooter;
