/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const stakeSlice = createSlice({
  name: 'stake',
  initialState: {
    pool1: {
      stakedAmount: '',
      maturityDate: '',
    },
    pool2: {
      stakedAmount: '',
      maturityDate: '',
    },
    pool3: {
      stakedAmount: '',
      maturityDate: '',
    },
    pool4: {
      stakedAmount: '',
      maturityDate: '',
    },
    pool5: {
      stakedAmount: '',
      maturityDate: '',
    },
    pool6: {
      stakedAmount: '',
      maturityDate: '',
    },
    pool7: {
      stakedAmount: '',
      maturityDate: '',
    },
    pool8: {
      stakedAmount: '',
      maturityDate: '',
    },
    pool9: {
      stakedAmount: '',
      maturityDate: '',
    },
    lp: {
      stakedAmount: '',
      maturityDate: '',
    },
  },
  reducers: {
    storeStakeInfo: (state, action) => {
      if (action.payload.pool === 1) {
        state.pool1 = {
          stakedAmount: action.payload.stakedAmount,
          maturityDate: action.payload.maturityDate,
        };
      } else if (action.payload.pool === 2) {
        state.pool2 = {
          stakedAmount: action.payload.stakedAmount,
          maturityDate: action.payload.maturityDate,
        };
      } else if (action.payload.pool === 3) {
        state.pool3 = {
          stakedAmount: action.payload.stakedAmount,
          maturityDate: action.payload.maturityDate,
        };
      } else if (action.payload.pool === 4) {
        state.pool4 = {
          stakedAmount: action.payload.stakedAmount,
          maturityDate: action.payload.maturityDate,
        };
      } else if (action.payload.pool === 5) {
        state.pool5 = {
          stakedAmount: action.payload.stakedAmount,
          maturityDate: action.payload.maturityDate,
        };
      } else if (action.payload.pool === 6) {
        state.pool6 = {
          stakedAmount: action.payload.stakedAmount,
          maturityDate: action.payload.maturityDate,
        };
      } else if (action.payload.pool === 7) {
        state.pool7 = {
          stakedAmount: action.payload.stakedAmount,
          maturityDate: action.payload.maturityDate,
        };
      } else if (action.payload.pool === 8) {
        state.pool8 = {
          stakedAmount: action.payload.stakedAmount,
          maturityDate: action.payload.maturityDate,
        };
      } else if (action.payload.pool === 9) {
        state.pool9 = {
          stakedAmount: action.payload.stakedAmount,
          maturityDate: action.payload.maturityDate,
        };
      } else if (action.payload.pool === 'lp') {
        state.lp = {
          stakedAmount: action.payload.stakedAmount,
          maturityDate: action.payload.maturityDate,
        };
      }
    },
  },
});

export const { storeStakeInfo } = stakeSlice.actions;

export default stakeSlice.reducer;
