import styled from 'styled-components';

export const CardContainer = styled.div`
  /* flex: 1 0 25%; */
  /* margin: 15px; */
  /* max-width: 380px; */
  /* background: #191c27; */
  width: 380px;
  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['300']
      : props.theme.colors.grey['100']};
  border-radius: 10px;

  transition: 0.6s;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 320px;
  }
  &:hover {
    transform: translate(0, -7px);
  }

  /* For Tablet */
  @media (max-width: ${(props) => props.theme.breakpoints.desktop}) {
    font-size: 1.5em;
  }

  .details-container {
    position: relative;
    padding: 0 24px;
    padding-top: 10px;
  }
`;

export const CardImgContainer = styled.div`
  margin-bottom: 5%;
  position: relative;
  border-radius: 5px 5px 0 0;
`;
export const BannerImg = styled.img`
  /* max-width: auto; */
  width: 100%;
  height: 180px;
  border-radius: 10px 10px 0 0;
`;
export const NameContainer = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const ProjectName = styled.p`
  margin: 0;
  font-weight: 500;
  /* font-size: 1.2em; */
  font-size: 1.25rem;
  line-height: 130%;
  /* color: #e7eaf3; */
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['200']
      : props.theme.colors.black['400']};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 20px;
  }
`;

export const TokenName = styled.p`
  margin: 0;
  /* color: #777d92; */
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['200']
      : props.theme.colors.black['400']};
  /* font-family: "Roboto"; */
  font-style: normal;
  font-weight: 500;
  font-size: 0.8em;
  margin-left: 0.5em;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 10px;
    color: #777d92;
  }
`;

export const SupportedTokens = styled.div`
  /* font-family: "Roboto"; */
  font-style: normal;
  font-weight: 500;
  /* font-size: 12px; */
  /* line-height: 150%; */

  display: flex;
  align-items: center;
  text-align: center;
  color: #777d92;
  border-left: 1px solid #31374d;
  padding-left: 5px;
  font-size: 8px;
`;

export const PipeDivider = styled.p`
  margin: 0 0.2em;
  color: #31374d;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 0 0.3rem;
  }
`;

export const ProjectType = styled.div`
  /* display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; */
  padding: 4px;

  position: absolute;
  /* width: 50px;
    height: 24px; */
  right: 8px;
  top: 8px;

  /* Yellow/Yellow_400 */

  color: #000;
  background: ${(props) => {
    if (props.type === 'Public IDO') {
      return props.theme.colors.yellow[400];
    }
    if (props.type === 'Community IDO') {
      return props.theme.colors.green[400];
    }

    return props.theme.colors.yellow[400];
  }};
  border-radius: 4px;
`;

export const CardLogo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;

  position: absolute;
  left: 20px;
  width: 48px;
  height: 48px;
  top: -40px;
  /* right: 81.05%; */
  /* top: 150px; */
  /* bottom: 48.46%; */

  /* background: #191c27; */
  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['300']
      : props.theme.colors.grey['100']};
  border-radius: 50%;

  img {
    max-width: 100%;
  }
`;

export const CardDetailsContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  /* padding: 5%; */
`;
export const ItemRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 1% 5%; */
  margin-bottom: 12px;
`;

export const RowText = styled.p`
  margin: 0;
  /* font-family: "Roboto"; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  /* line-height: 145%; */
  /* color: #e7eaf3; */
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['200']
      : props.theme.colors.black['400']};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: 14px;
  }
`;

export const RowTextSpan = styled.span`
  font-size: 0.9em;
  font-style: normal;
  font-weight: 400;
  color: ${(props) => props.color};
  margin-left: 5px;
`;

export const WhiteListStatus = styled.div`
  padding: 6px;
  background: ${(props) => props.bgColor};
  color: ${(props) => props.color};
  border-radius: 4px;
  font-size: 0.8em;
`;

export const NetworkInfo = styled.div`
  /* width: 62px;
  height: 26px; */
  /* background: #12141c; */
  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['400']
      : props.theme.colors.grey['200']};
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
`;

export const Divider = styled.hr`
  /* border: 1.5px solid #12141c; */
  border: 1px solid
    ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black['400']
        : `${props.theme.colors.grey['200']}99`};
  margin: 5% 1.05%;
  margin-bottom: 2%;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 9% 1.05%;
    margin-bottom: 2%;
  }
`;

export const DashedDivider = styled.hr`
  width: ${(props) =>
    props.strLength < 2
      ? '70%'
      : props.strLength >= 2 && props.strLength < 4
      ? '60%'
      : props.strLength >= 4 && props.strLength < 7
      ? '38%'
      : '48%'};
  border: none;
  height: 2px;
  background: #000;
  background: repeating-linear-gradient(
    90deg,
    #31374d,
    #31374d 6px,
    transparent 6px,
    transparent 12px
  );
`;
