export const roundOffBuyValue = (price) => {
  console.log(price);
  let prices;
  if (typeof price !== 'string') {
    prices = price.toString();
  }
  // prices = price;
  let roundOffPrice = prices;

  if (prices?.indexOf('.') !== -1) {
    const decimalIndex = prices?.indexOf('.');
    roundOffPrice =
      prices.substring(0, decimalIndex + 1) +
      prices.substring(decimalIndex + 1, decimalIndex + 3);
    return parseFloat(roundOffPrice);
  }

  return parseFloat(roundOffPrice);
};
export const calculatePriceOfTokens = (tokens, price) => {
  console.log(tokens, price);
  const token = parseFloat(tokens);
  const prices = parseFloat(price);
  let priceOfToken = parseFloat(parseFloat(token * prices));

  priceOfToken = roundOffBuyValue(priceOfToken);

  if (priceOfToken < 0.1) return 0;

  return Math.ceil(priceOfToken);
};

export const calculatePriceOfTokensRoundUp = (tokens, price) => {
  const token = parseFloat(tokens);
  const prices = parseFloat(price);
  let priceOfToken = parseFloat(parseFloat(token * prices));

  priceOfToken = roundOffBuyValue(priceOfToken);

  if (priceOfToken < 0.1) return 0;

  return Math.ceil(priceOfToken);
};

export const calculatePriceOfTokensRoundDown = (tokens, price) => {
  const token = parseFloat(tokens);
  const prices = parseFloat(price);
  let priceOfToken = parseFloat(parseFloat(token * prices));

  priceOfToken = roundOffBuyValue(priceOfToken);

  if (priceOfToken < 0.1) return 0;

  return Math.floor(priceOfToken);
};

// export function convertNegetiveNumberToNumber(number) {
//   if (number < 0.001) return 0;
//   return Math.ceil(number);
// }
