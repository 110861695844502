import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider } from 'styled-components';
import { useSelector } from 'react-redux';
import toast, { Toaster, useToasterStore } from 'react-hot-toast';
import GlobalStyle from './theme/globalStyles';
// import PoolDetails from './pages/PoolsList/PoolsList';
import 'antd/dist/antd.css';
import FullScreenLoader from './components/Loader/FullScreenLoader';
import Staking from './components/Staking/Staking';
import Dashboard from './components/Dashboard/Dashboard';

const PoolDetails = React.lazy(() => import('./pages/PoolDetails/PoolDetails'));
const PoolsList = React.lazy(() => import('./pages/PoolsList/PoolsList'));

const TOAST_LIMIT = 3;
const queryClient = new QueryClient();

function App() {
  const theme = useSelector((state) => state.theme.props);
  const { toasts } = useToasterStore();

  useEffect(() => {
    toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit
      .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) removal without animation: ;
  }, [toasts]);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Toaster position="top-right" />
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <React.Suspense fallback={<FullScreenLoader />}>
                  <PoolsList />
                </React.Suspense>
              }
            />
            <Route
              path="/pools/:projectId"
              element={
                <React.Suspense fallback={<FullScreenLoader />}>
                  <PoolDetails />
                </React.Suspense>
              }
            />
            <Route
              path="/stake"
              element={
                <React.Suspense fallback={<FullScreenLoader />}>
                  <Staking />
                </React.Suspense>
              }
            />
            <Route
              path="/dashboard"
              element={
                <React.Suspense fallback={<FullScreenLoader />}>
                  <Dashboard />
                </React.Suspense>
              }
            />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
