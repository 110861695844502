import POLYGONTESTNETABI from './DEV_CURRENCY_ABI.json';
import USDCCURRENCYABI from './USDC_CURRENCY.json';
import POOLABI from './TierIDOPool.json';

const web3Configuration = {
  // BSC Mainnet
  56: {
    abi: POOLABI,
    currency: {
      address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      abi: USDCCURRENCYABI,
    },
    // rpc: 'https://shy-nameless-sun.bsc.quiknode.pro/857077e9f117da1a6a57a981827335db71baadc9/',

    rpc: 'https://bsc-mainnet.blastapi.io/2f247e9d-8dc4-4737-bebf-54fdb87ac74b',
  },
  // Polygon/Matic Mainnet
  137: {
    rpc: 'https://polygon-mainnet.blastapi.io/2f247e9d-8dc4-4737-bebf-54fdb87ac74b',
  },
  // Avalance Mainnet
  43114: {
    rpc: 'https://speedy-nodes-nyc.moralis.io/31649378acd900255d51e632/avalanche/mainnet',
  },
  // BSC Testnet
  97: {
    abi: POOLABI,
    currency: '',
    rpc: 'https://bsc.getblock.io/testnet/?api_key=11e35fe6-558a-4850-b56c-7e6cf1ea27ff',
    // rpc: 'https://grtrlbqlkmce.usemoralis.com:2053/server',
  },
  // Polygon / Matic Testnet
  80001: {
    abi: POOLABI,
    currency: {
      address: '0x6e417ED32794D24a2e188d734B5b0747cD24DFE8',
      abi: POLYGONTESTNETABI,
    },
    rpc: 'https://rpc-mumbai.maticvigil.com',
    // rpc: "http://qa-admin-api.metalaunch.io/maticTestnet",
  },

  1: {
    rpc: 'https://speedy-nodes-nyc.moralis.io/31649378acd900255d51e632/avalanche/testnet',
  },

  // Avalance Testnet
  43113: {
    rpc: 'https://api.avax-test.network/ext/bc/C/rpc',
  },
  1442: { rpc: 'https://rpc.public.zkevm-test.net' },
  // polygon zkevm testnet
  420: { rpc: 'https://optimism-goerli.publicnode.com' },
  // optimism testnet
  421613: { rpc: 'https://arbitrum-goerli.publicnode.com' },

  // arbitrum testnet

  42161: {
    rpc: 'https://arbitrum-one.blastapi.io/2f247e9d-8dc4-4737-bebf-54fdb87ac74b',
  }, // arbitrum mainnet
  10: {
    rpc: 'https://optimism-mainnet.blastapi.io/2f247e9d-8dc4-4737-bebf-54fdb87ac74b',
  }, // optimism
  1101: {
    rpc: 'https://zkevm-rpc.com',
  }, // polygon zkevm
};

export default web3Configuration;
