export default function uptoTwoDecimal(num) {
  //   console.log(
  //     num,
  //     typeof num,
  //     num.slice(0, num.indexOf('.') + 3),
  //     'STR'
  //   );
  const string = num.toString();
  if (string.includes('.')) {
    return string.slice(0, string.indexOf('.') + 3);
  }
  return string;
}
