export const theme = {
  font: {
    size: {
      normal: '1rem',
      mainheading: '2.5rem',
      subheading: '1.3rem',
    },

    weight: {
      semibold: '500',
      bold: '700',
      normal: '400',
    },

    family: {
      primary:
        'https://fonts.google.com/specimen/Roboto+Condensed?query=roboto#standard-styles',
    },
  },

  colors: {
    primary: {
      500: '#FF0074',
      400: '#FA539F',
      300: '#FA539F',
      200: '#FF3893',
      100: '#FF3893',
    },

    secondary: {
      500: '#482CF2',
      400: '#5E50FF',
      300: '#5E50FF',
      200: '#5E50FF',
      100: '#5E50FF',
    },

    grey: {
      500: '#616C97',
      400: '#8897C1',
      300: '#B8C1DA',
      200: '#E7EAF3',
      100: '#FFFFFF',
    },

    black: {
      500: '#090A0E',
      400: '#12141C',
      300: '#191C27',
      200: '#31374D',
      100: '#777D92',
      50: '#30323A',
    },
    red: {
      500: '#F32929',
      400: '#FB5959',
      300: '#FB5959',
      200: '#FB5959',
      100: '#FB5959',
    },
    green: {
      500: '#1AB17B',
      400: '#30D89B',
      300: '#3BEEAD',
      200: '#3BEEAD',
      100: '#3BEEAD',
    },
    yellow: {
      500: '#F38B2B',
      400: '#FCB778',
      300: '#FCB778',
      200: '#FCB778',
      100: '#FCB778',
    },
  },

  gradiant: 'linear-gradient(118.21deg, #EC4781 22.9%, #6038F7 130.15%)',

  spacing: {
    1: '0.25rem',
    2: '',
  },

  breakpoints: {
    mobile: '500px',
    tablet: '900px',
    desktop: '1200px',
  },
};

export const lightTheme = {
  ...theme,
  mode: 'light',
  text: theme.colors.grey['500'],
  background: '#F4F5F9',
};

export const darkTheme = {
  ...theme,
  mode: 'dark',
  text: theme.colors.grey['200'],
  background: theme.colors.black['400'],
};
