import styled from 'styled-components';
import { Modal } from 'antd';

export const ModalStyles = styled(Modal)`
  background-color: '#fff';

  & .ant-modal-body {
    color: blue;
    border-radius: 12px;
  }

  & .ant-modal-content {
    /* background: transparent; */
    background-color: ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black['400']
        : props.theme.colors.grey['100']};
    border-radius: 12px;
  }

  & .ant-modal-close {
    display: none;
  }
`;

export const CloseIcon = styled.img`
  position: absolute;
  top: 10px;
  left: auto;
  right: 10px;
  z-index: 100;
  cursor: pointer;
`;

export const Container = styled.div`
  padding-top: 30px;
`;

export const LogoBackground = styled.div`
  background-color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['300']
      : props.theme.colors.grey['100']};
  border-radius: 30px;
  padding: 5px;
  display: block;
  margin: 0 auto;
  width: 48px;
  height: 48px;
  margin-bottom: 2px;
`;

export const ProjectLogo = styled.img`
  /* width: 38px;
  height: 38px;
   */
  max-width: 100%;
`;

export const ProjectName = styled.p`
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  text-align: center;
  margin-bottom: 4px;

  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['200']
      : props.theme.colors.black['400']};
`;

export const Allocation = styled.p`
  margin: 0;
  padding: 0;
  font-size: 10px;
  line-height: 160%;
  text-align: center;
  margin-bottom: 12px;

  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['300']
      : props.theme.colors.black['200']};

  span {
    color: ${(props) => props.theme.colors.green['400']};
    font-size: 10px;
    line-height: 160%;
  }
`;

export const DetailsContainer = styled.div`
  padding: 24px;
  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['400']
      : props.theme.colors.grey['200']};
  border-radius: 10px;
`;
export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : '0px'};
`;
export const TokenLogo = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;
export const TokenName = styled.p`
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 145%;
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['300']
      : props.theme.colors.black['400']};
`;
export const WalletBalance = styled.p`
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;

  color: ${(props) => props.theme.colors.black['100']};

  span {
    font-size: 12px;
    line-height: 150%;
    margin-left: 5px;
    color: ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.grey['300']
        : props.theme.colors.black['400']};
  }
`;

export const WalletBalanceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const AmountContainer = styled.div`
  position: relative;
  height: 44px;
  border: ${(props) =>
    props.theme.mode === 'dark'
      ? `1px solid${props.theme.colors.black['300']}`
      : `1px solid${props.theme.colors.grey['300']}`};
  border-radius: 6px;
  margin-bottom: 10px;
`;
export const AmountInput = styled.input`
  font-size: 12px;
  line-height: 150%;
  width: 100%;
  height: 100%;
  padding: 16px;
  outline: none;
  border: none;
  border-radius: 6px;
  background: transparent;

  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['300']
      : props.theme.colors.black['400']};

  ::placeholder {
    opacity: 0.7;
  }
`;
export const MaxButton = styled.button`
  border: none;
  outline: none;
  padding: 1px 4px;
  font-size: 10px;
  line-height: 160%;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['300']
      : props.theme.colors.grey['300']};
  border-radius: 30px;
  font-size: 10px;
  line-height: 160%;
  color: ${(props) => props.theme.colors.black['100']};
`;
export const RecieveContainer = styled.div`
  padding: 14px 16px;
  margin-bottom: 16px;

  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['300']
      : props.theme.colors.grey['100']};
`;
export const Text = styled.p`
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: 150%;
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['300']
      : props.theme.colors.black['300']};
`;
export const SubmitButton = styled.button`
  padding: 9px 0;
  width: 100%;
  outline: none;
  border: none;
  background: linear-gradient(118.21deg, #ec4781 22.9%, #6038f7 130.15%);
  color: #ffffff;
  font-size: 14px;
  line-height: 145%;

  :disabled {
    background: ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black['200']
        : props.theme.colors.grey['300']};
    color: ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black['100']
        : props.theme.colors.grey['200']};
  }
`;
