import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import StakeModal from '../Modals/StakeModal/StakeModal';
import UnstakeModal from '../Modals/UnstakeModal/UnstakeModal';
import {
  StakeContainer,
  Stakeli,
  Stakeul,
  RightStakeWrapper,
} from './AsvaStake.style';
import LpStake from './StakeDetails/LpStake';
import MyPosition from './StakeDetails/MyPosition';
import Fifteendays from './StakeDetails/NativeStake/15days';
import Thirtydays from './StakeDetails/NativeStake/30days';
import Sevendays from './StakeDetails/NativeStake/7days';
import Ninetydays from './StakeDetails/NativeStake/90days';
//  stake2.0
import Fifteendays2 from './StakeDetails/NativeStake2/15days';
import Thirtydays2 from './StakeDetails/NativeStake2/30days';
import ThreeSixFivedays from './StakeDetails/NativeStake2/365days';
import Sevendays2 from './StakeDetails/NativeStake2/7days';
import Ninetydays2 from './StakeDetails/NativeStake2/90days';

function AsvaStake({ active, StateHandler }) {
  const [open, setOpen] = useState('1');
  const { isStakeModalVisible, isUnStakeModalVisible } = useSelector(
    (state) => state.modal
  );
  const HandleToggle = (p) => {
    setOpen(p);
  };
  return (
    <RightStakeWrapper>
      {isStakeModalVisible && <StakeModal />}
      {isUnStakeModalVisible && <UnstakeModal />}

      <StakeContainer>
        <Stakeul>
          <Stakeli
            onClick={() => StateHandler(4)}
            className={active === 4 ? 'active' : ''}
          >
            Staking 2.0
          </Stakeli>
          <Stakeli
            onClick={() => StateHandler(1)}
            className={active === 1 ? 'active' : ''}
          >
            Staking 1.0
          </Stakeli>
          <Stakeli
            onClick={() => StateHandler(2)}
            className={active === 2 ? 'active' : ''}
          >
            LP Farming
          </Stakeli>
          <Stakeli
            onClick={() => StateHandler(3)}
            className={active === 3 ? 'active' : ''}
          >
            My Position
          </Stakeli>
        </Stakeul>
      </StakeContainer>
      {active === 1 && (
        <>
          <Sevendays sevendayToggele={HandleToggle} open={open} />
          <Fifteendays fifteenToggele={HandleToggle} open={open} />
          <Thirtydays thirtyToggele={HandleToggle} open={open} />
          <Ninetydays nintyToggele={HandleToggle} open={open} />
        </>
      )}
      {active === 4 && (
        <>
          <Sevendays2 sevendayToggele={HandleToggle} open={open} />
          <Fifteendays2 fifteenToggele={HandleToggle} open={open} />
          <Thirtydays2 thirtyToggele={HandleToggle} open={open} />
          <Ninetydays2 nintyToggele={HandleToggle} open={open} />
          <ThreeSixFivedays threeSixFiveToggele={HandleToggle} open={open} />
        </>
      )}
      {active === 2 && <LpStake />}
      {active === 3 && <MyPosition />}
    </RightStakeWrapper>
  );
}

export default AsvaStake;
