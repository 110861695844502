import axios from 'axios';
import { getBackendUrl } from './constants';
// require('dotenv').config();

const BASE_URL = getBackendUrl(process.env.REACT_APP_ENV);

const axiosHelper = async (url, method, formData = null, JSONData = null) => {
  const headers = formData
    ? { 'Content-Type': 'multipart/form-data' }
    : JSONData
    ? { 'Content-Type': 'application/json' }
    : {};
  // headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  return axios({
    method,
    url: `${BASE_URL}${url}`,
    data: formData || JSONData || null,
    headers,
  });
};

export default axiosHelper;
