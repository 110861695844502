import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Allocation,
  CloseIcon,
  Container,
  LogoBackground,
  ModalStyles,
  NoteText,
  ProjectLogo,
  ProjectName,
  // Tag,
  // TagContainer,
  DetailsContainer,
  SubmitButton,
  LoadingContainer,
  StakeInfoContainer,
  InfoRow,
  CustomDivider,
} from '../StakeModal/StakeModal.styles';
import closeIcon from '../../../../assets/Close.svg';
import asvaLogo from '../../../../assets/asvaLogo.svg';
import lpLogo from '../../assets/logo.svg';
import Loader from '../../../Loader/Loader';
import {
  InnerContainer,
  ThankYouText,
  TickBackground,
} from '../../../Modal/ThankYou/ThankYouModal.styles';
import TickPng from '../../../../assets/tick.png';
import { toggleUnStakeModal } from '../../../../store/slices/modal';
import pools from '../../../../config/stakePool';
import { unStakeAsvaToken, userStakeInfo } from '../../../../Web3/web3';
import formatAddress from '../../../../utils/formatAddress';
import { storeStakeInfo } from '../../../../store/slices/staking';
import { currentEnv } from '../../StakeEnv';

function StakeModal() {
  // const [amount, setAmount] = useState('');
  // const [modalOpen, setModalOpen] = useState(true);
  const {
    isUnStakeModalVisible,
    // rewardPercent,
    period,
    isLp,
    stakedInPool,
    rewardsEarned,
  } = useSelector((state) => state.modal);

  const userData = useSelector((state) => state.user);

  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [success, setSuccess] = useState(false);
  const [txHash, setTxHash] = useState('');

  const dispatch = useDispatch();

  const handleCancel = () => {
    dispatch(toggleUnStakeModal());
  };

  const handleUnStake = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let contract;
      if (isLp) {
        // eslint-disable-next-line prefer-destructuring
        contract = pools.lp[0];
      } else {
        let index = 0;
        if (period === '7') {
          index = 0;
        } else if (period === '15') {
          index = 1;
        } else if (period === '30') {
          index = 2;
        } else if (period === '90') {
          index = 3;
        } else if (period === '21') {
          index = 4;
        } else if (period === '60') {
          index = 5;
        } else if (period === '91') {
          index = 6;
        } else if (period === '180') {
          index = 7;
        } else if (period === '365') {
          index = 8;
        }
        contract = pools.pools[index];
      }
      // const contract = pools.pools[0];

      const network = await currentEnv(process.env.REACT_APP_ENV);
      const result = await unStakeAsvaToken(contract.contractAddress[network]);
      // console.log('done', result);
      // const displayTxHash = formatAddress(result?.transactionHash);
      setTxHash(result?.transactionHash);
      const userStake = await userStakeInfo(contract.contractAddress[network]);
      dispatch(
        storeStakeInfo({
          pool: isLp
            ? 'lp'
            : period === '7'
            ? 1
            : period === '15'
            ? 2
            : period === '30'
            ? 3
            : period === '90'
            ? 4
            : period === '21'
            ? 5
            : period === '60'
            ? 6
            : period === '91'
            ? 7
            : period === '180'
            ? 8
            : period === '365'
            ? 9
            : null,
          stakedAmount: userStake.amount,
          maturityDate: userStake.date,
        })
      );
      setSuccess(true);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <ModalStyles
      footer={null}
      visible={isUnStakeModalVisible}
      onCancel={handleCancel}
      width={400}
    >
      <CloseIcon src={closeIcon} onClick={handleCancel} />

      <Container>
        <LogoBackground>
          <ProjectLogo src={isLp ? lpLogo : asvaLogo} alt="close" />
        </LogoBackground>
        <ProjectName>
          {success
            ? 'Unstake Completed'
            : isLp
            ? 'Unstake ASVA/BUSD'
            : 'Unstake ASVA'}
        </ProjectName>
        {loading && <Allocation>Processing</Allocation>}
        {success && (
          <Allocation>
            Withdraw {stakedInPool * 1 + rewardsEarned * 1}{' '}
            {isLp ? 'ASVA/BUSD' : 'ASVA'}
          </Allocation>
        )}
        {/* {true && (
          <TagContainer>
            <Tag bgColor="green">{rewardPercent}% APY</Tag>
            <Tag bgColor="yellow">{period} Days</Tag>
          </TagContainer>
        )} */}
      </Container>
      {loading ? (
        <DetailsContainer>
          <LoadingContainer>
            <Loader />
            <NoteText>Processing...</NoteText>
          </LoadingContainer>
        </DetailsContainer>
      ) : success ? (
        <DetailsContainer>
          <InnerContainer>
            <TickBackground>
              <img src={TickPng} alt="tick" />
            </TickBackground>
            <ThankYouText>
              {/* Your Unstake {isLp ? 'ASVA/BUSD' : 'ASVA'} has been completed */}
              Your tokens are now successfully unstaked!
            </ThankYouText>

            <NoteText>
              Trns Hash{' '}
              <a
                href={
                  userData.networkId === 56
                    ? `https://bscscan.com/tx/${txHash}`
                    : `https://testnet.bscscan.com/tx/${txHash}`
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                {formatAddress(txHash)}
              </a>
            </NoteText>
          </InnerContainer>
        </DetailsContainer>
      ) : (
        <DetailsContainer>
          <StakeInfoContainer>
            <InfoRow>
              <NoteText>Your Total Staked</NoteText>
              <NoteText>
                {stakedInPool} {isLp ? 'ASVA/BUSD' : 'ASVA'}
              </NoteText>
            </InfoRow>
            <CustomDivider />
            <InfoRow>
              <NoteText>Rewards Earned </NoteText>
              <NoteText>{rewardsEarned} ASVA</NoteText>
            </InfoRow>
          </StakeInfoContainer>

          <NoteText>
            Note: Once you click on Unstake, your staked assets and the tokens
            you earned from staking will be deposited into your wallet directly.
          </NoteText>

          <SubmitButton
            disabled={stakedInPool * 1 <= 0}
            onClick={handleUnStake}
          >
            Unstake
          </SubmitButton>
        </DetailsContainer>
      )}
    </ModalStyles>
  );
}

export default StakeModal;
