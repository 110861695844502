import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Temp = styled.div`
  display: flex;
  flex-direction: column;
`;
