import React from 'react';
import PropTypes from 'prop-types';
import Progress from 'react-progressbar';
import { useTheme } from 'styled-components';
import { Percentage, ProgressBarContainer } from './ProgressBar.styles';
import './ProgressBar.css';
import useWindowSize from '../../hooks/useWindowSize';

function ProgressBar({ completed, width, styleClass }) {
  // console.log(completed, width);
  const theme = useTheme();
  const { widthProgressBar } = useWindowSize();
  return (
    <ProgressBarContainer width={width}>
      {widthProgressBar >= parseInt(theme.breakpoints.mobile, 10) ? (
        <Progress
          style={
            completed === 0
              ? { visibility: 'hidden' }
              : { visibility: 'visible' }
          }
          className={styleClass}
          completed={completed}
          height="1em"
        />
      ) : (
        <Progress
          style={
            completed === 0
              ? { visibility: 'hidden' }
              : { visibility: 'visible' }
          }
          className={styleClass}
          completed={completed}
          height="0.8rem"
        />
      )}
      <Percentage completed={completed}>{`${completed}%`}</Percentage>
    </ProgressBarContainer>
  );
}

ProgressBar.defaultProps = {
  styleClass: 'gradient',
};

ProgressBar.propTypes = {
  completed: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  styleClass: PropTypes.string,
};

export default ProgressBar;
