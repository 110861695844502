import styled from 'styled-components';
import { Modal } from 'antd';

export const ModalStyles = styled(Modal)`
  background-color: '#fff';

  & .ant-modal-body {
    color: blue;
    border-radius: 12px;
  }

  & .ant-modal-content {
    /* background: transparent; */
    background-color: ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black['300']
        : props.theme.colors.grey['100']};
    border-radius: 12px;
  }

  & .ant-modal-close {
    display: none;
  }
`;

export const CloseIcon = styled.img`
  position: absolute;
  top: 10px;
  left: auto;
  right: 10px;
  z-index: 100;
  cursor: pointer;
`;

export const Container = styled.div`
  padding-top: 30px;
`;

export const TickBackground = styled.div`
  background-color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['200']
      : props.theme.colors.grey['100']};

  margin: 0 auto;
  margin-bottom: 22px;
  padding: 5px;
  width: 59px;
  height: 59px;
  border-radius: 30px;

  img {
    max-width: 100%;
  }
`;

export const LogoBackground = styled.div`
  background-color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['200']
      : props.theme.colors.grey['100']};
  border-radius: 30px;
  padding: 5px;
  display: block;
  margin: 0 auto;
  width: 48px;
  height: 48px;
  margin-bottom: 2px;
`;

export const ProjectLogo = styled.img`
  /* width: 38px;
  height: 38px;
   */
  max-width: 100%;
`;

export const ProjectName = styled.p`
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  text-align: center;
  margin-bottom: 4px;

  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['200']
      : props.theme.colors.black['400']};
`;

export const Text = styled.p`
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: 150%;
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['300']
      : props.theme.colors.black['300']};
  text-align: center;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : '0px'};
`;

export const DetailsContainer = styled.div`
  padding: 50px 0;
  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['400']
      : props.theme.colors.grey['200']};
  border-radius: 10px;
`;
export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : '0px'};
`;

export const InnerContainer = styled.div`
  /* max-width: 181px; */
  padding: 0 2px;
  margin: 0 auto;
`;

export const ThankYouText = styled.p`
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['200']
      : props.theme.colors.black['400']};
  max-width: 250px;
  margin: 0 auto;
  margin-bottom: 12px;
`;
