import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import toast from 'react-hot-toast';
import {
  CloseIcon,
  Container,
  CustomButton,
  CustomForm,
  CustomInput,
  FormHeading,
  FormItem,
  FormWrapper,
  ModalStyles,
} from './EditProfileModal.style';
import Close from '../../../../assets/Close.png';
import axiosHelper from '../../../../api/axios-helper';
import { fetchProfileInfo } from '../../../../store/slices/user';

function SolanaWalletModal({ solanaModalOpen, closeModal }) {
  const user = useSelector((state) => state.user);

  const [loading, setLoading] = useState(false);
  const [solanaAddress, setSolanaAddress] = useState('');

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.profileInfo) {
      setSolanaAddress(user?.profileInfo?.solanaWallet);
      form.setFieldsValue({
        address: user?.profileInfo?.solanaWallet,
      });
    }
  }, [user?.profileInfo]);

  const handleCancel = () => {
    closeModal();
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await axiosHelper('user-profile', 'PUT', null, {
        walletAddress: user?.address || '',
        firstName: user?.profileInfo?.firstName || '',
        lastName: user?.profileInfo?.lastName || '',
        email: user?.profileInfo?.email || '',
        avatarUrl: user?.profileInfo?.avatarUrl || '',
        solanaWallet: solanaAddress || '',
      });
      //   console.log('Success:', values);
      setLoading(false);
      dispatch(fetchProfileInfo({ address: user?.address?.toLowerCase() }));
      closeModal();
    } catch (error) {
      setLoading(false);
      toast.error('Something went wrong!');
      console.log(error);
    }
  };

  const onFinishFailed = (errorInfo) => {
    toast.error('Something went wrong!');
    console.log('Failed:', errorInfo);
  };

  return (
    <ModalStyles
      footer={null}
      visible={solanaModalOpen}
      onCancel={handleCancel}
      width={400}
    >
      <Container>
        <CloseIcon src={Close} onClick={handleCancel} />

        <FormWrapper>
          <FormHeading>Add solana wallet address</FormHeading>

          <CustomForm
            name="basic"
            form={form}
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
            // initialValues={{
            //   remember: true,
            // }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <FormItem
              label="Your Address"
              name="address"
              rules={[
                {
                  // required: true,
                  message: 'Please input your address!',
                },
              ]}
            >
              <CustomInput
                value={solanaAddress}
                onChange={(e) => setSolanaAddress(e.target.value)}
              />
            </FormItem>
            <FormItem
              wrapperCol={{
                offset: 0,
                span: 24,
              }}
            >
              <CustomButton
                disabled={
                  loading ||
                  Boolean(solanaAddress.length < 1) ||
                  !user?.isConnected
                }
              >
                Update
              </CustomButton>
            </FormItem>
          </CustomForm>
        </FormWrapper>
      </Container>
    </ModalStyles>
  );
}

export default SolanaWalletModal;
