import styled from 'styled-components';
import { Modal } from 'antd';

// Have used many similiar classes as because of the same design repetition
export const Container = styled.div`
  padding: 10% 10% 0% 10%;
  border-radius: 20px;
  position: relative;
`;

export const ModalStyles = styled(Modal)`
  & .ant-modal-body {
    color: blue;
    border-radius: 12px;
  }

  .ant-modal-close-x {
    display: none;
  }

  & .ant-modal-content {
    background: ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black['300']
        : props.theme.colors.grey['200']};
    border-radius: 12px;
  }
`;

export const Heading = styled.p`
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['300']
      : props.theme.colors.black['400']};
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 135%;
  margin-bottom: 1%;
`;
export const A = styled.a`
  color: #fb5959;
`;
export const CheckBoxDiv = styled.div`
  margin-top: 6%;
`;
export const Checkbox = styled.img`
  color: #d5d5d5;
  margin-right: 2%;
  width: 16px;
  height: 16px;
`;

export const Label = styled.label`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;
  color: #777d92;
`;
export const Span = styled.span`
  color: #fb5959;
`;
export const Metamask = styled.button`
  outline: none;
  border: none;
  display: flex;
  width: 280px;
  height: 48px;
  align-items: center;
  border: ${(props) =>
    props.theme.mode === 'dark'
      ? `1px solid ${props.theme.colors.black['200']}`
      : `1px solid ${props.theme.colors.grey['300']}`};

  /* 1px solid #31374d; */
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 15%;
  padding-left: 3%;
  background-color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.black['400']
      : props.theme.colors.grey['100']};

  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['300']
      : props.theme.colors.black['300']};
`;
export const MetamaskImage = styled.img`
  width: 23.75px;
  height: 20px;
  margin-right: 5%;
`;

export const MetamaskText = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['300']
      : props.theme.colors.black['300']};
`;
// Modal second Css code start here
export const MetamaskConnect = styled.div`
  display: flex;
  position: relative;
  width: 260px;
  height: 47px;
  align-items: center;
  border: 1px solid #31374d;
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 6%;
  padding-left: 3%;
`;
export const MetamaskImageConnect = styled.img`
  width: 23.75px;
  height: 20px;
  margin-right: 5%;
`;
export const MetamaskTextConnect = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #b8c1da;
`;
export const Disconnect = styled.div`
  width: 42%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #12141c;
  padding: 7px 11px;
  margin-top: 14%;
  border-radius: 5px;
`;
export const DisconnectSpan = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #b8c1da;
`;
export const DisconnectIcon = styled.img``;

// Modal second css code ends here
// Modal last code start here till end
export const NetworkContainer = styled.div`
  margin-top: 13%;
`;
export const CloseIcon = styled.img`
  position: absolute;
  top: -2%;
  left: auto;
  right: -12px;
  z-index: 100;
  cursor: pointer;
`;
export const SelectNetworkPara = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;
  color: #777d92;
  margin-top: 10%;
`;
export const Networks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 4%;
`;
export const List = styled.div`
  border: 1px solid #31374d;
  box-sizing: border-box;
  border-radius: 8px;
  width: 259px;
  height: 48px;
  padding: 10px;
  position: relative;
  &:hover {
    background-color: #31374d;
    color: white;
    border-radius: 8px;
    width: 259px;
    height: 48px;
    padding: 10px;
  }
`;
export const NetworksImg = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 6%;
`;
export const NetworkName = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 140%;
  color: #b8c1da;
  margin-right: 27%;
`;
export const CommingSoon = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #30d89b;
  margin-left: 3%;
`;
export const ActiveIcon = styled.img`
  width: 6%;
  height: 34%;
  position: absolute;
  top: 33%;
  right: 6%;
`;
export const CopyImage = styled.img`
  position: absolute;
  right: 6px;
  width: 23.75px;
  height: 20px;
  margin-left: 5%;
`;
