import React, { useCallback, useEffect, useState } from 'react';
import Layout from '../../Layout/Layout';
import { web3Initialise } from '../../Web3/web3';
import Loader from '../Loader/Loader';
import AsvaStake from './AsvaStake/AsvaStake';
import { StakeWrapper } from './AsvaStake/AsvaStake.style';
import OverallStaking from './AsvaStake/OverallStaking';
import { currentEnv } from './StakeEnv';

function Staking() {
  const [active, setActive] = useState(4);
  const [loading, setLoading] = useState(false);
  const StateHandler = useCallback((props) => {
    setActive(props);
  }, []);

  const network = currentEnv(process.env.REACT_APP_ENV);
  const stakeInitializeObject = {
    projectNetwork: {
      networkId: network,
    },
  };
  const initWeb3 = async () => {
    setLoading(true);
    await web3Initialise(stakeInitializeObject);
    setLoading(false);
  };

  useEffect(() => {
    initWeb3();
  }, []);

  return (
    <Layout>
      {loading ? (
        <Loader />
      ) : (
        <StakeWrapper>
          <OverallStaking active={active} initLoading={loading} />
          <AsvaStake StateHandler={StateHandler} active={active} />
        </StakeWrapper>
      )}
    </Layout>
  );
}

export default Staking;
