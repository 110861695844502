import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Container,
  Heading,
  NetworkContainer,
  Networks,
  List,
  NetworkName,
  NetworksImg,
  ActiveIcon,
  SelectNetworkPara,
  // CommingSoon,
  CloseIcon,
  ModalStyles,
} from './SwitchNetworkModal.styles';

import ActiveTic from '../../../assets/ActiveTic.png';
import Close from '../../../assets/Close.svg';
import Avalanche from '../../../assets/Avalanche.png';
import Binance1 from '../../../assets/Binance1.png';
import Polygon from '../../../assets/poly.svg';
// import OptiLogo from '../../../assets/opti.png';
import PolygonZkevm from '../../../assets/polyzkvm.png';
// import ArbiLogo from '../../../assets/arbi.png';
// import Soalna from '../../../assets/Soalna.png';
import { toggleSwitchNetwork } from '../../../store/slices/modal';
import { switchNetwork } from '../../../Web3/web3';

function SwitchNetworkModal() {
  const { isSwitchNetworkModalVisible } = useSelector((state) => state.modal);

  const { networkId } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const handleCancel = () => {
    dispatch(toggleSwitchNetwork());
  };

  const handleChangeNetworkRequest = async (switchNetworkId) => {
    const isSwitched = await switchNetwork(switchNetworkId);
    if (isSwitched) {
      dispatch(toggleSwitchNetwork());
    }
  };

  // show is used just for demonstration

  return (
    <ModalStyles
      footer={null}
      visible={isSwitchNetworkModalVisible}
      onCancel={handleCancel}
      width={390}
    >
      <Container>
        <Heading>Switch your Preferred Network chain</Heading>
        <SelectNetworkPara>
          Select based on the chain listed below
        </SelectNetworkPara>
        <CloseIcon src={Close} onClick={handleCancel} />
        <NetworkContainer>
          <Networks onClick={() => handleChangeNetworkRequest(56)}>
            <List>
              <NetworksImg src={Binance1} />

              <NetworkName>BNB chain</NetworkName>
              {networkId === 56 && <ActiveIcon src={ActiveTic} />}
              {networkId === 97 && <ActiveIcon src={ActiveTic} />}
            </List>
          </Networks>
          <Networks onClick={() => handleChangeNetworkRequest(137)}>
            <List>
              <NetworksImg src={Polygon} />

              <NetworkName>Polygon</NetworkName>
              {networkId === 137 && <ActiveIcon src={ActiveTic} />}
              {networkId === 80001 && <ActiveIcon src={ActiveTic} />}
            </List>
          </Networks>
          <Networks onClick={() => handleChangeNetworkRequest(1101)}>
            <List>
              <NetworksImg src={PolygonZkevm} />

              <NetworkName>Polygon zkEVM </NetworkName>
              {networkId === 1101 && <ActiveIcon src={ActiveTic} />}
              {networkId === 1442 && <ActiveIcon src={ActiveTic} />}
            </List>
          </Networks>
          <Networks onClick={() => handleChangeNetworkRequest(43114)}>
            <List>
              <NetworksImg src={Avalanche} />

              <NetworkName>Avalanche</NetworkName>
              {networkId === 43114 && <ActiveIcon src={ActiveTic} />}
              {networkId === 43113 && <ActiveIcon src={ActiveTic} />}
            </List>
          </Networks>
          {/* <Networks onClick={() => handleChangeNetworkRequest(42161)}>
            <List>
              <NetworksImg src={ArbiLogo} />

              <NetworkName>Arbitrum</NetworkName>
              {networkId === 42161 && <ActiveIcon src={ActiveTic} />}
              {networkId === 421613 && <ActiveIcon src={ActiveTic} />}
            </List>
          </Networks>
          <Networks onClick={() => handleChangeNetworkRequest(10)}>
            <List>
              <NetworksImg src={OptiLogo} />

              <NetworkName>Optimism </NetworkName>
              {networkId === 10 && <ActiveIcon src={ActiveTic} />}
              {networkId === 420 && <ActiveIcon src={ActiveTic} />}
            </List>
          </Networks> */}

          {/* <Networks onClick={handleChangeNetworkRequest}>
            <List>
              <NetworksImg src={Soalna} />

              <NetworkName>
                Solana
                <CommingSoon> Coming soon </CommingSoon>
              </NetworkName>
              {currentNetwork === 'Solana' && <ActiveIcon src={ActiveTic} />}
            </List>
          </Networks> */}
        </NetworkContainer>
      </Container>
    </ModalStyles>
  );
}

export default SwitchNetworkModal;
