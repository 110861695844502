/* eslint-disable arrow-body-style */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { getBackendUrl } from '../../api/constants';

const apiUrl = getBackendUrl(process.env.REACT_APP_ENV);

const fetchIsKycVerified = createAsyncThunk(
  'fetchIsKycVerified',
  async (address) => {
    const response = await axios.get(`${apiUrl}kyc/fetchUserStatus/${address}`);

    return response.data;
  }
);

const fetchIsUserWhiteListed = createAsyncThunk(
  'fetchIsUserWhiteListed',
  async (data) => {
    const response = await axios.get(
      `${apiUrl}users/checkUserProjectStatus/${data.poolId}/${data.address}`
    );

    return response.data.data;
  }
);

const fetchProfileInfo = createAsyncThunk('fetchProfileInfo', async (data) => {
  // console.log('sdadsa', apiUrl, data);
  const response = await axios.get(`${apiUrl}user-profile/${data.address}`);

  return response.data.data;
});

export const initialState = {
  address: '',
  isConnected: false,
  loading: false,
  error: null,
  networkId: null,
  chainId: null,
  walletType: null,
  balance: 0,
  displayAddress: '',
  projectContract: {},
  isKycVerifiedLoading: false,
  isKycVerified: false,
  isKycVerifiedError: null,
  isUserWhitelistedLoading: false,
  isUserWhitelisted: false,
  isUserWhitelistedError: null,
  isProfileInfoLoading: false,
  profileInfo: null,
  isProfileInfoError: null,
};

function formatAddress(address) {
  return `${address.substring(0, 5)}...${address.substring(
    address.length - 4,
    address.length
  )}`;
}

export const userSlice = createSlice({
  name: 'user',
  initialState,

  reducers: {
    setAccountDetails: (state, action) => {
      return {
        ...state,
        address: action.payload.address,
        isConnected: true,
        networkId: action.payload.networkId,
        chainId: action.payload.chainId,
        walletType: 'metamask',
        balance: action.payload.balance,
        displayAddress: formatAddress(action.payload.address),
      };
    },

    setUserAddress: (state, action) => {
      return {
        ...state,
        address: action.payload,
        displayAddress: formatAddress(action.payload),
      };
    },

    setUserNetwork: (state, action) => {
      return {
        ...state,
        networkId: parseFloat(action.payload),
      };
    },

    setUserBalance: (state, action) => {
      return {
        ...state,
        balance: parseFloat(action.payload),
      };
    },

    walletDisconnect: (state) => {
      return {
        ...state,
        address: '',
        isConnected: false,
        loading: false,
        error: null,
        networkId: null,
        chainId: null,
        walletType: null,
        balance: 0,
        displayAddress: '',
      };
    },

    setProjectContract: (state, action) => {
      // console.log('dasdsa', action);
      return {
        ...state,
        projectContract: action.payload,
      };
    },

    resetProjectContract: (state) => {
      return {
        ...state,
        projectContract: {},
      };
    },
  },

  extraReducers: (builder) => {
    /*
     * fetchIsKycVerified Cases
     */

    builder.addCase(fetchIsKycVerified.pending, (state) => {
      state.isKycVerifiedLoading = true;
    });
    builder.addCase(fetchIsKycVerified.fulfilled, (state, action) => {
      state.isKycVerifiedLoading = false;
      state.isKycVerified = action.payload.status;
    });
    builder.addCase(fetchIsKycVerified.rejected, (state) => {
      state.isKycVerifiedLoading = false;
    });

    /*
     * fetchIsUserWhiteListed Cases
     */

    builder.addCase(fetchIsUserWhiteListed.pending, (state) => {
      state.isUserWhitelistedLoading = true;
    });
    builder.addCase(fetchIsUserWhiteListed.fulfilled, (state, action) => {
      state.isUserWhitelistedLoading = false;
      state.isUserWhitelisted = action.payload;
    });
    builder.addCase(fetchIsUserWhiteListed.rejected, (state) => {
      state.isUserWhitelistedLoading = false;
    });

    /*
     * fetchProfileInfo Cases
     */

    builder.addCase(fetchProfileInfo.pending, (state) => {
      state.isProfileInfoLoading = true;
    });
    builder.addCase(fetchProfileInfo.fulfilled, (state, action) => {
      // console.log('PPrro', action.payload);
      state.isProfileInfoLoading = false;
      state.profileInfo = action.payload;
    });
    builder.addCase(fetchProfileInfo.rejected, (state) => {
      state.isProfileInfoLoading = false;
    });
  },
});

export const {
  setAccountDetails,
  setUserAddress,
  setUserNetwork,
  setUserBalance,
  walletDisconnect,
  setProjectContract,
  resetProjectContract,
} = userSlice.actions;

export default userSlice.reducer;
export { fetchIsKycVerified, fetchIsUserWhiteListed, fetchProfileInfo };
// export { setAccountDetails };
