import styled from 'styled-components';

export const ProgressBarContainer = styled.div`
  padding: 0.2em;
  position: relative;
  width: ${(props) => `${props.width}%`};
  /* border: 1px solid #31374d; */
  border: 1px solid
    ${(props) =>
      props.theme.mode === 'dark'
        ? props.theme.colors.black['200']
        : props.theme.colors.black['100']};
  border-radius: 20px;
  box-sizing: border-box;
  border-radius: 20px;
`;

export const Percentage = styled.div`
  position: absolute;
  top: 0px;
  right: 5px;
  font-size: 0.8em;
  /* color: ${(props) => props.theme.text}; */
  color: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.colors.grey['100']
      : props.theme.colors.black['500']};

  /* For Mobile */
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    top: 2px;
    font-size: 0.6rem;
  }
`;
