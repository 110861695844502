import React from 'react';
import PropTypes from 'prop-types';
import {
  // CompletedState,
  CountdownBlock,
  CountDownContainer,
  Seprator,
} from './CustomCountdown.styles';

function CustomCountdown({ formatted, completed }) {
  // console.log(completed, 'sjhdjkah');
  const { days, hours, minutes, seconds } = formatted;
  if (completed) {
    // Render a completed state
    return (
      <CountDownContainer>
        <CountdownBlock>00</CountdownBlock>
        <Seprator>:</Seprator>
        <CountdownBlock>00</CountdownBlock>
        <Seprator>:</Seprator>
        <CountdownBlock>00</CountdownBlock>
        <Seprator>:</Seprator>
        <CountdownBlock>00</CountdownBlock>
      </CountDownContainer>
    );
  }
  // Render a countdown
  return (
    <CountDownContainer>
      <CountdownBlock>{days}</CountdownBlock>
      <Seprator>:</Seprator>
      <CountdownBlock>{hours}</CountdownBlock>
      <Seprator>:</Seprator>
      <CountdownBlock>{minutes}</CountdownBlock>
      <Seprator>:</Seprator>
      <CountdownBlock>{seconds}</CountdownBlock>
    </CountDownContainer>
  );
}

CustomCountdown.propTypes = {
  formatted: PropTypes.exact({
    days: PropTypes.string,
    hours: PropTypes.string,
    minutes: PropTypes.string,
    seconds: PropTypes.string,
  }).isRequired,
  completed: PropTypes.bool.isRequired,
};

export default CustomCountdown;
