import { useState, useEffect } from 'react';

// import { fetchAccountDetails } from '../Web3/web3';
// Usage

// Hook
const useAccountChange = () => {
  const [accountId, setAccountId] = useState(undefined);

  useEffect(() => {
    const accountWasChanged = (accounts) => {
      setAccountId(accounts);
    };

    if (window.ethereum !== undefined)
      window.ethereum.on('accountsChanged', accountWasChanged);

    // const getAccount = async () => {
    //   const data = await fetchAccountDetails();
    //   setAccountId(data.account.address);
    // };

    // window.ethereum.off('accountsChanged', accountWasChanged);
  }, []); // Empty array ensures that effect is only run on mount

  // if(accountId === )

  return accountId;
};

export default useAccountChange;
