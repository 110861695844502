const poolsMainnet = {
  pools: [
    {
      sousId: 0,
      tokenName: 'ASVA',
      tokenAdressInLp: '0xD1e6354fb05bF72A8909266203dAb80947dcEccF',
      tokenAddressSecondInLp: '0xD1e6354fb05bF72A8909266203dAb80947dcEccF',
      tokenAddress: '0xD1e6354fb05bF72A8909266203dAb80947dcEccF',
      stakingTokenName: 'Native Staking',
      rewardTokenName: 'ASVA',
      stakingTokenAddress: {
        97: '0x6C1B15639b0Ee022d977759E6898B91Cf99886e6',
        56: '0xf7b6d7e3434cb9441982f9534e6998c43eef144a',
      }, // lp
      rewardTokenAddress: '0x6C1B15639b0Ee022d977759E6898B91Cf99886e6',
      contractAddress: {
        97: '0x7C5A8D433f05C4f8a09207E66E7E31F16f358da9',
        56: '0x112Eb5a8F77Bc4Cb75B57e41332fe02519f64bef',
      },
      stakingTokenCoinGeckoid: 'asva',
      rewardTokenCoinGeckoid: 'asva',
      coinGeckoIds: [
        'asva',
        // "0xeDd6cA8A4202d4a36611e2fff109648c4863ae19",
      ],
      isLp: false,
      stakingTokenDecimals: 18,
      rewardTokenDecimals: 18,
      lockupPeriod: 7,
    },
    {
      sousId: 1,
      tokenName: 'ASVA',
      tokenAdressInLp: '0xD1e6354fb05bF72A8909266203dAb80947dcEccF',
      tokenAddressSecondInLp: '0xD1e6354fb05bF72A8909266203dAb80947dcEccF',
      tokenAddress: '0xD1e6354fb05bF72A8909266203dAb80947dcEccF',
      stakingTokenName: 'Native Staking',
      rewardTokenName: 'ASVA',
      stakingTokenAddress: {
        97: '0x6C1B15639b0Ee022d977759E6898B91Cf99886e6',
        56: '0xf7b6d7e3434cb9441982f9534e6998c43eef144a',
      }, // lp
      rewardTokenAddress: '0x6C1B15639b0Ee022d977759E6898B91Cf99886e6',
      contractAddress: {
        97: '0xAf7655F748eeDB721561fBC57331CF1aEbfB7bb9',
        56: '0x6A48D5A1b815C0e35d0Bc66fc133dd9BD020E2e4',
      },
      stakingTokenCoinGeckoid: 'asva',
      rewardTokenCoinGeckoid: 'asva',
      coinGeckoIds: [
        'asva',
        // "0xeDd6cA8A4202d4a36611e2fff109648c4863ae19",
      ],
      isLp: false,
      stakingTokenDecimals: 18,
      rewardTokenDecimals: 18,
      lockupPeriod: 7,
    },
    {
      sousId: 2,
      tokenName: 'ASVA',
      tokenAdressInLp: '0xD1e6354fb05bF72A8909266203dAb80947dcEccF',
      tokenAddressSecondInLp: '0xD1e6354fb05bF72A8909266203dAb80947dcEccF',
      tokenAddress: '0xD1e6354fb05bF72A8909266203dAb80947dcEccF',
      stakingTokenName: 'Native Staking',
      rewardTokenName: 'ASVA',
      stakingTokenAddress: {
        97: '0x6C1B15639b0Ee022d977759E6898B91Cf99886e6',
        56: '0xf7b6d7e3434cb9441982f9534e6998c43eef144a',
      }, // lp
      rewardTokenAddress: '0x6C1B15639b0Ee022d977759E6898B91Cf99886e6',
      contractAddress: {
        97: '0xe08C71F42Cb536Ae28a85C430DD777c77920213b',
        56: '0xA6c2cE24F2c26179BFd4a06b90aF393acad176DD',
      },
      stakingTokenCoinGeckoid: 'asva',
      rewardTokenCoinGeckoid: 'asva',
      coinGeckoIds: [
        'asva',
        // "0xeDd6cA8A4202d4a36611e2fff109648c4863ae19",
      ],
      isLp: false,
      stakingTokenDecimals: 18,
      rewardTokenDecimals: 18,
      lockupPeriod: 30,
    },
    {
      sousId: 3,
      tokenName: 'ASVA',
      tokenAdressInLp: '0xD1e6354fb05bF72A8909266203dAb80947dcEccF',
      tokenAddressSecondInLp: '0xD1e6354fb05bF72A8909266203dAb80947dcEccF',
      tokenAddress: '0xD1e6354fb05bF72A8909266203dAb80947dcEccF',
      stakingTokenName: 'Native Staking',
      rewardTokenName: 'ASVA',
      stakingTokenAddress: {
        97: '0x6C1B15639b0Ee022d977759E6898B91Cf99886e6',
        56: '0xf7b6d7e3434cb9441982f9534e6998c43eef144a',
      }, // lp
      rewardTokenAddress: '0x6C1B15639b0Ee022d977759E6898B91Cf99886e6',
      contractAddress: {
        97: '0x95b85d00c39823A90c2F5e032208eD0146906C19',
        56: '0x3ab062E7D4a5c91436792A1F3e02f32Eb018C870',
      },
      stakingTokenCoinGeckoid: 'asva',
      rewardTokenCoinGeckoid: 'asva',
      coinGeckoIds: [
        'asva',
        // "0xeDd6cA8A4202d4a36611e2fff109648c4863ae19",
      ],
      isLp: false,
      stakingTokenDecimals: 18,
      rewardTokenDecimals: 18,
      lockupPeriod: 60,
    },
    {
      stakingTokenAddress: {
        97: '0x6C1B15639b0Ee022d977759E6898B91Cf99886e6',
        56: '0xf7b6d7e3434cb9441982f9534e6998c43eef144a',
      },
      contractAddress: {
        97: '0x7C5A8D433f05C4f8a09207E66E7E31F16f358da9',
        56: '0x045b9F4037Ea1e17726CCE67e42A3B3bedC3eE8a',
      },
    },
    {
      stakingTokenAddress: {
        97: '0x6C1B15639b0Ee022d977759E6898B91Cf99886e6',
        56: '0xf7b6d7e3434cb9441982f9534e6998c43eef144a',
      },
      contractAddress: {
        97: '0x7C5A8D433f05C4f8a09207E66E7E31F16f358da9',
        56: '0xe6d48477b390c9aF123fd00b5FDC34A1F55594DD',
      },
    },
    {
      stakingTokenAddress: {
        97: '0x6C1B15639b0Ee022d977759E6898B91Cf99886e6',
        56: '0xf7b6d7e3434cb9441982f9534e6998c43eef144a',
      },
      contractAddress: {
        97: '0x7C5A8D433f05C4f8a09207E66E7E31F16f358da9',
        56: '0xC86722948F3b40457c2f905821BE7C350126D5EA',
      },
    },
    {
      stakingTokenAddress: {
        97: '0x6C1B15639b0Ee022d977759E6898B91Cf99886e6',
        56: '0xf7b6d7e3434cb9441982f9534e6998c43eef144a',
      },
      contractAddress: {
        97: '0x7C5A8D433f05C4f8a09207E66E7E31F16f358da9',
        56: '0x5a49B0A50EDa8F514c173e8cc6d7D9201DD8D8eA',
      },
    },
    {
      stakingTokenAddress: {
        97: '0x6C1B15639b0Ee022d977759E6898B91Cf99886e6',
        56: '0xf7b6d7e3434cb9441982f9534e6998c43eef144a',
      },
      contractAddress: {
        97: '0x7C5A8D433f05C4f8a09207E66E7E31F16f358da9',
        56: '0x343f3eED5aa3EB3ABd21D233272c46a74DfEEF79',
      },
    },
  ],
  lp: [
    // {
    //   sousId: 0,
    //   tokenName: "LP",
    //   tokenAdressInLp: "0xD1e6354fb05bF72A8909266203dAb80947dcEccF",
    //   tokenAddressSecondInLp: "0xD1e6354fb05bF72A8909266203dAb80947dcEccF",
    //   tokenAddress: "0xD1e6354fb05bF72A8909266203dAb80947dcEccF",
    //   stakingTokenName: "LP Staking",
    //   rewardTokenName: "ASVA",
    //   stakingTokenAddress: "0x8a07ff83de1a7f8ac6d1792a82e5ba4b70aa6a8b", // lp
    //   rewardTokenAddress: "0x6c1b15639b0ee022d977759e6898b91cf99886e6",
    //   contractAddress: {
    //     97: "0x5CC0557082888FF28E5f814ed888E51Ca300F5D9",
    //     56: "0x21d05A87e1cB55B19FE2b9f9Ee452D64A7AC3069",
    //   },
    //   stakingTokenCoinGeckoid: "asva",
    //   rewardTokenCoinGeckoid: "asva",
    //   coinGeckoIds: [
    //     "asva",
    //     // "0xeDd6cA8A4202d4a36611e2fff109648c4863ae19",
    //   ],
    //   isLp: true,
    //   stakingTokenDecimals: 18,
    //   rewardTokenDecimals: 18,
    //   lockupPeriod: 7,
    // },
    // {
    //   sousId: 1,
    //   tokenName: "LP",
    //   tokenAdressInLp: "0xD1e6354fb05bF72A8909266203dAb80947dcEccF",
    //   tokenAddressSecondInLp: "0xD1e6354fb05bF72A8909266203dAb80947dcEccF",
    //   tokenAddress: "0xD1e6354fb05bF72A8909266203dAb80947dcEccF",
    //   stakingTokenName: "LP Staking",
    //   rewardTokenName: "ASVA",
    //   stakingTokenAddress: "0x8a07ff83de1a7f8ac6d1792a82e5ba4b70aa6a8b", // lp
    //   rewardTokenAddress: "0x6C1B15639b0Ee022d977759E6898B91Cf99886e6",
    //   contractAddress: {
    //     97: "0xF8e1D5Ee765FE765Be63354C13d58A85EC935336",
    //     56: "0x21d05A87e1cB55B19FE2b9f9Ee452D64A7AC3069",
    //   },
    //   stakingTokenCoinGeckoid: "asva",
    //   rewardTokenCoinGeckoid: "asva",
    //   coinGeckoIds: [
    //     "asva",
    //     // "0xeDd6cA8A4202d4a36611e2fff109648c4863ae19",
    //   ],
    //   isLp: true,
    //   stakingTokenDecimals: 18,
    //   rewardTokenDecimals: 18,
    //   lockupPeriod: 7,
    // },
    // {
    //   sousId: 2,
    //   tokenName: "LP",
    //   tokenAdressInLp: "0xD1e6354fb05bF72A8909266203dAb80947dcEccF",
    //   tokenAddressSecondInLp: "0xD1e6354fb05bF72A8909266203dAb80947dcEccF",
    //   tokenAddress: "0xD1e6354fb05bF72A8909266203dAb80947dcEccF",
    //   stakingTokenName: "LP Staking",
    //   rewardTokenName: "ASVA",
    //   stakingTokenAddress: "0x8a07ff83de1a7f8ac6d1792a82e5ba4b70aa6a8b", // lp
    //   rewardTokenAddress: "0x6c1b15639b0ee022d977759e6898b91cf99886e6",
    //   contractAddress: {
    //     97: "0xC36Bf557bc48A324D2Ea1d61F347Da1B3cE536cf",
    //     56: "0x21d05A87e1cB55B19FE2b9f9Ee452D64A7AC3069",
    //   },
    //   stakingTokenCoinGeckoid: "asva",
    //   rewardTokenCoinGeckoid: "asva",
    //   coinGeckoIds: [
    //     "asva",
    //     // "0xeDd6cA8A4202d4a36611e2fff109648c4863ae19",
    //   ],
    //   isLp: true,
    //   stakingTokenDecimals: 18,
    //   rewardTokenDecimals: 18,
    //   lockupPeriod: 30,
    // },
    {
      sousId: 3,
      tokenName: 'LP',
      tokenAdressInLp: '0xD1e6354fb05bF72A8909266203dAb80947dcEccF',
      tokenAddressSecondInLp: '0xD1e6354fb05bF72A8909266203dAb80947dcEccF',
      tokenAddress: '0xD1e6354fb05bF72A8909266203dAb80947dcEccF',
      stakingTokenName: 'LP Staking',
      rewardTokenName: 'ASVA',
      stakingTokenAddress: {
        97: '0x8a07ff83de1a7f8ac6d1792a82e5ba4b70aa6a8b',
        56: '0x583c2a4226a3b9abb87e658d1808926a73647768',
      }, // lp
      rewardTokenAddress: '0x6c1b15639b0ee022d977759e6898b91cf99886e6',
      contractAddress: {
        97: '0x026ea84F6639D0F53aD39Ff5f6D3F82a8eAD5453',
        56: '0xcC23A58E81495edf4025C70e4F864501da871FF3',
      },
      stakingTokenCoinGeckoid: 'asva',
      rewardTokenCoinGeckoid: 'asva',
      coinGeckoIds: [
        'asva',
        // "0xeDd6cA8A4202d4a36611e2fff109648c4863ae19",
      ],
      isLp: true,
      stakingTokenDecimals: 18,
      rewardTokenDecimals: 18,
      lockupPeriod: 60,
    },
  ],
  pools2: [
    {
      stakingTokenAddress: {
        97: '0x6C1B15639b0Ee022d977759E6898B91Cf99886e6',
        56: '0xf7b6d7e3434cb9441982f9534e6998c43eef144a',
      },
      contractAddress: {
        97: '0x7C5A8D433f05C4f8a09207E66E7E31F16f358da9',
        56: '0x045b9F4037Ea1e17726CCE67e42A3B3bedC3eE8a',
      },
    },
  ],
};
export default poolsMainnet;
