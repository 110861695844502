import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import EndedpoolDesktop from '../EndedPoolCard/EndedPoolCard';
import EndedPoolMobile from '../EndedPoolCard/EndedPoolMobile';

import {
  Container,
  Wrapper,
  HeadPoolList,
  Heading,
  Span,
  Inputfilter,
  Input,
  Filter,
  Li,
  PoolCardList,
  Wrapperdetail,
  TotalProject,
  Icon,
  Text,
  // UniqueParticipants,
  RaisedCapital,
  Number,
  Data,
  // NumberParticipants,
  // DataParticipants,
  NumberRaisedCapital,
  DataRaisedCapital,
  SectionHead,
  Tagline,
  BottomSection,
  // ProjectListed,
  LoadMore,
  SearchIcon,
  SearchIconDiv,
  SearchIconMobile,
  InputfilterMobile,
} from './EndedPoolList.styles';
import Frame from '../../../assets/Frame.svg';
// import Frame1 from '../../../assets/Frame1.png';
import { fetchEndedPoolList } from '../../../store/slices/pool';

import Searchicon from '../../../assets/Searchicon.png';
import Frame3 from '../../../assets/Frame3.svg';
import { useWindowSize } from '../../../hooks';
// import EndedPoolCardSkeleton from '../EndedPoolCard/EndedPoolCardSkeleton';
import EndedPoolListSkeleton from './EndedPoolListSkeleton';

function EndedPoolList() {
  const { endedPools, endedPoolsLoading } = useSelector((state) => state.pools);
  const pageNumber = useSelector((state) => state.pools.PageNumber);
  const loadMoreDisable = useSelector((state) => state.pools.loadMoreDisable);
  const EndedPoolStats = useSelector((state) => state.pools.EndedPoolStats);
  const [searchBar, setSearchBar] = useState(false);
  const [inputSearch, setInputSearch] = useState();

  const theme = useTheme();
  const { width } = useWindowSize();
  const [currentTab, setCurrentTab] = useState('All');
  const dispatch = useDispatch();

  const handleChange = (e) => {
    if (e.target.value.toLowerCase() === 'bsc') {
      setInputSearch('56');
    } else if (e.target.value.toLowerCase() === 'bsc testnet') {
      setInputSearch('97');
    } else if (e.target.value.toLowerCase() === 'polygon') {
      setInputSearch('137');
    } else if (e.target.value.toLowerCase() === 'polygon testnet') {
      setInputSearch('80001');
    } else if (e.target.value.toLowerCase() === 'avalanche') {
      setInputSearch('43114');
    } else if (e.target.value.toLowerCase() === 'avalanche testnet') {
      setInputSearch('43113');
    } else {
      setInputSearch(e.target.value);
    }
    if (e.target.value === '') {
      setInputSearch('');
      dispatch(fetchEndedPoolList({ number: 1, search: '' }));
    }
  };

  const handleSearch = (e) => {
    if (e.keyCode === 13) {
      dispatch(fetchEndedPoolList({ number: 1, search: inputSearch }));
    }
  };

  // if(endedPoolsLoading){
  //   return <EndedPoolCardSkeleton/>
  // }
  // if(endedPoolsLoading){
  //   return <EndedPoolListSkeleton/>
  // }

  return (
    <Container>
      <SectionHead>Total Projects and Capital Raised by Asva Labs</SectionHead>
      <Tagline>
        MetaLaunch has successfully hosted token sales for some of the trending
        projects in the metaverse and gaming sectors.
      </Tagline>
      {endedPoolsLoading ? (
        <EndedPoolListSkeleton />
      ) : (
        <Wrapperdetail>
          <TotalProject>
            <Icon>
              <img src={Frame3} alt="Frame" />
            </Icon>
            <Text>
              <Number>{`${EndedPoolStats?.totalProjects}+`}</Number>

              <Data>Total Raise</Data>
            </Text>
          </TotalProject>
          {/* <UniqueParticipants>
          <Icon>
            <img src={Frame1} alt="" />
          </Icon>
          <Text>
            <NumberParticipants>12000+</NumberParticipants>
            <DataParticipants>Unique participants</DataParticipants>
          </Text>
        </UniqueParticipants> */}
          <RaisedCapital>
            <Icon>
              <img src={Frame} alt="Frame3" />
            </Icon>
            <Text>
              <NumberRaisedCapital>{`${Math.floor(
                EndedPoolStats?.totalFundraise
              ).toLocaleString('en-US')} USD`}</NumberRaisedCapital>
              <DataRaisedCapital>Raised Capital</DataRaisedCapital>
            </Text>
          </RaisedCapital>
        </Wrapperdetail>
      )}
      <Wrapper>
        <HeadPoolList>
          {width >= parseInt(theme.breakpoints.mobile, 10) ? (
            <Heading>Ended Projects</Heading>
          ) : (
            <Heading>List of IDO project</Heading>
          )}
          <Span>Count:{endedPools.length}</Span>
        </HeadPoolList>
        {width >= parseInt(theme.breakpoints.mobile, 10) ? (
          <Inputfilter>
            <SearchIcon src={Searchicon} onClick={handleSearch} />
            <Input
              left="2%"
              searchBar
              type="text"
              placeholder="Seach by project name, Symbols..."
              onChange={(e) => handleChange(e)}
              onKeyUp={(e) => handleSearch(e)}
            />

            <Filter>
              <Li
                onClick={() => {
                  setCurrentTab('All');

                  dispatch(
                    fetchEndedPoolList({
                      number: 1,
                      search: '',
                      append: false,
                      decrement: true,
                    })
                  );
                }}
                active={currentTab === 'All'}
              >
                All
              </Li>
              <Li
                onClick={() => {
                  setCurrentTab('BSC');

                  dispatch(fetchEndedPoolList({ number: 1, search: '56' }));
                }}
                active={currentTab === 'BSC'}
              >
                BSC
              </Li>
              <Li
                onClick={() => {
                  setCurrentTab('Polygon');
                  dispatch(fetchEndedPoolList({ number: 1, search: '137' }));
                }}
                active={currentTab === 'Polygon'}
              >
                Polygon
              </Li>
              <Li
                onClick={() => {
                  setCurrentTab('Avalanche');
                  dispatch(fetchEndedPoolList({ number: 1, search: '43114' }));
                }}
                active={currentTab === 'Avalanche'}
              >
                Avalanche
              </Li>
            </Filter>
          </Inputfilter>
        ) : (
          <InputfilterMobile>
            {searchBar && (
              <Input
                left="2%"
                searchBar
                type="text"
                placeholder="Seach by project name, Symbols..."
                onChange={(e) => handleChange(e)}
                onKeyUp={(e) => handleSearch(e)}
              />
            )}
            {!searchBar && (
              <Filter>
                <Li
                  onClick={() => {
                    setCurrentTab('All');

                    dispatch(
                      fetchEndedPoolList({
                        number: 1,
                        search: '',
                        append: false,
                        decrement: true,
                      })
                    );
                  }}
                  active={currentTab === 'All'}
                >
                  All
                </Li>
                <Li
                  onClick={() => {
                    setCurrentTab('BSC');

                    dispatch(fetchEndedPoolList({ number: 1, search: '56' }));
                  }}
                  active={currentTab === 'BSC'}
                >
                  BSC
                </Li>
                <Li
                  onClick={() => {
                    setCurrentTab('Polygon');

                    dispatch(fetchEndedPoolList({ number: 1, search: '137' }));
                  }}
                  active={currentTab === 'Polygon'}
                >
                  Polygon
                </Li>
                <Li
                  onClick={() => {
                    setCurrentTab('Avalanche');

                    dispatch(
                      fetchEndedPoolList({ number: 1, search: '43114' })
                    );
                  }}
                  active={currentTab === 'Avalanche'}
                >
                  Avalanche
                </Li>
              </Filter>
            )}

            <SearchIconDiv>
              <SearchIconMobile
                alt="search"
                src={Searchicon}
                onClick={() => {
                  setSearchBar(!searchBar);
                }}
              />
            </SearchIconDiv>
          </InputfilterMobile>
        )}
      </Wrapper>

      <PoolCardList>
        {/* {endedPool.length > 0 &&
          (width >= parseInt(theme.breakpoints.tablet, 10)
            ? endedPool.map?.((list) => <EndedpoolDesktop data={list} />)
            : endedPool.map?.((list) => <EndedPoolMobile data={list} />))} */}

        {endedPools.length > 0 &&
          endedPools.map?.((list) =>
            width >= 768 ? (
              <EndedpoolDesktop
                key={list._id}
                data={list}
                datas={endedPoolsLoading}
              />
            ) : (
              <EndedPoolMobile key={list._id} data={list} />
            )
          )}
      </PoolCardList>
      <BottomSection>
        {/* <ProjectListed>
          Total {endedPools.length} ended project listed{' '}
        </ProjectListed> */}
        {!loadMoreDisable && (
          <LoadMore
            onClick={() =>
              dispatch(
                fetchEndedPoolList({
                  number: pageNumber,
                  search: '',
                  append: true,
                })
              )
            }
          >
            Load More
          </LoadMore>
        )}
      </BottomSection>
    </Container>
  );
}

export default EndedPoolList;
