import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import {
  persistReducer,
  // FLUSH,
  // REHYDRATE,
  // PAUSE,
  // PERSIST,
  // PURGE,
  // REGISTER,
  persistStore,
} from 'redux-persist';
// import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// import { PersistGate } from 'redux-persist/integration/react';
import { modalSlice } from './slices/modal';
import { socketSLice } from './slices/socket';
import { notificationSlice } from './slices/notification';
import { poolSlice } from './slices/pool';
import { themeSlice } from './slices/theme';
import { timeSlice } from './slices/time';
import { userSlice } from './slices/user';
import { stakeSlice } from './slices/staking';
// import { userSlice } from './slices/user';

const rootReducer = combineReducers({
  pools: poolSlice.reducer,
  theme: themeSlice.reducer,
  time: timeSlice.reducer,
  user: userSlice.reducer,
  modal: modalSlice.reducer,
  notification: notificationSlice.reducer,
  socket: socketSLice.reducer,
  stake: stakeSlice.reducer,
});

const persistConfig = {
  timeout: 1000,
  key: 'root',
  version: 1,
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // serializableCheck: {
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      // },

      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

export default store;
