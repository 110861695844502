import React, { useState } from 'react';
// import { useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import { useWindowSize } from '../../../hooks';
import {
  Container,
  Wrapper,
  HeadPoolList,
  Heading,
  Inputfilter,
  Input,
  Filter,
  Li,
  SearchIcon,
  SearchIconDiv,
  SearchIconMobile,
  InputfilterMobile,
  Table,
  TRow,
  THead,
  TCell,
  THRow,
  //   TCellMain,
  ProjectName,
  ProjectType,
  ProjectLogo,
  ClaimButton,
} from './InvestmentHistory.style';
import Searchicon from '../../../assets/Searchicon.png';
import tempProjectLogo from '../../../assets/tempProjectLogo.svg';

function InvestmentHistory() {
  //   const dispatch = useDispatch();
  const theme = useTheme();
  const { width } = useWindowSize();

  const [currentTab, setCurrentTab] = useState('All');
  const [searchBar, setSearchBar] = useState(false);

  const handleSearch = (e) => {
    console.log('test', e);
  };
  const handleChange = (e) => {
    console.log('test22', e);
  };

  return (
    <Container>
      <Wrapper>
        <HeadPoolList>
          {width >= parseInt(theme.breakpoints.mobile, 10) ? (
            <Heading>Investment History</Heading>
          ) : (
            <Heading>IH</Heading>
          )}
        </HeadPoolList>
        {width >= parseInt(theme.breakpoints.mobile, 10) ? (
          <Inputfilter>
            <SearchIcon src={Searchicon} onClick={handleSearch} />
            <Input
              left="2%"
              searchBar
              type="text"
              placeholder="Search by project name, network, Symbol..."
              onChange={(e) => handleChange(e)}
              onKeyUp={(e) => handleSearch(e)}
            />

            <Filter>
              <Li
                onClick={() => {
                  setCurrentTab('All');
                }}
                active={currentTab === 'All'}
              >
                All
              </Li>
              <Li
                onClick={() => {
                  setCurrentTab('BSC');
                }}
                active={currentTab === 'BSC'}
              >
                BSC
              </Li>
              <Li
                onClick={() => {
                  setCurrentTab('Polygon');
                }}
                active={currentTab === 'Polygon'}
              >
                Polygon
              </Li>
              <Li
                onClick={() => {
                  setCurrentTab('Avalanche');
                }}
                active={currentTab === 'Avalanche'}
              >
                Avalanche
              </Li>
            </Filter>
          </Inputfilter>
        ) : (
          <InputfilterMobile>
            {searchBar && (
              <Input
                left="2%"
                searchBar
                type="text"
                placeholder="Search by project name, network, Symbol..."
                onChange={(e) => handleChange(e)}
                onKeyUp={(e) => handleSearch(e)}
              />
            )}
            {!searchBar && (
              <Filter>
                <Li
                  onClick={() => {
                    setCurrentTab('All');
                  }}
                  active={currentTab === 'All'}
                >
                  All
                </Li>
                <Li
                  onClick={() => {
                    setCurrentTab('BSC');
                  }}
                  active={currentTab === 'BSC'}
                >
                  BSC
                </Li>
                <Li
                  onClick={() => {
                    setCurrentTab('Polygon');
                  }}
                  active={currentTab === 'Polygon'}
                >
                  Polygon
                </Li>
                <Li
                  onClick={() => {
                    setCurrentTab('Avalanche');
                  }}
                  active={currentTab === 'Avalanche'}
                >
                  Avalanche
                </Li>
              </Filter>
            )}

            <SearchIconDiv>
              <SearchIconMobile
                alt="search"
                src={Searchicon}
                onClick={() => {
                  setSearchBar(!searchBar);
                }}
              />
            </SearchIconDiv>
          </InputfilterMobile>
        )}
      </Wrapper>

      <Table>
        <THRow>
          <THead>PROJECT NAME</THead>
          <THead>NETWORk</THead>
          <THead>Invested</THead>
          <THead>Purchaged</THead>
          <THead>Claimable</THead>
          <THead>Claimed</THead>
          <THead>Upcoming claim</THead>
          <THead>Action</THead>
        </THRow>
        <TRow>
          <TCell>
            <ProjectLogo src={tempProjectLogo} alt="project" />
            <div>
              <ProjectName>Sandbox Game</ProjectName>
              <ProjectType>Test</ProjectType>
            </div>
          </TCell>
          <TCell>Binance BSC </TCell>
          <TCell>1050 USD</TCell>
          <TCell>110,9837 ASVA</TCell>
          <TCell textColor="#FCB778">1243 ASVA</TCell>
          <TCell>1034 ASVA</TCell>
          <TCell textColor="#3beeadCC">12 MAR 2022</TCell>
          <TCell>
            <ClaimButton>Claim</ClaimButton>
          </TCell>
        </TRow>
        <TRow>
          <TCell>
            <ProjectLogo src={tempProjectLogo} alt="project" />
            <div>
              <ProjectName>Sandbox Game</ProjectName>
              <ProjectType>Test</ProjectType>
            </div>
          </TCell>
          <TCell>Binance BSC </TCell>
          <TCell>1050 USD</TCell>
          <TCell>110,9837 ASVA</TCell>
          <TCell textColor="#FCB778">1243 ASVA</TCell>
          <TCell>1034 ASVA</TCell>
          <TCell textColor="#3beeadCC">12 MAR 2022</TCell>
          <TCell>
            <ClaimButton>Claim</ClaimButton>
          </TCell>
        </TRow>
        <TRow>
          <TCell>
            <ProjectLogo src={tempProjectLogo} alt="project" />
            <div>
              <ProjectName>Sandbox Game</ProjectName>
              <ProjectType>Test</ProjectType>
            </div>
          </TCell>
          <TCell>Binance BSC </TCell>
          <TCell>1050 USD</TCell>
          <TCell>110,9837 ASVA</TCell>
          <TCell textColor="#FCB778">1243 ASVA</TCell>
          <TCell>1034 ASVA</TCell>
          <TCell textColor="#3beeadCC">12 MAR 2022</TCell>
          <TCell>
            <ClaimButton disabled>Claim</ClaimButton>
          </TCell>
        </TRow>
        <TRow>
          <TCell>
            <ProjectLogo src={tempProjectLogo} alt="project" />
            <div>
              <ProjectName>Sandbox Game</ProjectName>
              <ProjectType>Test</ProjectType>
            </div>
          </TCell>
          <TCell>Binance BSC </TCell>
          <TCell>1050 USD</TCell>
          <TCell>110,9837 ASVA</TCell>
          <TCell textColor="#FCB778">1243 ASVA</TCell>
          <TCell>1034 ASVA</TCell>
          <TCell textColor="#3beeadCC">12 MAR 2022</TCell>
          <TCell>
            <ClaimButton disabled>Claim</ClaimButton>
          </TCell>
        </TRow>
        <TRow>
          <TCell>
            <ProjectLogo src={tempProjectLogo} alt="project" />
            <div>
              <ProjectName>Sandbox Game</ProjectName>
              <ProjectType>Test</ProjectType>
            </div>
          </TCell>
          <TCell>Binance BSC </TCell>
          <TCell>1050 USD</TCell>
          <TCell>110,9837 ASVA</TCell>
          <TCell textColor="#FCB778">1243 ASVA</TCell>
          <TCell>1034 ASVA</TCell>
          <TCell textColor="#3beeadCC">12 MAR 2022</TCell>
          <TCell>
            <ClaimButton disabled>Claim</ClaimButton>
          </TCell>
        </TRow>
      </Table>
    </Container>
  );
}

export default InvestmentHistory;
